import classnames from "classnames";

export const gradientAfterBase =
  "after:absolute after:right-0 after:bottom-0 after:bg-gradient-to-r";

export const gradientBeforeBase =
  "before:absolute before:left-0 before:bottom-0 before:bg-gradient-to-l";

const fromBackgroundMap = {
  white: {
    after: "after:from-white/0",
    before: "before:from-white/0",
  },
  background: {
    after: "after:from-background/0",
    before: "before:from-background/0",
  },
};

export type Color = keyof typeof colorMap;
const colorMap = {
  white: {
    before: "before:to-white",
    after: "after:to-white",
  },
  background: {
    before: "before:to-background",
    after: "after:to-background",
  },
};

export type Width = keyof typeof widthMap;
const widthMap = {
  4: {
    before: "before:w-4",
    after: "after:w-4",
  },
  8: {
    before: "before:w-8",
    after: "after:w-8",
  },
};

export type Height = keyof typeof heightMap;
const heightMap = {
  5.5: {
    before: "before:h-[22px]",
    after: "after:h-[22px]",
  },
};

export const getGradientClass = (
  color: Color,
  width: Width,
  height: Height
) => {
  const c = colorMap[color];
  const w = widthMap[width];
  const h = heightMap[height];
  const fb = fromBackgroundMap[color];

  const before = classnames(
    gradientBeforeBase,
    fb.before,
    c.before,
    w.before,
    h.before
  );
  const after = classnames(
    gradientAfterBase,
    fb.after,
    c.after,
    w.after,
    h.after
  );

  return { before, after };
};
