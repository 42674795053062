import React, {
  ChangeEvent,
  KeyboardEvent,
  forwardRef,
  InputHTMLAttributes,
  useRef,
} from "react";
import classnames from "classnames";

interface WritableCustomInput extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  closeCalendar: () => void;
  label?: string;
}

const WritableCustomInput = forwardRef<HTMLInputElement, WritableCustomInput>(
  (
    { className, label, value, onChange, closeCalendar, ...inputProps },
    ref
  ) => {
    const editMode = useRef(false);
    const masker = ".";

    const onCloseCalendar = () => {
      const timer = setTimeout(() => {
        closeCalendar;
        clearTimeout(timer);
      }, 500);
    };

    const onChangeForOnlyAllowNumber = (e: ChangeEvent<HTMLInputElement>) => {
      // 숫자 및 .(Dot)만 허용 허용
      const value = e.target.value;
      const valueLength = value.length;
      const maskerCount = value.split(masker).length - 1;
      const maskerIndex = value.indexOf(masker);
      if (value.match(/^[0-9.]+$/g) && onChange) {
        if (valueLength === 10) {
          onCloseCalendar();
        }
        if (!editMode.current) {
          if (maskerCount === 0 && valueLength === 2) {
            e.target.value = value + masker;
          } else if (maskerCount === 0 && valueLength > 2) {
            e.target.value =
              value.substring(0, 2) +
              masker +
              value.substring(2, 4) +
              masker +
              value.substring(4, valueLength);
          } else if (
            valueLength >= 5 &&
            maskerCount === 1 &&
            maskerIndex === 2
          ) {
            e.target.value =
              value.substring(0, 5) + masker + value.substring(5, valueLength);
          }
          e.target.value = e.target.value.substring(0, 10);
        }
        onChange(e);
      }
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      // 숫자만 허용 허용
      const input = e.target as HTMLInputElement;
      const value = input.value;
      const valueLength = value.length;
      // e.keyCode, e.which 는 @deprecated
      const isBackspace =
        e.nativeEvent.key === "Backspace" || e.nativeEvent.key === "Delete";
      const maskerCount = value.split(masker).length - 1;
      if (e.key.match(/\./g) && maskerCount >= 2) {
        e.preventDefault();
        e.stopPropagation();
        return;
      } else if (valueLength === 1 && isBackspace) {
        input.value = "";
        // @ts-expect-error 이벤트 타입 변경 필요
        onChange && onChange(e);
      }
      editMode.current = isBackspace;
    };

    return (
      <label>
        {label && <div className="pb-2 text-sm text-blueGray">{label}</div>}

        <input
          ref={ref}
          {...inputProps}
          className={classnames(
            "border-b-2 border-lineColor pb-1 outline-none focus:border-primary",
            className
          )}
          value={value}
          onKeyDown={onKeyDown}
          onChange={onChangeForOnlyAllowNumber}
          maxLength={10}
        />
      </label>
    );
  }
);

export default WritableCustomInput;
