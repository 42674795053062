import ButtonHeader from "./ButtonHeader";
import DepthHeader from "./DepthHeader";
import Header from "./Header";
import MainHeader from "./MainHeader";
import SearchHeader from "./SearchHeader";

export {
  DepthHeader as MobileDepthHeader,
  Header as MobileHeader,
  MainHeader as MobileMainHeader,
  SearchHeader as MobileSearchHeader,
  ButtonHeader as MobileButtonHeader,
};
