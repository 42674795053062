import { ReactNode, useState } from "react";
import { usePopper } from "react-popper";
import { useOutsideClick } from "@dentlink/hooks";

import ConditionalWrapper from "../../utils/conditionalWrapper";
import Portal from "../Portal";
import { ModifierOption, Placement } from "./types";

export type PopperPropType = {
  open: boolean;
  anchorEl: Element | null;
  children: ReactNode;
  onOutsideClick?: (arg0: Event) => void;
  placement?: Placement;
  containerClasses?: string;
  modifiers?: ModifierOption<"flip" | "preventOverflow" | "offset">[];
  disablePortal?: boolean;
  portalId?: string;
};

const shadowStyle = "shadow-dropdown";

const Popper = ({
  open,
  anchorEl,
  children,
  onOutsideClick,
  placement = "bottom",
  containerClasses: containerStyle,
  modifiers = [],
  disablePortal = false,
  portalId = "popper-root",
}: PopperPropType) => {
  const className = [
    "z-10",
    shadowStyle,
    containerStyle,
    open ? "visible" : "invisible",
  ]
    .join(" ")
    .trim();
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null);
  const { attributes, styles } = usePopper(anchorEl, popperElement, {
    placement,
    modifiers,
  });

  useOutsideClick({
    enabled: !!onOutsideClick,
    handler: onOutsideClick,
    ref: popperElement,
  });

  return (
    <ConditionalWrapper
      condition={!disablePortal}
      wrapper={(children) => <Portal id={portalId}>{children}</Portal>}
    >
      <div
        className={className}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {children}
      </div>
    </ConditionalWrapper>
  );
};

export default Popper;
