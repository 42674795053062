import * as React from "react";
import { SVGProps } from "react";
const SvgReset = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.76 9.665h3.576v3.577"
      stroke="#000"
      strokeWidth={1.52}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M4.576 5.448H1V1.87"
      stroke="#000"
      strokeWidth={1.192}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M12.802 2.922a6.55 6.55 0 0 0-9.269 0L1.5 5M3.533 12.19a6.55 6.55 0 0 0 9.27 0L15 10"
      stroke="#000"
      strokeWidth={1.52}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgReset;
