import { useCallback, useMemo, useRef, useState } from "react";
import {
  factoryGetCurIdxFromDotIdx,
  factoryGetDotIdxFromCurIdx,
  getInitialCarouselIdx,
} from "../utils";
import { FromType } from "../types";
import { IDX_GUIDE_MAP } from "../constants";

interface UseCarouselIndexProps {
  childrenCount: number; // 원본 children length
  startIndex: number;
}

export default function useCarouselIndex({
  childrenCount,
  startIndex,
}: UseCarouselIndexProps) {
  const [currentIndex, setCurrentIndex] = useState(() => {
    return getInitialCarouselIdx(childrenCount, startIndex);
  });
  const dotIndex = useRef(startIndex);
  const IDX_GUIDE = useRef(IDX_GUIDE_MAP(childrenCount)).current;

  const callbackMap = useMemo(
    () => ({
      fromTrack: factoryGetDotIdxFromCurIdx(IDX_GUIDE),
      fromDot: factoryGetCurIdxFromDotIdx(IDX_GUIDE),
    }),
    [IDX_GUIDE]
  );

  const setCarouselIndexes = useCallback(
    (newIndex: number, fromType: FromType = "fromTrack") => {
      const syncIndex = callbackMap[fromType];

      switch (fromType) {
        case "fromTrack":
          setCurrentIndex(newIndex);
          dotIndex.current = syncIndex(newIndex);
          break;

        case "fromDot":
          setCurrentIndex(syncIndex(newIndex));
          dotIndex.current = newIndex;
          break;
      }
    },
    [callbackMap]
  );

  return {
    setCarouselIndexes,
    carouselIndex: currentIndex,
    dotIndex: dotIndex.current,
    IDX_GUIDE,
  };
}
