import { LogoWhite } from "@dentlink/icons";
import { Paper, Typography } from "@dentlink/ui";
import Head from "next/head";
import Link from "next/link";
import { ReactNode } from "react";
import LandingSvg1 from "../../public/assets/landing_aside_bg_vector_1.svg";
import LandingSvg2 from "../../public/assets/landing_aside_bg_vector_2.svg";

const DesktopLandingPageLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Head>
        <title>Portal | Dentlink International</title>
      </Head>
      <main className="flex-center h-screen min-h-[846px] px-20">
        <div className="flex h-full max-h-[822px] w-full max-w-[1194px] flex-row gap-7">
          <LandingPageAside />
          <Paper
            flex="none"
            className="flex h-full flex-1 bg-white"
            component="section"
          >
            {children}
          </Paper>
        </div>
      </main>
    </>
  );
};

const LandingPageAside = () => {
  return (
    <Paper
      className="signin-aside-gradient relative h-full max-w-[378px] px-11 pt-[4.5rem] text-white"
      component="aside"
    >
      <LandingSvg1 className="pointer-events-none absolute bottom-0 left-0" />
      <LandingSvg2 className="pointer-events-none absolute top-0 right-0" />
      <Link href="/" passHref>
        <LogoWhite width={164} height={36} />
      </Link>
      <Typography
        size="lg"
        weight="regular"
        className="mt-[3.125rem] whitespace-pre"
      >
        {"Welcome to\nDentlink International"}
      </Typography>
    </Paper>
  );
};

export default DesktopLandingPageLayout;
