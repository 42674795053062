import { format, isSunday } from "date-fns";
import classnames from "classnames";

interface getDayClassGeneratorProps {
  defaultClassName?: string;
  highlightHolidays?: boolean;
  holidays?: string[];
  includeSunday?: boolean;
}

const getDayClassGenerator = ({
  defaultClassName = "",
  highlightHolidays,
  holidays,
}: getDayClassGeneratorProps) => {
  const dayClassGenerator = (date: Date) => {
    if (!highlightHolidays) return defaultClassName;

    if (isSunday(date) || holidays?.includes(format(date, "yyyy-MM-dd"))) {
      return classnames("day__holiday", defaultClassName);
    }

    return defaultClassName;
  };

  return dayClassGenerator;
};

export default getDayClassGenerator;
