import classNames from "classnames";

interface BadgeProps {
  children: React.ReactNode;
  content?: string | number;
  layout?: "mobile" | "desktop";
}

const Badge = ({ children, content, layout = "desktop" }: BadgeProps) => {
  const shouldRenderBadge = content !== undefined && content !== 0;

  return (
    <div className="relative inline-flex flex-shrink-0 align-middle">
      {children}
      {
        <span
          id="badge-content"
          className={classNames("absolute  rounded-full bg-primary", {
            hidden: !shouldRenderBadge,
            "-right-[1px] -top-[5px] h-2 w-2": layout === "desktop",
            "-right-[3px] -top-[3px] h-1.5 w-1.5": layout === "mobile",
          })}
        />
      }
    </div>
  );
};

export default Badge;
