import * as React from "react";
import { SVGProps } from "react";
const SvgCheckForRateOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={14} cy={14} r={14} fill="currentColor" />
    <path d="m20 11-8 7-4-3.5" fill="currentColor" />
    <path
      d="m20 11-8 7-4-3.5"
      stroke="#fff"
      strokeWidth={2.743}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCheckForRateOutlined;
