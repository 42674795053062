import classnames from "classnames";
import { Dot } from "./Buttons";

interface DotGroupProps {
  dotCount: number;
  onDotClick: (i: number) => void;
  dotIndex: number;
  className?: string;
}

export default function DotGroup({
  dotCount,
  onDotClick,
  dotIndex,
  className,
}: DotGroupProps) {
  const isCustomBottomOptionSet = className?.includes("bottom-");

  return (
    <div
      className={classnames(
        "absolute left-2/4 flex -translate-x-2/4 gap-2.5",
        className,
        {
          "bottom-16": !isCustomBottomOptionSet,
        }
      )}
    >
      {Array.from(Array(dotCount)).map((_, i) => (
        <Dot
          key={`carousel-dot-${i}`}
          onClick={() => onDotClick(i)}
          isActive={i === dotIndex}
        />
      ))}
    </div>
  );
}
