/**
 * Mx에 속하는 치식의 배열.
 */
export const MxEntries = {
  UNS: [
    "#1",
    "#2",
    "#3",
    "#4",
    "#5",
    "#6",
    "#7",
    "#8",
    "#9",
    "#10",
    "#11",
    "#12",
    "#13",
    "#14",
    "#15",
    "#16",
  ],
  FDI: [
    "#11",
    "#12",
    "#13",
    "#14",
    "#15",
    "#16",
    "#17",
    "#18",
    "#21",
    "#22",
    "#23",
    "#24",
    "#25",
    "#26",
    "#27",
    "#28",
  ],
};

/**
 * Mn에 속하는 치식의 배열.
 */
export const MnEntries = {
  UNS: [
    "#17",
    "#18",
    "#19",
    "#20",
    "#21",
    "#22",
    "#23",
    "#24",
    "#25",
    "#26",
    "#27",
    "#28",
    "#29",
    "#30",
    "#31",
    "#32",
  ],
  FDI: [
    "#31",
    "#32",
    "#33",
    "#34",
    "#35",
    "#36",
    "#37",
    "#38",
    "#41",
    "#42",
    "#43",
    "#44",
    "#45",
    "#46",
    "#47",
    "#48",
  ],
};

export const ShadeOptions = [
  { value: "A1", label: "A1" },
  { value: "A2", label: "A2" },
  { value: "A3", label: "A3" },
  { value: "A3.5", label: "A3.5" },
  { value: "A4", label: "A4" },
  { value: "B1", label: "B1" },
  { value: "B2", label: "B2" },
  { value: "B3", label: "B3" },
  { value: "B4", label: "B4" },
  { value: "C1", label: "C1" },
  { value: "C2", label: "C2" },
  { value: "C3", label: "C3" },
  { value: "C4", label: "C4" },
  { value: "D2", label: "D2" },
  { value: "D3", label: "D3" },
  { value: "D4", label: "D4" },
];
