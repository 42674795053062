import { useEffect } from "react";

type UseSetAbyBProps<T> = {
  /**
   * setter for A
   */
  setA: (A: T) => void;
  /**
   * value to set as A
   */
  B: T;
};

/**
 * reflects the value of B to A
 * @param {UseSetAbyBProps<T>} props
 */
const useSetAbyB = <T>({ setA, B }: UseSetAbyBProps<T>) => {
  useEffect(() => {
    setA(B);
  }, [setA, B]);
};

export default useSetAbyB;
