import axiosInstance from "src/common/apis/axiosInstance";
import { NotificationsResponse } from "./types";

/**
 *
 * @param last_create_at 가장 마지막 노티의 생성 시각. 이 값을 커서로 다음 20개를 가져옴
 * @returns
 */
export const getNotifications = async (params?: { cursor: string }) => {
  return await axiosInstance.get<NotificationsResponse>("/notifications", {
    params: {
      create_at: params?.cursor,
    },
  });
};

export const readNotification = async (notificationId: number) => {
  return await axiosInstance.patch(`/notifications/read`, {
    notification_id: notificationId,
  });
};
