import { Eye, EyeSlash } from "@dentlink/icons";
import { forwardRef, useCallback, useMemo, useState } from "react";
import InputBase, { InputBasePropType } from "../../base/InputBase";

const endAdormentStyle = "stroke-black";

const PasswordInput = forwardRef<
  HTMLInputElement,
  Omit<InputBasePropType, "endAdornment">
>(({ ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePreview = useCallback(() => {
    setShowPassword(!showPassword);
  }, [setShowPassword, showPassword]);

  const previewIcon = useMemo(() => {
    return showPassword ? (
      <Eye
        className={endAdormentStyle}
        onClick={togglePreview}
        width={24}
        height={24}
      />
    ) : (
      <EyeSlash
        className={endAdormentStyle}
        onClick={togglePreview}
        width={24}
        height={24}
      />
    );
  }, [showPassword, togglePreview]);
  return (
    <InputBase
      type={showPassword ? "text" : "password"}
      {...props}
      endAdornment={
        <span className="place-content-top grid h-[27px]">{previewIcon}</span>
      }
      ref={ref}
    />
  );
});

export default PasswordInput;
