import type { CheckFieldSequences } from "./types";
import {
  ReviewFormValues,
  ReviewListRenderData,
  WriteReviewRenderData,
} from "./types";
import type { Entries, Nullable } from "../../common/types/common";
import {
  ReviewsByOrderIdResponse,
  TOrderSummary,
} from "../../common/apis/ReviewAPI/type";
import { Company } from "../../common/apis/UserAPI/types";

export const getEntries = <T>(data: { [key: string]: unknown }) => {
  return Object.entries(data) as Entries<T>;
};

export const extractCheckFormSequences = (
  reviewData: ReviewsByOrderIdResponse
): CheckFieldSequences => {
  return {
    seating_time: reviewData.seating_time.sequence,
    contact: reviewData.contact.sequence,
    occlusion: reviewData.occlusion.sequence,
    shade: reviewData.shade.sequence,
    margin: reviewData.margin.sequence,
  };
};

export const getDefaultValues = (
  data: ReviewsByOrderIdResponse | undefined
): ReviewFormValues => {
  return {
    overall_rate: data?.order.overall_rate ?? 0,
    seating_time: data?.seating_time.value ?? null,
    contact: data?.contact.value ?? null,
    occlusion: data?.occlusion.value ?? null,
    shade: data?.shade.value ?? null,
    margin: data?.margin.value ?? null,
    review: data?.review ?? "",
  };
};

export const extractWriteReviewData = (
  reviewData: ReviewsByOrderIdResponse | undefined
): Nullable<WriteReviewRenderData> => {
  if (!reviewData) return null;

  return {
    checkFieldSequences: extractCheckFormSequences(reviewData),
    defaultValues: getDefaultValues(reviewData),
    orderSummary: reviewData.order,
    isFirstReview: !reviewData.order.overall_rate,
  };
};

export const getMyReviewRenderData = (
  data: ReviewsByOrderIdResponse | undefined
): Nullable<ReviewListRenderData> => {
  if (!data) return null;

  return {
    rate: {
      overall_rate: data.order.overall_rate,
      check_rates: [
        { label: "seating_time", value: data.seating_time.label },
        { label: "contact", value: data.contact.label },
        { label: "occlusion", value: data.occlusion.label },
        { label: "shade", value: data.shade.label },
        { label: "margin", value: data.margin.label },
      ],
      review: data.review,
      update_at: data.update_at,
    },
    order: data.order,
  };
};

export const parseOrderSummaryInfo = (
  data: TOrderSummary,
  companyType: Company = "Clinic"
) => {
  switch (companyType) {
    /** 치과 계정에선 to_company_name 값 렌더 */
    case "Clinic":
      return data.to_company_name;
    /** 기공소 계정에선 from_company_name 값 렌더 */
    case "Lab":
      return data.from_company_name;
  }
};
