import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { UsersAPI } from "../apis/UserAPI";
import QueryKeys from "../constants/QueryKeys";
import { AxiosError } from "axios";
import { ProfileType, TClinicProfile } from "../apis/UserAPI/types";

type QueryOption<T, K> = UseQueryOptions<T, AxiosError, K>;

export default function useProfileQuery<
  T extends ProfileType = TClinicProfile,
  K = T
>(options: QueryOption<T, K> = {}) {
  const { getUserProfile } = UsersAPI;

  return useQuery<T, AxiosError, K>(
    [QueryKeys.USER_PROFILE],
    async () => (await getUserProfile<T>()).data,
    options
  );
}
