/**
 * @param {string} string capitalize 하고 싶은 문자열 전달
 * @param {string} [separator] 파라미터에 구분자 전달시, 구분자를 기준으로 capitalize ex) hello/world -> Hello/World
 * */
export default function capitalizeFirstLetter(
  string: string,
  separator?: string
) {
  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  if (separator) {
    return string
      .split(separator)
      .reduce(
        (res, str, idx) =>
          `${idx === 1 ? capitalize(res) : res}${separator}${capitalize(str)}`
      );
  }

  return capitalize(string);
}
