import useRadioGroup from "../RadioGroup/useRadioGroup";

interface RadioProps {
  label: string;
  value: string;
  icon?: JSX.Element;
}

const SelectedStyle = "text-black";
const UnselectedStyle = "text-black opacity-70";

const Radio = ({ label, value, icon }: RadioProps) => {
  const radioGroup = useRadioGroup();

  const checked = radioGroup.value === value;

  const handleClick = () => {
    radioGroup.setValue(value);
  };

  return (
    <div className="w-full">
      <button
        type="button"
        onClick={handleClick}
        className={`relative flex w-fit cursor-pointer items-center space-x-[9px] text-md ${
          checked ? SelectedStyle : UnselectedStyle
        } `}
      >
        <span className="inline-block h-[19px] w-[19px] rounded-full border border-inherit " />
        {checked && (
          <span className="absolute left-[-3px] h-[7px] w-[7px] rounded-full bg-black" />
        )}
        <span className="inline-flex items-center gap-2 text-inherit">
          {icon}
          {label}
        </span>
      </button>
    </div>
  );
};

export default Radio;
