import { Spinner } from "@dentlink/icons";
import Button, { ButtonProps } from "../Button/Button";

interface LoadingButtonProps
  extends Omit<ButtonProps, "startIcon" | "endIcon"> {
  loading?: boolean;
  iconPlacement?: "start" | "end";
}

const LoadingButton = ({
  loading = false,
  iconPlacement = "start",
  ...props
}: LoadingButtonProps) => {
  const icon = loading ? <Spinner className="text animate-spin " /> : undefined;

  const loadingButtonCustomProps = {
    startIcon: iconPlacement === "start" ? icon : undefined,
    endIcon: iconPlacement === "end" ? icon : undefined,
    disabled: loading,
  };

  return <Button {...loadingButtonCustomProps} {...props} />;
};

export default LoadingButton;
