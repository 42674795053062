import useOrderId from "../../Order/hooks/useOrderId";
import useOrder from "../../Order/hooks/useOrder";
import { useClientProfileQuery } from "../../../common/queries";
import useCompanyType from "src/common/hooks/useCompanyType";
import { Company } from "src/common/constants/User";

export default function useOrderDenturistProfile() {
  const orderId = useOrderId();
  const { data: orderData } = useOrder(orderId);
  const companyType = useCompanyType();

  return useClientProfileQuery(orderData?.to_user_id ?? 0, {
    enabled: !!orderData?.to_user_id && companyType !== Company.lab,
  });
}
