import { ReactNode } from "react";
import { Portal } from "../../src";
import getStyles from "./getStyles";
import { DrawerPlacements } from "./types";

interface DrawerBaseProps {
  placement: DrawerPlacements;
  /**
   * this classname is applied to the inner div of drawer.
   */
  className?: string;
  children?: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  portalId?: string;
  closeOnOutsideClick?: boolean;
}

const Backdrop = ({ toggle }: { toggle?: () => void }) => {
  return (
    <div
      onClick={toggle}
      className="fixed top-0 left-0 z-50 h-screen w-screen bg-black bg-opacity-80"
    />
  );
};

const DrawerBase = ({
  placement,
  isOpen,
  className,
  children,
  onClose,
  portalId = "drawer-root",
  closeOnOutsideClick,
}: DrawerBaseProps) => {
  if (closeOnOutsideClick && !onClose)
    throw new Error(`should pass onClose when closeOnOutsideClick is true`);

  const styleClasses = getStyles({ placement });

  if (!isOpen) return null;

  return (
    <Portal id={portalId}>
      <Backdrop toggle={() => closeOnOutsideClick && onClose?.()} />
      <div className={styleClasses}>
        <div className={`relative ${className}`}>{children}</div>
      </div>
    </Portal>
  );
};

export default DrawerBase;
