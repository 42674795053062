import useOrderDenturistProfile from "../../../lib/Profile/hooks/useOrderDenturistProfile";
import useCompanyType from "../../../common/hooks/useCompanyType";
import ProfileImageThumbnail, { ThumbnailProps } from "./ProfileImageThumbnail";
import classnames from "classnames";
import { UserNameWithBadge, WithBadgeProps } from "./UserName";
import { CaretDown } from "@dentlink/icons";
import { useState } from "react";
import DenturistBio, { DenturistBioProps } from "./DenturistBio";

export default function ProfileOnComment({
  className,
  bottomDivider = true,
}: {
  className?: string;
  bottomDivider?: boolean;
}) {
  const companyType = useCompanyType();
  const { data: labProfile, isLoading } = useOrderDenturistProfile();
  const [showDesc, setShowDesc] = useState(false);

  if (companyType !== "Clinic") return null;

  const thumbnailProps: ThumbnailProps = {
    isLoading,
    fallbackInitial: labProfile?.company_name.charAt(0),
    src: labProfile?.profile_image?.file_url,
    size: "small",
    type: "Lab",
  };

  const userNameProps: WithBadgeProps = {
    isLoading,
    title: labProfile?.user_name,
    isLicensed: Boolean(labProfile?.license),
  };

  const denturistBioProps: DenturistBioProps = {
    showMore: showDesc,
    bio: labProfile?.bio ?? "",
    sampleImages: labProfile?.sample_images ?? [],
  };

  const onCaretClick = () => setShowDesc((prev) => !prev);

  return (
    <section
      className={classnames("flex flex-col", className, {
        "mr-4 border-b border-lineColor pb-4": bottomDivider,
      })}
    >
      <div className="flex justify-between">
        <div className="flex items-center gap-[13px]">
          <ProfileImageThumbnail {...thumbnailProps} />
          <UserNameWithBadge {...userNameProps} />
        </div>
        <button
          className={classnames("mt-2 h-fit w-fit transition-all", {
            "rotate-180": showDesc,
          })}
          type="button"
          onClick={onCaretClick}
        >
          <CaretDown width={14} height={14} />
        </button>
      </div>

      <DenturistBio {...denturistBioProps} />
    </section>
  );
}
