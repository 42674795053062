import useIsMobile from "src/common/hooks/useIsMobile";
import Notification from "./Notification";
import { useNotifications } from "../../lib/Notifications/hooks";
import classnames from "classnames";
import Placeholder from "./Placeholder";

const NotificationList = ({ className }: { className?: string }) => {
  const { flatData, isLoading, hasNextPage, setObservationTarget } =
    useNotifications();
  const isNotificationEmpty = !isLoading && flatData?.length === 0;
  const isMobile = useIsMobile();

  if (isNotificationEmpty) {
    return (
      <div
        className={classnames(className, {
          "fixed inset-0 grid place-content-center bg-background": isMobile,
          "bg-white": !isMobile,
        })}
      >
        <Placeholder />
      </div>
    );
  }

  return (
    <div
      className={classnames(className, {
        "flex flex-col gap-4": !isMobile,
      })}
      onScroll={(e) => e.stopPropagation()}
    >
      {flatData?.map((data) => (
        <Notification data={data} key={data.notification_id} />
      ))}
      {hasNextPage && <div ref={setObservationTarget} />}
    </div>
  );
};

export default NotificationList;
