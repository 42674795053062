import axiosInstance from "../axiosInstance";
import {
  OrderSummaryParams,
  OrderSummaryResponse,
  ReviewItemsResponse,
  ReviewsByOrderIdResponse,
  SubmitReviewPayload,
} from "./type";
import { AxiosRequestConfig } from "axios";
import qs from "qs";

export const getReviewItems = async () => {
  const { data } = await axiosInstance.get<ReviewItemsResponse>(
    "/orders/reviews/items"
  );

  return data;
};

export const getOrderSummary = async (params?: OrderSummaryParams) => {
  const { data } = await axiosInstance.get<OrderSummaryResponse>(
    "/orders/reviews/list",
    {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );

  return data;
};

export const getReviewsByOrderId = async (
  orderId: number,
  config: AxiosRequestConfig = {}
) => {
  const { data } = await axiosInstance.get<ReviewsByOrderIdResponse>(
    `orders/reviews/${orderId}`,
    config
  );

  return data;
};

export const postReview = async (payload: SubmitReviewPayload) => {
  const { data } = await axiosInstance.post("/orders/reviews", payload);

  return data;
};

export const patchReview = async (payload: SubmitReviewPayload) => {
  const { data } = await axiosInstance.patch("/orders/reviews", payload);

  return data;
};
