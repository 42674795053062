import { MouseEvent } from "react";
import Typography from "../../Typography";
import Button from "../../Button";

interface IInfoFailedProps {
  title: string;
  description: string;
  label?: string;
  onClick: (event: MouseEvent) => void;
}

export default function InfoFailed({
  title,
  description,
  label = "OK",
  onClick,
}: IInfoFailedProps) {
  return (
    <section className="relative h-[272px] w-[423px] whitespace-pre-line rounded-dropdown bg-white px-10 py-8">
      <Typography
        renderAs="h3"
        size="xl"
        weight="bold"
        className="mr-auto mb-4"
      >
        {title}
      </Typography>
      <Typography renderAs="p" size="base" className="mr-auto mb-6">
        {description}
      </Typography>
      <Button fullWidth onClick={onClick}>
        {label}
      </Button>
    </section>
  );
}
