import axiosInstance from "../axiosInstance";
import { Client } from "./type";

export const getClientList = async (config = {}) => {
  const { data } = await axiosInstance.get<Client[]>("/clients", config);
  return data;
};

export const getClientProfile = async (clientId: Client["user_id"]) => {
  const { data } = await axiosInstance.get<Client>(`/clients/${clientId}`);
  return data;
};
