import * as React from "react";
import {
  ComponentType,
  createElement,
  forwardRef,
  HTMLProps,
  LegacyRef,
  MouseEvent,
  ReactNode,
  RefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import ReactDatepicker, { ReactDatePickerProps } from "react-datepicker";
import { useSetAbyB } from "@dentlink/hooks";
import CalendarContainer from "./components/CalendarContainer";
import RangePickerHeader from "./components/RangePickerHeader";
import getDayClassGenerator from "./utils/getDayClassGenerator";
import { DatePickerPopperOptions } from "../Popper/types";
import classnames from "classnames";
import { CalendarRef } from "./types";

export interface DateRangePickerProps
  extends Omit<ReactDatePickerProps, "onChange" | "popperModifiers"> {
  /**
   * currently selected start date.
   */
  startDate: Date | null;
  /**
   * setStartDate function to update the start date
   */
  setStartDate: (date: Date | null) => void;
  /**
   * currently selected end date.
   */
  endDate: Date | null;
  /**
   * setEndDate function to update the end date
   */
  setEndDate: (date: Date | null) => void;
  /**
   * component to use as Anchor
   */
  AnchorComponent?: ComponentType;
  /**
   * The locale to use for the range picker.
   * import this from date-fns/locale
   */
  locale?: Locale;
  /**
   * lower-boundary date to select in the range picker
   */
  minDate?: Date | null;
  /**
   * upper-boundary date to select in the range picker
   */
  maxDate?: Date | null;
  /**
   * If true, holidays including sundays will be highlighted.
   */
  highlightHolidays?: boolean;
  /**
   * custom holidays list.
   */
  holidays?: string[];

  /**
   * custom HelperText to be displayed when the range is invalid.
   */
  helperText?: ReactNode;

  popperOptions?: DatePickerPopperOptions;

  /**
   * Format of the string is based on Unicode Technical Standard
   * @see https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   * */
  dateFormat?: string;

  /** When to force selection of both start and end dates. default is false */
  shouldSelectBothDate?: boolean;
  /** 날짜 선택을 완료하고 OK 버튼을 눌렀을 때 실행할 함수 */
  onSaveEffect?: () => void;
  /** 날짜 선택 취소 버튼을 눌렀을 때 실행할 함수 */
  onCancelEffect?: () => void;
  /** 앵커 컴포넌트를 클릭해도 날짜 선택창이 닫히지 않도록 하려면 ref 전달 */
  anchorElRef?: RefObject<HTMLElement>;
  /** CustomInput Wrapper에 들어갈 classNames */
  customInputClasses?: string;
  withPortal?: boolean;
  portalId?: string;
}

const renderDayContents = (dayOfMonth: number, date?: Date) => {
  return (
    <>
      <span className="background" />
      <span className="date">{dayOfMonth}</span>
    </>
  );
};

/**
 * Selects a date range.
 * @param {DateRangePickerProps} props
 */
const DateRangepicker = ({
  startDate,
  setEndDate,
  setStartDate,
  endDate,
  AnchorComponent,
  highlightHolidays,
  holidays,
  helperText,
  onSaveEffect,
  onCancelEffect,
  anchorElRef,
  customInputClasses,
  popperOptions = {},
  shouldSelectBothDate = false,
  inline,
  dateFormat,
  withPortal,
  portalId,
  ...rest
}: DateRangePickerProps) => {
  /** withPortal 을 사용하면 isCalendarOpen 값 적용이 안됨
   * 정확한 원인 파악은 안되지만 커스텀 요소를 사용해서 그런것으로 추측됨
   * 따라서 Cancel 버튼을 누르면 ref.setOpen() 으로 닫히도록 되도록 작성
   * */
  const calendarRef = useRef<CalendarRef | null>(null);
  const monthPickerRef = useRef<HTMLInputElement>(null);

  // withPortal 을 사용할 땐 항상 true
  const [isCalendarOpen, setCalendarOpen] = useState<boolean>(!!withPortal);
  const [internalStartDate, setInternalStartDate] =
    useState<Date | null>(startDate);
  const [internalEndDate, setInternalEndDate] = useState<Date | null>(endDate);

  useSetAbyB({ setA: setInternalStartDate, B: startDate });
  useSetAbyB({ setA: setInternalEndDate, B: endDate });

  const setOpen = useCallback(
    (value: boolean) => {
      calendarRef.current?.setOpen(value);
      // withPortal 을 사용할 땐 isCalendarOpen 값이 항상 참이어야 작동하므로 조건 추가
      if (!withPortal) setCalendarOpen(value);
    },
    [withPortal]
  );

  const onChange = (dates: [Date | null, Date | null]) => {
    const [newStart, newEnd] = dates;
    setInternalStartDate(newStart);
    setInternalEndDate(newEnd);
  };

  const saveSelection = () => {
    setStartDate(internalStartDate);
    setEndDate(internalEndDate);
    setOpen(false);
    if (onSaveEffect) onSaveEffect();
  };
  const cancelSelection = () => {
    setInternalStartDate(startDate);
    setInternalEndDate(endDate);
    setOpen(false);
    if (onCancelEffect) onCancelEffect();
  };

  const onOutsideClick = (e: MouseEvent) => {
    if (e.target === anchorElRef?.current) return;
    cancelSelection();
  };

  const calendarActions = [cancelSelection, saveSelection];

  const CustomInput = useCallback(
    (props: HTMLProps<HTMLInputElement>, ref: LegacyRef<HTMLDivElement>) => {
      return (
        <>
          <div
            className={`cursor-pointer ${customInputClasses}`}
            onClick={() => setOpen(!calendarRef.current?.state.open)}
            ref={ref}
          >
            {AnchorComponent ? (
              <AnchorComponent {...props} />
            ) : (
              <input {...props} />
            )}
          </div>
          {!!helperText && <div className="mt-2">{helperText}</div>}
        </>
      );
    },
    [AnchorComponent, customInputClasses, helperText, setOpen]
  );

  const dayClassGenerator = getDayClassGenerator({
    defaultClassName: "rangepicker__day",
    highlightHolidays,
    holidays,
  });

  const isOkButtonDisabled = shouldSelectBothDate
    ? [internalStartDate, internalEndDate].some((d = null) => d === null)
    : false;

  return (
    <ReactDatepicker
      ref={calendarRef}
      withPortal={withPortal}
      portalId={portalId}
      selected={internalStartDate}
      onChange={onChange}
      startDate={internalStartDate}
      endDate={internalEndDate}
      selectsRange
      shouldCloseOnSelect={false}
      open={isCalendarOpen}
      dateFormat={dateFormat}
      inline={inline}
      onClickOutside={inline ? undefined : onOutsideClick}
      renderDayContents={renderDayContents}
      dayClassName={dayClassGenerator}
      disabledKeyboardNavigation
      formatWeekDay={(weekDayName) => weekDayName.slice(0, 1)}
      renderCustomHeader={(props) => (
        <RangePickerHeader
          monthPickerRef={monthPickerRef}
          startDate={internalStartDate}
          endDate={internalEndDate}
          {...props}
        />
      )}
      calendarContainer={({ className, ...props }) => (
        <CalendarContainer
          isOkButtonDisabled={isOkButtonDisabled}
          actions={calendarActions}
          className={classnames(className, {
            "max-w-[375px] rounded bg-white py-5 px-10": withPortal, // 모달 스타일
          })}
          {...props}
        />
      )}
      customInput={createElement(forwardRef(CustomInput))}
      {...rest}
      {...popperOptions}
    />
  );
};

export default DateRangepicker;
