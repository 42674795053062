import { OrderListItem } from "../OrderList";
import { useSearchOrders } from "../../lib/Search/hooks";
import classnames from "classnames";
import { Loading } from "@dentlink/ui";
import PlaceHolder from "./Placeholder";

interface DesktopSearchProps {
  searchTerm: string;
  isTyping: boolean;
}

const DesktopSearch = ({ searchTerm, isTyping }: DesktopSearchProps) => {
  const {
    flatData,
    setObservationTarget,
    hasNextPage,
    showLoading,
    showResults,
    showPlaceholder,
  } = useSearchOrders({ searchTerm, isTyping });

  return (
    <div
      className={classnames(
        "common-scroll grid max-h-[calc(100vh-7rem)] overflow-auto px-1.5 transition-all duration-200",
        { "min-h-0": !searchTerm, "min-h-[225px]": searchTerm }
      )}
    >
      {showResults && (
        <div className="flex flex-col gap-2.5">
          {flatData.map((order) => (
            <OrderListItem
              key={order.order_id}
              data={order}
              shadow={false}
              className="hover:border-2"
            />
          ))}
          {hasNextPage && <div ref={setObservationTarget} />}
        </div>
      )}
      {showPlaceholder && <PlaceHolder className="place-self-center" />}
      {showLoading && <Loading className="m-4 place-self-center" />}
    </div>
  );
};

DesktopSearch.displayName = "DesktopSearch";

export default DesktopSearch;
