import { FlexDirection } from "./types";

export const FlexConfig: Record<FlexDirection, string> = {
  row: "flex flex-row",
  column: "flex flex-col justify-start items-start",
  "row-reverse": "flex flex-row-reverse",
  "column-reverse": "flex flex-col-reverse",
  none: "",
};

export const ElevationConfig: Record<number, string> = {
  0: "",
  2: "shadow-md",
};

export const PaddedConfig = "p-[16px]";

export const RoundedConfig = "rounded-[10px]";

export const DefaultConfig = "bg-white flex-shrink-0";
