import Script from "next/script";
import getChannelTalkScript from "../utils/addons/channeltalk";

const ChannelTalkScript = () => {
  if (process.env.NEXT_PUBLIC_DEPLOY_ENV !== "production") {
    return null;
  }

  return (
    <Script
      strategy="afterInteractive"
      id="channel-io-script"
      dangerouslySetInnerHTML={getChannelTalkScript()}
    />
  );
};

export default ChannelTalkScript;
