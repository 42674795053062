import { AxiosRequestConfig } from "axios";
import axiosInstance from "src/common/apis/axiosInstance";
import type {
  AuthEmail,
  CompactUserInfo,
  EditProfilePayload,
  ResetPasswordPayload,
  SignupActivationPayload,
  SignupPayload,
  SignupResponse,
  SocialSignupPayload,
  UserSessionResponse,
  VerifyEmail,
} from "./types";
import { ProfileType, SamplingSignupPayload } from "./types";

export async function Signup(
  payload: SignupPayload | SocialSignupPayload | SignupActivationPayload
) {
  const { type, ...signupInfo } = payload;

  const response = await axiosInstance.post<SignupResponse>(
    `/users/?type=${type}`,
    signupInfo
  );

  return response;
}

export async function SamplingSignup(payload: SamplingSignupPayload) {
  const response = await axiosInstance.post<SamplingSignupPayload>(
    `/users?type=sampling`,
    payload
  );

  return response;
}

export async function GetUserSession() {
  const response = await axiosInstance.get<UserSessionResponse>("/users");

  return response;
}

/**
 * send verification email to user
 * @param {AuthEmail} payload : email, verification type.
 * @returns
 */
export async function AuthEmail(payload: AuthEmail) {
  const response = await axiosInstance.post<boolean>(
    `/users/auth/email/?type=${payload.type}`,
    {
      email: payload.email,
    }
  );
  return response;
}

export async function VerifyEmail(payload: VerifyEmail) {
  const response = await axiosInstance.post<boolean>(
    "/users/auth/email/verify",
    payload
  );

  return response;
}

export async function ResetPassword(payload: ResetPasswordPayload) {
  const response = await axiosInstance.patch<boolean>(`/users/reset`, payload);
  return response;
}

export async function getUserCompactInfo(user_uid: string) {
  const response = await axiosInstance.get<CompactUserInfo>(
    `/users/${user_uid}`
  );
  return response;
}

export async function getUserProfile<T extends ProfileType>(
  config: AxiosRequestConfig = {}
) {
  return await axiosInstance.get<T>("/users/profile", config);
}

export async function editUserProfile<T extends ProfileType>(
  payload: EditProfilePayload
) {
  return await axiosInstance.patch<T>("/users/profile", payload);
}
