import { useDisableBodyScroll, useOnTransitionStart } from "@dentlink/hooks";
import { useCallback, useRef } from "react";
import {
  CarouselArrow,
  CarouselItemAttributes,
  CarouselOptions,
  SwipeEvent,
  TrackEventAttributes,
} from "../types";
import { getCoordinates, getSwipeSizeCalcExpression } from "../utils";
import { IDX_OFFSET, INITIAL_DIFF } from "../constants";
import useCarouselModel from "./useCarouselModel";
import useCarouselIndex from "./useCarouselIndex";
import useCarouselDiff from "./useCarouselDiff";
import useFrameInterval from "./useFrameInterval";

interface UseCarouselProps extends CarouselOptions {
  childrenCount: number; // 원본 children length
}

export default function useCarousel({
  childrenCount,
  autoSlideOptions: { interval, direction },
  ...options
}: UseCarouselProps) {
  const trackRef = useRef<HTMLDivElement>(null);
  const carouselModel = useCarouselModel();

  const { shouldMoveToNext, shouldMoveToPrev, diff, setDiff } = useCarouselDiff(
    { sensitivity: options.sensitivity }
  );

  const { carouselIndex, dotIndex, setCarouselIndexes, IDX_GUIDE } =
    useCarouselIndex({
      childrenCount,
      startIndex: options.startIndex,
    });

  const swipeSizeCalcExpression = getSwipeSizeCalcExpression(
    carouselIndex,
    options.itemWidthRatio,
    diff
  );

  useDisableBodyScroll({ condition: options.hideBodyScroll });

  useOnTransitionStart({
    ref: trackRef,
    callback: () => carouselModel.onTransitionStartAction(),
  });

  useFrameInterval({
    callback: () => !carouselModel.isSwipeable && moveToNextIndex(direction),
    isActive: options.autoSlide,
    interval,
  });

  const onTransitionEndAfterDelay = useCallback(
    (delay = 20) => {
      setTimeout(() => carouselModel.onTransitionEndAction(), delay);
    },
    [carouselModel]
  );

  const moveToIdxPromptly = (index: number) => {
    setTimeout(() => {
      carouselModel.onMoveToIdxPromptlyAction();
      setCarouselIndexes(index);
      onTransitionEndAfterDelay();
    }, options.speed);
  };

  const moveToNextIndex = (type: CarouselArrow, waitTransition = true) => {
    if (waitTransition && !carouselModel.isIdle) return;

    const isPrev = type === "prev";
    const nextIndex = carouselIndex + (isPrev ? -IDX_OFFSET : IDX_OFFSET);

    setCarouselIndexes(nextIndex);
    carouselModel.onMoveToIdxAction();

    const isFront = nextIndex === IDX_GUIDE.FRONT;
    const isTale = nextIndex === IDX_GUIDE.TAIL;

    if (isFront || isTale) {
      const toIndex = isPrev ? IDX_GUIDE.LAST_ITEM + IDX_OFFSET : IDX_OFFSET;
      moveToIdxPromptly(toIndex);
    }
  };

  const onDotClick = useCallback(
    (index: number) => {
      if (!carouselModel.isIdle) return;

      carouselModel.onDotClickAction();
      setCarouselIndexes(index, "fromDot");
    },
    [carouselModel, setCarouselIndexes]
  );

  const onSwipeStart = useCallback(
    (event: SwipeEvent) => {
      if (!carouselModel.isIdle) return;

      const { clientX } = getCoordinates(event);
      carouselModel.onSwipeStartAction(clientX);
    },
    [carouselModel]
  );

  const onSwipeMove = useCallback(
    (event: SwipeEvent) => {
      if (!carouselModel.isSwipeable) return;

      const { clientX } = getCoordinates(event);
      const diff = carouselModel.getDiff(clientX);
      setDiff(diff);
    },
    [carouselModel, setDiff]
  );

  const onSwipeEnd = () => {
    if (shouldMoveToNext) onNext(false);
    if (shouldMoveToPrev) onPrev(false);

    carouselModel.onSwipeEndAction();
    setDiff(INITIAL_DIFF);
  };

  const trackEventHandlers: TrackEventAttributes = {
    onTouchStart: onSwipeStart,
    onTouchMove: onSwipeMove,
    onTouchEnd: onSwipeEnd,
    onMouseDown: onSwipeStart,
    onMouseMove: onSwipeMove,
    onMouseUp: onSwipeEnd,
    onMouseLeave: onSwipeEnd,
  };

  const dotGroupProps = {
    dotCount: childrenCount,
    onDotClick,
    dotIndex,
  };

  const carouselItemProps: CarouselItemAttributes = {
    ref: trackRef,
    style: {
      transform: `translateX(calc(${swipeSizeCalcExpression}))`,
      transition: carouselModel.isActiveTransition
        ? `transform ${options.speed}ms ${options.transitionType}`
        : undefined,
    },
    onTransitionEnd: () => onTransitionEndAfterDelay(),
  };

  const onPrev = (waitTransition = true) => {
    moveToNextIndex("prev", waitTransition);
  };
  const onNext = (waitTransition = true) => {
    moveToNextIndex("next", waitTransition);
  };

  const arrowHandlers = { onPrev, onNext };

  const isSingleChildren = childrenCount === 1;
  const shouldRenderArrows = !isSingleChildren && options.arrows;
  const shouldRenderDots = !isSingleChildren && options.dots;

  return {
    trackEventHandlers: !isSingleChildren && trackEventHandlers,
    arrowHandlers,
    dotGroupProps,
    carouselItemProps,
    shouldRenderArrows,
    shouldRenderDots,
  };
}
