import { Paper, Typography } from "@dentlink/ui";
import { TMenuEntry } from "src/common/constants/Menus";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { ChevronRight } from "@dentlink/icons";
import useIsMobile from "../../../common/hooks/useIsMobile";
import useCompanyType from "../../../common/hooks/useCompanyType";
import isFunction from "lodash/isFunction";
import { useReviewCount } from "src/lib/Menus/hooks";

const ReviewMenuWithCount = ({ label }: { label: string }) => {
  const { totalCount } = useReviewCount();
  /**
   * 리뷰 카운트가 0이라면 아무것도 표시하지 않습니다
   * 리뷰 카운트가 99를 초과한다면 99+ 형태로 표시합니다
   * 치과 계정에선 작성할 수 있는 리뷰 개수를 표시합니다
   * 기공소 계정에선 이미 작성한 리뷰 개수를 표시합니다
   * */

  return (
    <Typography size="base">
      {label}
      <Typography size="md" color="primary" weight="bold" className="ml-2.5">
        {totalCount}
      </Typography>
    </Typography>
  );
};

/**
 * 모바일 메뉴 창에 띄줄 메뉴 컴포넌트.
 */
const MenuItem = ({ label, link, target }: TMenuEntry) => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const companyType = useCompanyType() ?? "Clinic";

  useEffect(() => {
    if (!target) router.prefetch(link);
  }, [router, link, target]);

  const onMenuClick = async () => {
    if (target) window.open(link, target);
    else await router.push(link);
  };

  const ConditionalItem = () => {
    if (label === "Reviews") {
      return <ReviewMenuWithCount label={label} />;
    }
    if (isFunction(label)) {
      return <Typography size="base">{label(companyType)}</Typography>;
    }
    return <Typography size="base">{label}</Typography>;
  };

  return (
    <Paper
      flex="row"
      className="items-center justify-between rounded-[3px] px-[9px] py-2 hover:bg-background"
      component="button"
      elevation={0}
      enableRipple
      onClick={onMenuClick}
    >
      <ConditionalItem />
      {isMobile && <ChevronRight width={24} height={24} />}
    </Paper>
  );
};

export default MenuItem;
