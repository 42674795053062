interface DividerProps {
  className?: string;
  color?: "lineColor" | "black";
}

const Divider = ({ color = "lineColor", className }: DividerProps) => {
  const defaultStyle = "w-full height-[1px]";
  const colorStyle =
    color === "lineColor" ? "border-lineColor" : "border-black";
  const classes = [defaultStyle, colorStyle, className].join(" ");

  return <hr className={classes} />;
};

export default Divider;
