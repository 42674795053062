/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import {
  Children,
  cloneElement,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactNode,
} from "react";
import { GapStyles } from "../../constants/styles";
import Typography from "../Typography";

interface CheckboxGroupProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  value: any[];
  onChange: (value: any[]) => void;
  label?: string;
  children: ReactNode;
  required?: boolean;
  col?: boolean;
  /**
   * this classes will be applied to outer wrapper of CheckboxGroup
   */
  className?: string;
  /**
   * this classes will be applied to inner wrapper of CheckboxGroup
   */
  innerClassName?: string;
  helperText?: ReactNode;
  gap?: keyof typeof GapStyles;
}

// TODO : implement ReadOnly CheckboxGroup
const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
  (props, ref) => {
    const {
      value: selectedValues,
      onChange,
      label,
      children,
      required,
      col,
      className,
      innerClassName,
      helperText,
      gap = 8,
      ...rest
    } = props;

    if (!Array.isArray(selectedValues)) {
      throw new Error("CheckboxGroup value must be an array");
    }

    const toggleValue = (newValue: any) => {
      if (selectedValues.includes(newValue)) {
        onChange(selectedValues.filter((value) => value !== newValue));
      } else {
        onChange([...selectedValues, newValue]);
      }
    };

    return (
      <>
        <div
          ref={ref}
          className={classNames(
            `flex flex-col justify-start`,
            className,
            GapStyles[gap]
          )}
          {...rest}
        >
          <Typography
            variant="body2"
            className={`text-blueGray ${required && "after:content-['*']"}`}
          >
            {label}
          </Typography>
          <div
            className={classNames("flex", innerClassName, {
              "flex-col": col,
            })}
          >
            {Children.map(children, (child) => {
              if (!isValidElement(child)) return null;
              const { props } = child;
              const { value: checkboxValue } = props;
              const checked = selectedValues.includes(checkboxValue);

              return cloneElement(child, {
                checked,
                onChange: () => toggleValue(checkboxValue),
                ...props,
              });
            })}
          </div>
        </div>
        {!!helperText && <div className="mt-2">{helperText}</div>}
      </>
    );
  }
);

export default CheckboxGroup;
