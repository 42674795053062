import { useInfiniteQuery } from "@tanstack/react-query";
import QueryKeys from "../../../common/constants/QueryKeys";
import { useIntersectionObserver } from "@dentlink/hooks";
import { OrderAPI } from "src/common/apis/OrderAPI";
import { useSelector } from "react-redux";
import { orderFilterSelector } from "src/lib/OrderFilter/redux/orderFilterSelectors";

export default function useOrderListData() {
  const orderFilters = useSelector(orderFilterSelector);

  const infiniteQueryResult = useInfiniteQuery(
    [QueryKeys.ORDER_LIST, orderFilters],
    async ({ pageParam: cursor }) => {
      return (
        await OrderAPI.getOrderList(
          {
            status: orderFilters.status,
            client: orderFilters.client.map((client) => client.user_id),
            startdate: orderFilters.arrival_date[0],
            enddate: orderFilters.arrival_date[1],
          },
          cursor
        )
      ).data;
    },
    { getNextPageParam: (data) => data.cursor ?? undefined }
  );

  const { setObservationTarget } = useIntersectionObserver({
    onIntersect: ([firstEntry]) => {
      if (firstEntry.isIntersecting) {
        infiniteQueryResult.fetchNextPage();
      }
    },
  });

  const orderList = infiniteQueryResult.data?.pages.flatMap(
    (data) => data.orders
  );

  return { ...infiniteQueryResult, setObservationTarget, orderList } as const;
}
