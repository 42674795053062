import { useMounted } from "@dentlink/hooks";
import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  children: ReactNode;
  id?: string;
}

export default function Portal({ children, id = "portal-root" }: PortalProps) {
  const isMounted = useMounted();

  const [element, setElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setElement(document.getElementById(id));
  }, [id]);

  if (!isMounted) return null;
  if (!element) {
    if (process.env.NODE_ENV === "development") {
      throw new Error(`Portal root with id ${id} not found`);
    }
    return null;
  }
  return createPortal(children, element);
}
