type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

const pageView = (url: string) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event: "pageview",
    page: url,
  });
};

export default pageView;
