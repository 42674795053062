import { OrderDetailLayout } from "../types/OrderDetail";

const getLayoutConditions = (layout: OrderDetailLayout) => {
  return {
    isMobileLayout: layout === "mobile",
    isModalLayout: layout === "modal",
    isDesktopLayout: layout === "desktop",
  };
};

export default getLayoutConditions;
