import { FontColor, FontSize, FontWeight } from "@dentlink/types";
import {
  fontColorConfig,
  fontSizeConfig,
  fontWeightConfig,
} from "@dentlink/utils/src/styles/configs";
import classNames from "classnames";

interface getUtilityClassesProps {
  className?: string;
  weight: FontWeight;
  size: FontSize;
  color: FontColor;
}

export default function getUtilityClasses({
  className,
  weight,
  size,
  color,
}: getUtilityClassesProps): string {
  const classes = classNames(
    fontColorConfig[color],
    fontWeightConfig[weight],
    fontSizeConfig[size],
    className
  );

  return classes;
}
