import { Children, PropsWithChildren } from "react";
import classnames from "classnames";

interface MenuItemGroupProps {
  divider?: boolean;
  className?: string;
}

const MenuItemGroup = ({
  children,
  divider = true,
  className,
}: PropsWithChildren<MenuItemGroupProps>) => {
  const hasChildren = Children.count(children);
  if (!hasChildren) return null;

  return (
    <section
      className={classnames("flex flex-col gap-2 py-[14px]", className, {
        "border-t border-lineColor": divider,
      })}
    >
      {children}
    </section>
  );
};

export default MenuItemGroup;
