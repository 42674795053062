import { useRouter } from "next/router";
import useIsMobile from "../../../common/hooks/useIsMobile";
import Links from "../../../common/constants/Links";
import { BottomSheet, Modal } from "@dentlink/ui";
import { Action } from "@dentlink/ui/src/BottomSheet/BottomSheet";
import { useState } from "react";
import useCheckShouldRedirectToProfileSetupPage from "src/lib/Profile/hooks/useCheckShouldRedirectToProfileSetupPage";

const getProfileSetupUI = (
  isMobile: boolean,
  action: Action,
  openModal: boolean
) => {
  switch (isMobile) {
    case true:
      return (
        <BottomSheet
          actions={action}
          title="Not available"
          description={`Please enter the company\n information and use our service`}
          descriptionProps={{ renderAs: "p", size: "base" }}
          isOpen={openModal}
        />
      );
    case false:
      return (
        <Modal isOpen={openModal}>
          <Modal.InfoFailed
            title="Not available"
            onClick={() => action.onClick?.()}
            description={`Please enter the company\n information and use our service`}
          />
        </Modal>
      );
  }
};

export const useProfileSetupNotice = () => {
  const router = useRouter();
  const { pathname } = router;

  const isMobile = useIsMobile();
  const [openModal, setOpenModal] = useState<boolean>(true);

  const isCompanyInfoPath =
    pathname === Links.PROFILE || pathname === Links.PROFILE_EDIT;

  const shouldRedirectToProfileSetupPage =
    useCheckShouldRedirectToProfileSetupPage({
      disabledPath: [Links.PROFILE_SETUP],
    });

  if (!shouldRedirectToProfileSetupPage) return null;

  const action = {
    label: "OK",
    color: "primary",
    variant: "contained",
    onClick: () => {
      isCompanyInfoPath
        ? setOpenModal(false)
        : router.push(Links.PROFILE_SETUP);
    },
  } as const;

  return getProfileSetupUI(isMobile, action, openModal);
};
