import { CaretDown } from "@dentlink/icons";
import { format, getMonth, getYear } from "date-fns";
import { ChangeEvent, RefObject } from "react";
import checkNativeMonthSelectSupported from "../utils/checkNativeMonthSelectSupported";
import MonthSelect from "./MonthSelect";
import YearSelect from "./YearSelect";

interface MobileYearMonthPickerProps {
  monthDate: Date;
  monthPickerRef: RefObject<HTMLInputElement>;
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
  locale?: Locale;
}

const YearMonthPicker = ({
  monthDate,
  monthPickerRef,
  changeMonth,
  changeYear,
  locale,
}: MobileYearMonthPickerProps) => {
  const month = getMonth(monthDate);
  const year = getYear(monthDate);

  const isNativeMonthPickerSupported = checkNativeMonthSelectSupported();

  const selectYearMonth = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const year = parseInt(e.target.value.split("-")[0]);
      const month = parseInt(e.target.value.split("-")[1]);
      changeYear(year);
      changeMonth(month - 1);
    }
  };

  return isNativeMonthPickerSupported ? (
    <div className="relative">
      <span
        onClick={() => {
          monthPickerRef.current?.focus();
        }}
        className="w-24 cursor-pointer"
      >
        {format(monthDate, "MMMM yyyy", { locale })}
      </span>
      <input
        type="month"
        className="absolute h-0 w-0 opacity-0"
        onChange={selectYearMonth}
        value={format(monthDate, "yyyy-MM")}
        ref={monthPickerRef}
      />
      <CaretDown className="absolute top-1 -right-6 text-black" />
    </div>
  ) : (
    <div className="flex gap-2">
      <MonthSelect month={month} changeMonth={changeMonth} />
      <YearSelect year={year} changeYear={changeYear} />
    </div>
  );
};

export default YearMonthPicker;
