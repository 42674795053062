import { ReactNode, useState } from "react";
import classnames from "classnames";

type TooltipPositionType = "top" | "left" | "right" | "bottom";
interface TooltipProps {
  contents: string | JSX.Element;
  children: ReactNode;
  className?: string;
  position?: TooltipPositionType;
  tipClassName?: string;
  arrowClassName?: string;
}

const getPositionTooltipClass = (position: TooltipPositionType) => {
  switch (position) {
    case "top":
      return "bottom-[100%] pb-2 left-[50%] -translate-x-[50%]";
    case "left":
      return "right-[100%] pr-2 top-[50%] -translate-y-[50%]";
    case "right":
      return "left-[100%] pl-2 top-[50%] -translate-y-[50%]";
    default:
      return "top-[100%] pt-2 left-[50%] -translate-x-[50%]";
  }
};

const getPositionArrowClass = (position: TooltipPositionType) => {
  switch (position) {
    case "top":
      return "top-[100%] left-[50%] -ml-2 border-t-black";
    case "left":
      return "left-[100%] top-[50%] -mt-2 border-l-black";
    case "right":
      return "right-[100%] top-[50%] -mt-2 border-r-black";
    default:
      return "bottom-[100%] left-[50%] -ml-2 border-b-black";
  }
};

const Tooltip = ({
  contents,
  children,
  className,
  position = "bottom",
  tipClassName,
  arrowClassName,
}: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <div
      className={classnames("relative", className)}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div
          className={classnames(
            "absolute z-10",
            getPositionTooltipClass(position),
            tipClassName
          )}
        >
          <div
            className={classnames(
              `relative rounded-[5px] bg-black px-3 py-2.5 text-xs text-white shadow-dropdown`
            )}
          >
            <div className="break-words">{contents}</div>
            <div
              className={classnames(
                "absolute  border-8 border-transparent",
                getPositionArrowClass(position),
                arrowClassName
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
