import * as React from "react";
import { SVGProps } from "react";
const SvgChevronRightTwo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m1 11 5-5-5-5" stroke="currentColor" strokeWidth={2} />
  </svg>
);
export default SvgChevronRightTwo;
