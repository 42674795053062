import { SessionProvider } from "next-auth/react";
import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
  HydrateProps,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactNode, useState } from "react";
import { wrapper } from "src/redux/store";
import { CookiesProvider } from "react-cookie";
import AxiosProvider from "./AxiosProvider";
import { Session } from "next-auth";

interface AppProviderProps {
  children: ReactNode;
  session: Session | null | undefined;
  dehydratedState: HydrateProps;
}

const AppProviders = ({
  children,
  session,
  dehydratedState,
}: AppProviderProps) => {
  // @see https://react-query.tanstack.com/guides/ssr#using-hydration
  const [queryClient] = useState(() => new QueryClient());

  return (
    <CookiesProvider>
      <SessionProvider refetchInterval={60 * 5} session={session}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <AxiosProvider>
              {children}
              <ReactQueryDevtools initialIsOpen={false} />
            </AxiosProvider>
          </Hydrate>
        </QueryClientProvider>
      </SessionProvider>
    </CookiesProvider>
  );
};

export default wrapper.withRedux(AppProviders);
