import ChannelTalkScript from "./ChannelTalkScript";
import GtmScript from "./GtmScript";

const Scripts = () => {
  return (
    <>
      <GtmScript />
      <ChannelTalkScript />
    </>
  );
};

export default Scripts;
