import { Typography } from "../../index";
import Button from "../../Button";
import { Spinner } from "@dentlink/icons";
import classnames from "classnames";

type FormContentType = "addTracking" | "cancelOrder" | "declineOrder";

interface IFormContentProps {
  title: string;
  onCancel: () => void;
  onSubmit: () => void;
  formElement: JSX.Element;
  type: FormContentType;
  isLoading?: boolean;
}

const getTypeState = (type: FormContentType) => {
  const typeKeys: Array<FormContentType> = [
    "addTracking",
    "cancelOrder",
    "declineOrder",
  ];
  return typeKeys.reduce((acc, cur) => {
    acc[cur] = cur === type;
    return acc;
  }, {} as { [key in FormContentType]: boolean });
};

export default function FormContent({
  title,
  onCancel,
  onSubmit,
  formElement,
  type,
  isLoading = false,
}: IFormContentProps) {
  const curType = getTypeState(type);

  return (
    <div className="min-h-[282px] w-[423px] rounded-dropdown bg-white py-8 px-10">
      <div className="mx-auto">
        <section
          className={classnames({
            "mb-11": curType.addTracking,
            "mb-4": curType.cancelOrder || curType.declineOrder,
          })}
        >
          <Typography variant="title">{title}</Typography>
        </section>
        <section
          className={classnames("mb-[41px]", {
            "flex gap-5": curType.addTracking,
          })}
        >
          {formElement}
        </section>
        <section className="flex justify-center gap-3">
          <Button
            onClick={onCancel}
            variant="outlined"
            fullWidth
            elevation
            size="medium"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            fullWidth
            disabled={isLoading}
            elevation
            size="medium"
          >
            {isLoading ? <Spinner className="text animate-spin" /> : "OK"}
          </Button>
        </section>
      </div>
    </div>
  );
}
