import DrawerBase from "../../base/DrawerBase";
import Typography from "../Typography";
import Button from "../Button";
import BigCheckIcon from "./asset/BigCheckIcon";
import classnames from "classnames";
import { TypographyProps } from "../Typography/Typography";

export type Action = {
  label: string;
  onClick?: () => void;
  color?: "primary";
  variant?: "outlined" | "contained";
};

export interface BottomSheetProps {
  isOpen: boolean;
  toggle?: () => void;
  title?: string;
  description?: string;
  descriptionProps?: TypographyProps;
  drawerClasses?: string;
  actions?: Action[] | Action;
  withCheck?: boolean;
}

const BottomSheet = ({
  isOpen,
  toggle,
  title,
  description,
  actions,
  withCheck,
  drawerClasses,
  descriptionProps = { renderAs: "p", size: "base" },
}: BottomSheetProps) => {
  const drawerProps = { isOpen, toggle, placement: "bottom" as const };
  const drawerClass = classnames(
    "relative px-4 pb-6",
    { "pt-3.5": !withCheck, "pt-[30px]": withCheck },
    drawerClasses
  );

  const renderActions = actions ? [actions].flat() : undefined;
  const actionCount = renderActions?.length;

  return (
    <DrawerBase {...drawerProps} className={drawerClass}>
      {withCheck && (
        <div className="absolute left-4 top-[-33px]">
          <BigCheckIcon />
        </div>
      )}
      <Typography
        variant="title"
        className={classnames({
          "mb-4": !withCheck,
          "my-6": withCheck,
        })}
      >
        {title}
      </Typography>
      <Typography
        {...descriptionProps}
        className={classnames(
          "whitespace-pre-line",
          descriptionProps?.className,
          { "mb-6": !withCheck }
        )}
      >
        {description}
      </Typography>

      <div className={classnames("flex gap-2", { hidden: !actionCount })}>
        {renderActions?.map(({ label, ...btnProps }) => (
          <Button
            key={`bottom-sheet-${label}`}
            fullWidth
            size={actionCount === 1 ? "large" : "medium"}
            elevation
            {...btnProps}
          >
            {label}
          </Button>
        ))}
      </div>
    </DrawerBase>
  );
};

export default BottomSheet;
