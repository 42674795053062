import * as React from "react";
import { SVGProps } from "react";
const SvgDeleteOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.154 4.014h4.154v1.662h-1.662v10.8a.83.83 0 0 1-.831.831H3.184a.83.83 0 0 1-.831-.831v-10.8H.69V4.014h4.154V1.522a.831.831 0 0 1 .83-.831h6.648a.83.83 0 0 1 .83.831v2.492Zm.83 1.662h-9.97v9.97h9.97v-9.97ZM6.508 8.169h1.662v4.985H6.507V8.169Zm3.323 0h1.66v4.985H9.83V8.169ZM6.507 2.353v1.661h4.984V2.353H6.507Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgDeleteOutlined;
