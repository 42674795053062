import classnames from "classnames";
import Image from "next/legacy/image";
import { ThumbnailSize } from "../../../lib/Profile/types";
import { Company } from "../../../common/apis/UserAPI/types";

const thumbnailSizeMap = {
  small: "w-[60px]", // 미니 프로필
  medium: "w-[120px]", // 프로필 페이지
};

const textSizeMap = {
  small: "text-md", // 미니 프로필
  medium: "text-[40px]", // 프로필 페이지
};

const bgStyleMap = {
  Clinic: "bg-blueGray",
  Lab: "bg-primaryLight",
  Admin: "bg-primary",
};

const textColorMap = {
  Clinic: "text-white",
  Lab: "text-black",
  Admin: "text-white",
};

export interface ThumbnailProps {
  type: Company;
  size: ThumbnailSize;
  src?: string;
  className?: string;
  blurDataURL?: string;
  /** 프로필 이미지가 없는 상황에서 대신 보여줄 이름 첫 글자 */
  fallbackInitial?: string;
  isLoading?: boolean;
}

const ProfileImageThumbnail = ({
  type,
  size,
  src,
  className,
  blurDataURL,
  fallbackInitial,
  isLoading = false,
}: ThumbnailProps) => {
  const showSkeleton = isLoading && !src;
  const showPlaceholder = blurDataURL ? "blur" : undefined;

  return (
    <section
      className={classnames(
        "flex-center relative aspect-square overflow-hidden rounded-[10px]",
        className,
        thumbnailSizeMap[size],
        {
          "animate-pulse": showSkeleton,
          "border border-lineColor bg-transparent": src,
          [bgStyleMap[type]]: !src,
        }
      )}
    >
      {src && (
        <Image
          src={src}
          layout="fill"
          sizes="(max-width: 768px) 60px, 120px"
          objectFit="cover"
          alt="profile image"
          blurDataURL={blurDataURL}
          placeholder={showPlaceholder}
        />
      )}

      {!src && (
        <div
          className={classnames(
            "font-semibold",
            textColorMap[type],
            textSizeMap[size]
          )}
        >
          {fallbackInitial?.toUpperCase()}
        </div>
      )}
    </section>
  );
};

export default ProfileImageThumbnail;
