/* eslint-disable react/prop-types */
import { autoResizeHeight } from "@dentlink/utils";
import classnames from "classnames";
import React, {
  FocusEvent,
  FormEvent,
  forwardRef,
  ReactNode,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { GapStyles } from "../../constants/styles";
import useForkRef from "../../utils/useForkRef";
import Typography from "../Typography";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  textareaClasses?: string;
  grow?: boolean;
  helperText?: ReactNode;
  maxHeight?: number;
  gap?: keyof typeof GapStyles;
  /** if true always show remaining characters indicator */
  showCount?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      className,
      textareaClasses,
      maxHeight,
      helperText,
      gap = 8,
      showCount = false,
      ...props
    },
    ref
  ) => {
    const [_localRef, setlocalRef] = useState<HTMLTextAreaElement | null>(null);
    const handleRef = useForkRef(ref, setlocalRef);
    const [textLength, setTextLength] = useState<number>(0);

    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      if (_localRef) setTextLength(_localRef.value.length);
    }, [_localRef]);

    const onInput = (e: FormEvent<HTMLTextAreaElement>) => {
      autoResizeHeight(e, maxHeight);
      setTextLength((e.target as HTMLTextAreaElement).value.length);
    };

    const onBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
      props.onBlur?.(e);
      setIsFocused(false);
    };

    const onFocus = () => setIsFocused(true);

    const showHelperText = Boolean(helperText);

    const showRemainingCharactersCount = (() => {
      if (showCount) return true;
      return Boolean(props.maxLength) && isFocused;
    })();

    const showHelperTextArea = showHelperText || showRemainingCharactersCount;

    const textareaHeight = props.maxLength
      ? _localRef?.scrollHeight
      : undefined;

    return (
      <>
        <label
          className={classnames(
            "flex w-full flex-col focus-within:text-primary",
            className,
            GapStyles[gap]
          )}
        >
          {label && (
            <Typography variant="body2" color="blueGray">
              {props.required ? `${label}*` : label}
            </Typography>
          )}
          <textarea
            {...props}
            className={classnames(
              "min-h-[34px] resize-none border-b-2 border-lineColor pb-2 text-md text-black caret-primary/30 outline-none placeholder:text-grayText focus-within:border-primary",
              textareaClasses
            )}
            rows={1}
            ref={handleRef}
            onInput={onInput}
            onBlur={onBlur}
            onFocus={onFocus}
            style={{ height: textareaHeight }}
          />

          <div
            className={classnames("flex items-center justify-between", {
              hidden: !showHelperTextArea,
            })}
          >
            {showHelperText && <div>{helperText}</div>}
            {showRemainingCharactersCount && (
              <Typography
                size="xs"
                className={classnames("ml-auto", {
                  "text-lineColor": !isFocused,
                })}
              >
                {textLength} / {props.maxLength}
              </Typography>
            )}
          </div>
        </label>
      </>
    );
  }
);

export default Textarea;
