import { MouseEvent, ReactNode } from "react";
import classnames from "classnames";

type DatepickerAction = (e: MouseEvent) => void;

interface CalendarContainerProps {
  actions: DatepickerAction[];
  className?: string;
  children?: ReactNode;
  isOkButtonDisabled?: boolean;
}

const ButtonDefaultStyle =
  "flex justify-center items-center rounded w-full p-3 font-bold";

const CalendarContainer = ({
  className,
  children,
  actions,
  isOkButtonDisabled = false,
}: CalendarContainerProps) => {
  return (
    <div className={`${className} flex flex-col`}>
      {children}
      <div className="mt-[10px] flex gap-2">
        <button
          onClick={actions[0]}
          className={`${ButtonDefaultStyle} border-[1px] border-blueGray text-blueGray`}
        >
          Cancel
        </button>
        <button
          disabled={isOkButtonDisabled}
          onClick={actions[1]}
          className={classnames(`${ButtonDefaultStyle} text-white`, {
            "bg-primary": !isOkButtonDisabled,
            "bg-grayText/40": isOkButtonDisabled,
          })}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default CalendarContainer;
