import { createElement, HTMLAttributes, useRef } from "react";
import getUtilityClasses from "./getUtilityClasses";
import { ElementTypes, ElevationTypes, FlexDirection } from "./types";
import { useRipple } from "@dentlink/hooks";
import { RippleOptions } from "@dentlink/hooks/src/useRipple";

interface PaperProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * set Paper's flex direction. Default is column.
   */
  flex?: FlexDirection;
  /**
   * set if the paper should have shadow. Default is 2.
   */
  elevation?: ElevationTypes;
  /**
   * set if the paper should have rounded corner. Default is false
   */
  rounded?: boolean;
  /**
   * set if the paper should have padding. Default is false
   */
  padded?: boolean;
  /**
   * the element type of component
   */
  component?: ElementTypes;
  /** Whether to display ripple effects on click. Default is true */
  enableRipple?: boolean;
  /** Specify options for size and animation time when giving a ripple effect  */
  rippleOptions?: Omit<RippleOptions, "disabled">;
}

const Paper = ({
  className,
  children,
  flex = "column",
  rounded,
  elevation = 0,
  padded = false,
  enableRipple = false,
  rippleOptions = {},
  component = "div",
  ...props
}: PaperProps) => {
  const utilityClasses = getUtilityClasses({
    classes: className,
    options: {
      flex,
      rounded,
      elevation,
      padded,
    },
  });

  const ref = useRef<HTMLElement | null>(null);
  useRipple(ref, {
    disabled: !enableRipple,
    rippleSize: 300,
    animationLength: 600,
    ...rippleOptions,
  });

  const PaperComponent = createElement(
    component,
    {
      ...props,
      className: utilityClasses,
      type: component === "button" ? "button" : undefined,
      ref,
    },
    children
  );

  return PaperComponent;
};

export default Paper;
