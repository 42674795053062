import { ElevationTypes, FlexDirection } from "./types";
import {
  DefaultConfig,
  ElevationConfig,
  FlexConfig,
  PaddedConfig,
  RoundedConfig,
} from "./UtilityClassConfig";

interface getUtilityClassesProps {
  classes?: string;
  options?: {
    flex?: FlexDirection;
    rounded?: boolean;
    elevation: ElevationTypes;
    padded: boolean;
  };
}

export default function getUtilityClasses({
  classes,
  options,
}: getUtilityClassesProps) {
  const { flex, rounded, elevation, padded } = options || {};

  const utilityClasses = [
    DefaultConfig,
    flex && FlexConfig[flex],
    rounded && RoundedConfig,
    padded && PaddedConfig,
    elevation && ElevationConfig[elevation],
    classes,
  ]
    .filter(Boolean)
    .join(" ");

  return utilityClasses;
}
