import { isAndroid, osVersion, isIOS } from "react-device-detect";

/**
 * Check if device can use input type="month"
 * @returns can use <input type='month' /> for month select.
 */
export default function checkNativeMonthSelectSupported() {
  const isOverIOS15 = isIOS && Number(osVersion) >= 15;

  const isOverAndroid12 = isAndroid && Number(osVersion) >= 12;

  const isNativeMonthSelectSupported = isOverIOS15 && isOverAndroid12;

  return isNativeMonthSelectSupported;
}
