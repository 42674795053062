import { UsersAPI } from "../apis/UserAPI";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { UseMutationOptions } from "@tanstack/react-query";
import {
  EditProfilePayload,
  ProfileType,
  TClinicProfile,
} from "../apis/UserAPI/types";

type QueryOption<T> = UseMutationOptions<T, AxiosError, EditProfilePayload>;

export default function useProfileMutation<
  T extends ProfileType = TClinicProfile
>(options: QueryOption<T> = {}) {
  const { editUserProfile } = UsersAPI;

  return useMutation<T, AxiosError, EditProfilePayload>(
    async (payload) => (await editUserProfile<T>(payload)).data,
    options
  );
}
