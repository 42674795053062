import { Spinner } from "@dentlink/icons";
import classnames from "classnames";

interface LoadingProps {
  className?: string;
  color?: keyof typeof colors;
  size?: number;
}

const colors = {
  primary: "text-primary",
  black: "text-black",
  white: "text-white",
  "white/50": "text-white/50",
  blueGray: "text-blueGray",
} as const;

const Loading = ({ className, size = 24, color = "primary" }: LoadingProps) => {
  const classes = classnames("animate-spin text", className, colors[color]);
  return <Spinner className={classes} width={size} height={size} />;
};

export default Loading;
