import { Modal, Typography } from "@dentlink/ui";
import Image from "next/legacy/image";
import { FileInfo } from "../../../common/apis/FileAPI/types";
import classnames from "classnames";
import React, { useState } from "react";
import Carousel from "src/components/Carousel/Carousel";
import CarouselImage from "src/components/Carousel/CarouselImage";
import useIsMobile from "../../../common/hooks/useIsMobile";
import LinkifyTag from "./LinkifyTag";

export interface DenturistBioProps {
  bio: string;
  sampleImages: FileInfo[];
  showMore: boolean;
}

export default function DenturistBio({
  bio,
  sampleImages,
  showMore,
}: DenturistBioProps) {
  const [showCarousel, setShowCarousel] = useState(false);
  const isMobile = useIsMobile();

  const openCarousel = () => setShowCarousel(true);

  return (
    <div
      className={classnames("flex flex-col gap-3 transition-all ease-linear", {
        "invisible max-h-0 opacity-0": !showMore,
        "visible max-h-[150px]": showMore,
        "mt-4": showMore,
      })}
    >
      <Typography
        size="xs"
        className="w-full whitespace-pre-line break-all line-clamp-4"
      >
        <LinkifyTag content={bio} />
      </Typography>
      <div
        className={classnames("flex gap-[7px]", {
          hidden: sampleImages.length === 0,
        })}
      >
        {sampleImages.map(({ file_id, file_url }, i) => (
          <button
            key={file_id}
            type="button"
            onClick={openCarousel}
            className="flex-center overflow-hidden border border-lineColor"
          >
            <Image
              src={file_url}
              objectFit="cover"
              layout="fixed"
              width={63}
              height={63}
              alt={`Sample image ${i}`}
            />
          </button>
        ))}
      </div>

      <Modal isOpen={showCarousel}>
        <Carousel
          options={{ hideBodyScroll: true, arrows: !isMobile ?? true }}
          onClose={() => setShowCarousel(false)}
        >
          {sampleImages.map(({ file_id, file_url }, i) => (
            <CarouselImage
              key={file_id}
              src={file_url}
              alt={`Sample image ${i}`}
            />
          ))}
        </Carousel>
      </Modal>
    </div>
  );
}
