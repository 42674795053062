import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useIsomorphicLayoutEffect } from "./index";

export default function useIsMobileUserAgent() {
  const [_isMobile, setIsMobile] = useState<boolean>(false);

  useIsomorphicLayoutEffect(() => {
    setIsMobile(isMobile);
  });

  return _isMobile;
}
