import { SearchEmpty } from "@dentlink/icons";
import { Typography } from "@dentlink/ui";
import classNames from "classnames";

const PlaceHolder = ({ className }: { className?: string }) => {
  return (
    <div className={classNames("flex-center flex-col gap-4", className)}>
      <SearchEmpty className="h-[77px] w-[77px]" />
      <Typography variant="body1" className="text-iconColor">
        No Results Found
      </Typography>
    </div>
  );
};

export default PlaceHolder;
