import Button from "../../Button";
import Typography from "../../Typography";
import { Action } from "../../BottomSheet/BottomSheet";

interface IInfoSuccessProps {
  title: string;
  description: string;
  actions: Action[];
}

const Confirm = function ({ title, description, actions }: IInfoSuccessProps) {
  return (
    <div className="min-h-[263px] w-[423px] rounded-dropdown bg-white px-10 py-8">
      <Typography variant="title" className="mb-4">
        {title}
      </Typography>
      <Typography renderAs="p" size="base" className="mb-6 whitespace-pre-line">
        {description}
      </Typography>
      <div className="flex gap-[13px]">
        {actions.map((action) => (
          <Button
            key={action.label}
            className="flex-1"
            elevation
            variant={action.variant}
            onClick={action.onClick}
          >
            {action.label}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Confirm;
