import { MouseEvent } from "react";
import { Button, Typography } from "../../index";
import BigCheckIcon from "../../BottomSheet/asset/BigCheckIcon";

export interface IModalInfoProps {
  title: string;
  label?: string;
  onClick: (event: MouseEvent) => void;
}

const InfoSuccess = function ({
  title,
  label = "OK",
  onClick,
}: IModalInfoProps) {
  return (
    /** 바깥 Wrapper */
    <div className="relative w-[423px]">
      <div className="relative -bottom-10 mx-auto w-fit">
        <BigCheckIcon />
      </div>
      {/** 흰색 배경 콘텐츠 영역 */}
      <div className="rounded-dropdown bg-white px-10 py-8">
        <Typography
          renderAs="h2"
          size="xl"
          weight="bold"
          className="py-6 leading-[50px]"
        >
          {title}
        </Typography>
        <Button fullWidth onClick={onClick}>
          {label}
        </Button>
      </div>
    </div>
  );
};

export default InfoSuccess;
