import { TypographyVariant, TypographyRenderType } from "../types";

export default function getRenderTag(
  variant?: TypographyVariant,
  renderAs?: TypographyRenderType
): TypographyRenderType {
  if (renderAs) {
    return renderAs;
  }

  switch (variant) {
    case "title":
      return "h2";
    case "subtitle":
      return "h3";
    case "body1":
      return "p";
    case "body2":
      return "p";
    case "caption":
      return "p";
    default:
      return "span";
  }
}
