import classnames from "classnames";
import { Licensed as LicensedIcon } from "@dentlink/icons";
import { Typography } from "@dentlink/ui";

const titleSkeleton = "animate-pulse bg-grayText/30 w-20 h-5 rounded";
const blueGraySkeleton = "animate-pulse bg-grayText/30 w-10 h-5 rounded";

interface MainTitleProps {
  isLoading: boolean;
  title?: string;
}

const MainTitle = ({ title, isLoading }: MainTitleProps) => {
  return (
    <Typography
      size="sm"
      weight="bold"
      color="black"
      className={classnames({ [titleSkeleton]: isLoading })}
    >
      {title}
    </Typography>
  );
};

interface SubTitleProp {
  isLoading: boolean;
  blueGray?: string;
}

const SubTitle = ({ blueGray, isLoading }: SubTitleProp) => {
  return (
    <Typography
      size="xs"
      weight="regular"
      color="grayText"
      className={classnames({ [blueGraySkeleton]: isLoading })}
    >
      {blueGray}
    </Typography>
  );
};

export interface NoBadgeProps {
  isLoading: boolean;
  hasSubTitle: boolean;
  title?: string;
  blueGray?: string;
}

function UserName({ isLoading, hasSubTitle, title, blueGray }: NoBadgeProps) {
  return (
    <section className={classnames("flex flex-col", { "gap-1": isLoading })}>
      <MainTitle title={title} isLoading={isLoading} />
      {hasSubTitle && <SubTitle blueGray={blueGray} isLoading={isLoading} />}
    </section>
  );
}

export type WithBadgeProps = MainTitleProps & { isLicensed: boolean };

function UserNameWithBadge({ isLoading, isLicensed, title }: WithBadgeProps) {
  return (
    <section className={classnames("flex flex-col gap-1.5 text-primary")}>
      <div
        className={classnames("h-4 w-16 animate-pulse rounded bg-primary/20", {
          hidden: !isLoading,
        })}
      />
      <LicensedIcon
        width={65}
        height={16}
        className={classnames({ hidden: !isLicensed || isLoading })}
      />
      <MainTitle title={title} isLoading={isLoading} />
    </section>
  );
}

export { UserName, UserNameWithBadge };
