import { OrderData } from "src/common/apis/OrderAPI/types";
import isEmpty from "lodash/isEmpty";
import { OrderFormValues } from "../types/OrderForm";
import { DeepPartial } from "react-hook-form";
import omit from "lodash/omit";

const DefaultValue: DeepPartial<OrderFormValues> = {
  clinic: undefined,
  patient_gender: "male" as const,
  patient_birthdate: undefined,
  patient_name: "",
  impression_type: [],
  numbering_system: "FDI",
  order_type: "newcase",
  arrival_date: undefined,
  scan_files: [],
  image_files: [],
  cases: [
    {
      dental_formula: [],
      shade: undefined,
      prosthesis: undefined,
      materials: [],
    },
  ],
  order_options: [],
  order_others: [{ other_price: undefined, other_name: undefined }],
};

const getOrderFormDefaultValues = (
  data: OrderData | null,
  options: { isRemake?: boolean; isReorder?: boolean } = {}
) => {
  if (!data) {
    return DefaultValue;
  }
  return {
    clinic: {
      label: data.from_company_name,
      value: data.from_user_id,
      system: data.numbering_system,
    },
    patient_gender: data.patient_gender,
    patient_birthdate: data.patient_birthdate
      ? new Date(data.patient_birthdate)
      : undefined,
    patient_name: data.patient_name,
    impression_type: data.impression_type,
    numbering_system: data.numbering_system,
    arrival_date:
      options.isRemake || options.isReorder
        ? undefined
        : new Date(data.arrival_date),
    scan_files: data.scan_files,
    image_files: data.image_files,
    cases: data.cases.map((item) => ({
      dental_formula: item.dental_formula,
      prosthesis: {
        value: item.prosthesis.prosthesis_id,
        label: item.prosthesis.prosthesis_name,
        hasChild: item.prosthesis.hasChild,
      },
      materials: item.materials.map((mat_item) => ({
        value: mat_item.material_id,
        label: mat_item.material_name,
        hasChild: mat_item.hasChild,
      })),
      shade: isEmpty(item.shade)
        ? undefined
        : {
            label: item.shade,
            value: item.shade,
          },
    })),
    description: data.description,
    order_options: data.order_options,
    order_others: data.order_others.map((item) => omit(item, "other_id")),
    shipping: {
      tracking_code: data.shipping?.tracking_code,
      courier_service: {
        value: data.shipping?.courier_service,
        label: data.shipping?.courier_service,
      },
    },
  };
};

export default getOrderFormDefaultValues;
