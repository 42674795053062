import { forwardRef, ReactNode, useCallback, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import DatepickerHeader from "./components/DatepickerHeader";
import getDayClassGenerator from "./utils/getDayClassGenerator";
import { DatePickerPopperOptions } from "../Popper/types";
import { GapStyles } from "../../constants/styles";
import useDatepickerRef from "./hooks/useDatepickerRef";
import WritableCustomInput from "./components/WritableCustomInput";
import { format } from "date-fns";
import CustomInput from "./components/CustomInput";

interface DatepickerProps {
  /**
   * currently selected date.
   */
  date: Date | null;
  /**
   * setDate function to update the date
   */
  setDate: (date: Date | null) => void;
  /**
   * If true, the input will be required.
   */
  required?: boolean;
  /**
   * the label for datepicker
   */
  label?: string;
  /**
   * The locale to use for the date picker.
   * import this from date-fns/locale
   */
  locale?: Locale;
  /**
   * lower-boundary date to select in the datepicker
   */
  minDate?: Date | null;
  /**
   * upper-boundary date to select in the datepicker
   */
  maxDate?: Date | null;
  /**
   * HelperText Component to use for the datepicker (ex. error message)
   */
  helperText?: ReactNode;
  /**
   * If true, holidays including sundays will be highlighted.
   */
  highlightHolidays?: boolean;
  /**
   * custom holidays list.
   */
  holidays?: string[];
  /**
   * Datepciker place holder text
   */
  placeholderText?: string;
  /**
   * popper options to use for the datepicker
   */
  popperOptions?: DatePickerPopperOptions;
  /**
   * gap between label and datepicker input
   */
  gap?: keyof typeof GapStyles;
  readOnly?: boolean;
  dateFormat?: string;
  customInputType?: "select" | "writable";
  hasError?: boolean;
  preventOpen?: boolean;
}

/**
 * selects a single date
 * @param {DatepickerProps} props
 */
const Datepicker = forwardRef<ReactDatePicker, DatepickerProps>(
  (
    {
      locale,
      label,
      date,
      setDate,
      highlightHolidays,
      holidays,
      helperText,
      popperOptions,
      placeholderText = "",
      gap = 8,
      dateFormat,
      customInputType = "select",
      hasError = false,
      preventOpen = false,
      ...rest
    },
    ref
  ) => {
    const { datepickerRef, customInputRef } = useDatepickerRef(ref);
    const monthPickerRef = useRef<HTMLInputElement>(null);

    const [isCalendarOpen, setCalendarOpen] = useState<boolean>(false);

    const dayClassGenerator = getDayClassGenerator({
      highlightHolidays,
      holidays,
    });

    const toggleCalendarOpen = useCallback(() => {
      if (!preventOpen) {
        setCalendarOpen((isCalendarOpen) => !isCalendarOpen);
      }
    }, [preventOpen]);
    const closeCalendar = useCallback(() => {
      if (!preventOpen) {
        setCalendarOpen(false);
      }
    }, [preventOpen]);

    const onChange = useCallback(
      (date: Date) => {
        setDate(date);
        /** 기존 로직을 유지하기 위해 임시로 이렇게 작성 */
        if (customInputType === "select") closeCalendar();
      },
      [closeCalendar, customInputType, setDate]
    );

    const CustomInputMap = {
      select: (
        <CustomInput
          inputValue={date ? format(date, "MMMM d, yyyy", { locale }) : ""}
          label={label}
          helperText={helperText}
          gap={gap}
          inputRef={customInputRef}
          hasError={hasError}
        />
      ),
      writable: (
        <WritableCustomInput
          ref={customInputRef}
          closeCalendar={closeCalendar}
          label={label}
        />
      ),
    };

    return (
      <ReactDatePicker
        ref={datepickerRef}
        selected={date}
        locale={locale}
        open={isCalendarOpen}
        onChange={onChange}
        onSelect={closeCalendar}
        onClickOutside={closeCalendar}
        onInputClick={toggleCalendarOpen}
        dayClassName={dayClassGenerator}
        disabledKeyboardNavigation
        customInput={CustomInputMap[customInputType]}
        renderCustomHeader={(props) => (
          <DatepickerHeader monthPickerRef={monthPickerRef} {...props} />
        )}
        placeholderText={placeholderText}
        formatWeekDay={(weekDayName) => weekDayName.slice(0, 1)}
        dateFormat={dateFormat}
        {...popperOptions}
        {...rest}
      />
    );
  }
);

export default Datepicker;
