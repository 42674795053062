export function isElement(el: any): el is Element {
  return (
    el != null &&
    typeof el == "object" &&
    "nodeType" in el &&
    el.nodeType === Node.ELEMENT_NODE
  );
}

export function getOwnerDocument(node?: Element | null): Document {
  return isElement(node) ? node.ownerDocument ?? document : document;
}

export function disableBodyScrolling() {
  const body = window?.document.querySelector("body");
  if (body) {
    body.style.overflow = "hidden";
  }
}

export function enableBodyScrolling() {
  const body = window?.document.querySelector("body");
  if (body) {
    body.style.overflow = "";
  }
}

export function getDocumentWidth() {
  return Math.min(
    window.innerWidth,
    document.body.clientWidth,
    document.documentElement.clientWidth
  );
}

export function autoResizeHeight(e: any, maxHeight = 9999) {
  const textareaEl = e.target as HTMLTextAreaElement;
  if (textareaEl.scrollHeight < maxHeight) {
    textareaEl.style.height = "inherit";
    textareaEl.style.height = `${textareaEl.scrollHeight}px`;
  }
}
