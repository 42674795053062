import { createSlice } from "@reduxjs/toolkit";

export type CommentUploaderState = {
  stagedFileNumber: number;
};

const initialState: CommentUploaderState = {
  stagedFileNumber: 0,
};

const commentUploaderSlice = createSlice({
  name: "commentUploader",
  initialState,
  reducers: {
    increaseStagedFileNumber: (state: CommentUploaderState) => {
      state.stagedFileNumber++;
    },
    decreaseStagedFileNumber: (state: CommentUploaderState) => {
      state.stagedFileNumber--;
    },
  },
});

export const { increaseStagedFileNumber, decreaseStagedFileNumber } =
  commentUploaderSlice.actions;

export default commentUploaderSlice;
