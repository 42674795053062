import axiosInstance from "src/common/apis/axiosInstance";
import { PaymentMethod, RegisterPaymentMethodPayload } from "./type";
import { AxiosRequestConfig } from "axios";

export const registerMethod = async (payload: RegisterPaymentMethodPayload) => {
  return await axiosInstance.post("/payments/regist", payload);
};

/**
 * 현재 등록된 카드가 있는지 조회하는 API
 * @returns {boolean} 카드가 있으면 true, 없으면 false
 */
export const lookupPaymentMethod = async (config: AxiosRequestConfig = {}) => {
  return await axiosInstance.get<PaymentMethod[]>("/payments", config);
};

export const deletePaymentMethod = async (paymentId: number | string) => {
  return await axiosInstance.delete(`payments/${paymentId}`);
};
