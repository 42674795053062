import type { FontSize, FontWeight, FontColor } from "@dentlink/types";
import { fontColorConfig, fontSizeConfig, fontWeightConfig } from "./configs";
import { UtilityClassGenerator } from "./type";

interface getFontClassProps {
  size?: FontSize;
  color?: FontColor;
  weight?: FontWeight;
}

const getFontClasses: UtilityClassGenerator<getFontClassProps> = ({
  size = "inherit",
  color = "text",
  weight = "inherit",
}) => {
  return [
    fontColorConfig[color],
    fontWeightConfig[weight],
    fontSizeConfig[size],
  ]
    .filter(Boolean)
    .join(" ");
};

export default getFontClasses;
