import { useCallback, useRef } from "react";
import { useIsomorphicLayoutEffect } from "@dentlink/hooks";
import { AutoSlideInterval } from "../types";

interface UseFrameIntervalProps {
  callback: () => void;
  isActive: boolean;
  interval: AutoSlideInterval;
}

export default function useFrameInterval({
  callback,
  isActive,
  interval,
}: UseFrameIntervalProps) {
  const frame = useRef<number>();
  const prevTime = useRef<DOMHighResTimeStamp>();
  const savedCallback = useRef(callback);

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const animate = useCallback(
    (time: DOMHighResTimeStamp) => {
      if (!prevTime.current) prevTime.current = time;

      if (time - prevTime.current >= interval) {
        savedCallback.current();
        prevTime.current = time;
      }

      frame.current = requestAnimationFrame(animate);
    },
    [interval]
  );

  useIsomorphicLayoutEffect(() => {
    if (isActive) {
      frame.current = requestAnimationFrame(animate);
      return () => {
        if (frame.current) cancelAnimationFrame(frame.current);
      };
    }
  }, [animate, isActive]);
}
