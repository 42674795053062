import {
  useIsMobileUserAgent,
  useIsSmallerThanBreakPoint,
} from "@dentlink/hooks";
import BreakPoints from "../constants/BreakPoint";

/** 기본값 authenticatedPage breakpoint(768px) 기준
 * 모바일 레이아웃 ~767px, 데스크톱 레이아웃 768px~ */
export default function useIsMobile({
  breakPointWidth = BreakPoints.authenticatedPage,
  mode = "breakpoint",
}: {
  breakPointWidth?: number;
  mode?: "breakpoint" | "userAgent";
} = {}) {
  const isMobileAgent = useIsMobileUserAgent();
  const isSmallerThanBreakpoint = useIsSmallerThanBreakPoint(breakPointWidth);

  return mode === "breakpoint" ? isSmallerThanBreakpoint : isMobileAgent;
}
