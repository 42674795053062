import Header from "./Header";

const MainHeader = () => {
  return (
    <Header>
      <div className="mx-auto flex h-full w-full max-w-[40rem] items-center justify-between px-4">
        <Header.Logo enableLink variant="color" />
        <Header.Navbar />
      </div>
    </Header>
  );
};

export default MainHeader;
