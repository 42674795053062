import { FontColor, FontSize, FontWeight } from "@dentlink/types";
import { TypographyVariant } from "../types";

interface getTextStylesProps {
  variant?: TypographyVariant;
  weight?: FontWeight;
  size?: FontSize;
  color?: FontColor;
}

type TextStyles = {
  weight: FontWeight;
  size: FontSize;
  color: FontColor;
};

export default function getTextStyles({
  variant,
  weight,
  size,
  color,
}: getTextStylesProps) {
  const defaultStyle: TextStyles = {
    weight: "regular",
    size: "md",
    color: "inherit",
  };

  switch (variant) {
    case "body2":
      defaultStyle.size = "sm";
      break;
    case "caption":
      defaultStyle.size = "sm";
      defaultStyle.color = "grayText";
      break;
    case "title":
      defaultStyle.size = "xl";
      defaultStyle.weight = "bold";
      break;
    case "subtitle":
      defaultStyle.size = "xl";
      defaultStyle.weight = "semibold";
      break;
  }
  return {
    weight: weight || defaultStyle.weight,
    size: size || defaultStyle.size,
    color: color || defaultStyle.color,
  };
}
