import * as React from "react";
import { SVGProps } from "react";
const SvgMagnifierOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.214 14.977 4.387 4.386-1.45 1.45-4.386-4.387a9.222 9.222 0 1 1 1.449-1.45Zm-2.055-.76a7.168 7.168 0 1 0-.154.154l.154-.154Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMagnifierOutlined;
