import { ColorConfigType, VariantConfigType } from "./types";

export const ButtonColorConfig: ColorConfigType = {
  contained: {
    primary: "bg-primary text-white",
    black: "bg-black text-white",
    blueGray: "bg-blueGray text-blueGray",
  },
  outlined: {
    primary: "bg-white text-primary border-primary",
    black: "bg-white text-black border-black",
    blueGray: "bg-white text-blueGray border-blueGray",
  },
};

export const DisabledButtonColorConfig: ColorConfigType = {
  contained: {
    primary: "bg-primary/50 text-white/50 border-primary/50",
    black: "bg-black/50 text-white/50 border-black/50",
    blueGray: "bg-blueGray/50 text-blueGray/50 border-blueGray/50",
  },
  outlined: {
    primary: "bg-white/50 text-primary/50 border-primary/50",
    black: "bg-white/50 text-black/50 border-black/50",
    blueGray: "bg-white/50 text-blueGray/50 border-blueGray/50",
  },
};

export const ButtonVariantConfig: VariantConfigType = {
  outlined: {
    defaultColorScheme: "blueGray",
  },
  contained: {
    defaultColorScheme: "primary",
  },
};

export const SizeConfig = {
  outlined: {
    small: "h-[29px] px-2 text-xs border space-x-1 hover:shadow-button",
    medium: "h-[54px] text-md border space-x-2.5",
    large: "h-[63px] text-md border space-x-2.5",
  },
  contained: {
    small: "h-[29px] px-2 text-xs space-x-1 hover:shadow-button",
    medium: "h-[54px] text-md space-x-2.5",
    large: "h-[63px] text-md space-x-2.5",
  },
};
