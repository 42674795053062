import * as React from "react";
import { SVGProps } from "react";
const SvgLogoGray = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 110 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.4}>
      <path
        d="M31.141 6.695c3.894 0 6.42 2.381 6.42 6.037 0 3.655-2.526 6.036-6.42 6.036H26.16v-9.93c0-1.183.99-2.143 2.21-2.143h2.772Zm-.32 9.381c2.329 0 3.664-1.222 3.664-3.344 0-2.123-1.335-3.345-3.664-3.345h-1.602v6.69h1.602ZM41.672 16.37h5.28v2.398h-8.05v-9.93c0-1.183.918-2.143 2.05-2.143h6v2.362h-5.28v2.45h5.28v2.33h-5.28v2.537-.004ZM56.149 6.695h2.876v12.073h-2.438l-5.418-7.126v7.126h-2.876V6.695h2.421l5.435 7.142V6.695ZM71.098 6.695V9.42h-3.566v9.348h-2.93V9.42h-3.565V6.695h10.06ZM76.234 16.024h5.595v2.744h-8.72V6.695h3.125v9.333-.004ZM83.17 18.768v-9.93c0-1.183 1.086-2.143 2.423-2.143h.932v12.073H83.17ZM95.23 6.695h2.697v12.073H95.64l-5.079-7.126v7.126h-2.696V6.695h2.27l5.094 7.142V6.695ZM106.387 18.768l-3.06-4.78h-.983v4.78h-3.076v-9.93c0-1.183.995-2.143 2.222-2.143h.854v4.656h.983l2.952-4.656h3.576l-3.898 5.915L110 18.768h-3.613Z"
        fill="#1E1E32"
      />
      <path
        d="m16.148 2.377-.02-.008c-.085-.032-.17-.064-.26-.092-.01-.004-.026-.007-.038-.011l-.244-.072c-.02-.004-.043-.008-.062-.016-.074-.02-.151-.035-.229-.051A4.139 4.139 0 0 0 15 2.072c-.03-.005-.066-.009-.1-.013-.066-.007-.136-.02-.202-.027l-.136-.012c-.058-.004-.112-.012-.17-.012A7.69 7.69 0 0 0 14.085 2h-.004C10.774 2 8.096 4.719 8.05 8.084v.044h3.016c1.667 0 3.017 1.383 3.02 3.083v5.545H18.2c1.062 0 1.923-.88 1.923-1.966V8.175c0-2.667-1.655-4.938-3.97-5.798h-.004Z"
        fill="#9E9EAA"
      />
      <path
        d="M6.039 16.663V8.037H1.924C.86 8.037 0 8.917 0 10.002v6.617c0 2.667 1.652 4.937 3.971 5.797.008 0 .012.004.02.008.085.032.17.064.26.091l.038.012.245.072a3.86 3.86 0 0 0 .29.067 4.235 4.235 0 0 0 .295.055l.101.012c.066.008.136.02.202.028l.136.012c.058.004.112.012.17.012.101.004.202.008.307.008h.004c3.308 0 5.992-2.718 6.034-6.083v-.043H6.04v-.004Z"
        fill="#1E1E32"
      />
    </g>
  </svg>
);
export default SvgLogoGray;
