import * as React from "react";
import { SVGProps } from "react";
const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m11.986 19.17 5.573 3.678c.719.472 1.603-.23 1.393-1.092l-1.614-6.598a1.039 1.039 0 0 1 .02-.554c.057-.179.161-.337.3-.458l4.998-4.333c.653-.564.321-1.702-.53-1.76l-6.524-.436a.897.897 0 0 1-.492-.185.952.952 0 0 1-.315-.436L12.362.628a.951.951 0 0 0-.335-.455.894.894 0 0 0-1.053 0 .951.951 0 0 0-.336.455L8.205 6.996a.951.951 0 0 1-.315.436.896.896 0 0 1-.492.185l-6.524.437C.023 8.11-.309 9.249.344 9.813l4.997 4.333c.14.12.244.28.3.458.058.18.065.371.021.554l-1.493 6.115c-.254 1.035.807 1.874 1.659 1.311l5.186-3.414a.882.882 0 0 1 .973 0Z"
      fill="#E1E3E8"
    />
  </svg>
);
export default SvgStar;
