import { PageBackground } from "src/layouts";
import { signOut, useSession } from "next-auth/react";
import { AuthConfig } from "../../types/Auth";
import { NextComponentType, NextPageContext } from "next";
import { ReactNode } from "react";
import axiosInstance from "../../apis/axiosInstance";

export type NextComponentWithAuth<IP, P> = NextComponentType<
  NextPageContext,
  IP,
  P
> &
  Partial<{ auth: AuthConfig; shouldRedirect?: boolean }>;

interface AuthComponentProps {
  /**
   * component to render if user is authenticated
   */
  children: ReactNode;
  auth?: AuthConfig | boolean;
}

/*
const getAuthConfig = (
  auth: boolean | undefined | AuthConfig
): AuthConfig | { loading: undefined; fallbackPath: undefined } => {
  if (typeof auth === "object") {
    return auth;
  }
  return { loading: undefined, fallbackPath: undefined };
};
*/

const Auth = ({ children }: AuthComponentProps) => {
  const session = useSession({
    required: true,
  });

  if (session.status === "loading") {
    return <PageBackground />;
  }

  if (session.data.error === "RefreshAccessTokenError") signOut();

  if (session.data.user.accessToken) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.data.user.accessToken}`;
  }

  return <>{children}</>;
};

export default Auth;
