import * as React from "react";
import { SVGProps } from "react";
const SvgPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.5 9.208v-.001a.068.068 0 0 1 .02-.049L9.153.525h0l.002-.002a.076.076 0 0 1 .108 0h0l.003.002 3.209 3.21h0l.002.002a.075.075 0 0 1 .017.083.075.075 0 0 1-.017.025l-.002.002L3.822 12.5H.576a.076.076 0 0 1-.076-.076V9.208Z"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m7 4 2 2"
      stroke="#000"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPencil;
