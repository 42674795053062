import { FontSize } from "@dentlink/types";
import { ListStyle } from "./type";

export const getTextSizeClass = (size: FontSize) => {
  switch (size) {
    case "xs": // 12px
      return "text-xs";
    case "sm": // 14px
      return "text-sm";
    case "md": // 16px
      return "text-md";
    case "base": // 18px
      return "text-base";
    case "lg": // 24px
      return "text-lg";
    case "xl": // 28px
      return "text-xl";
  }
};

export const getListStyleClass = (style: ListStyle) => {
  switch (style) {
    case "none":
      return "list-none";
    case "disc":
      return "list-disc";
    case "decimal":
      return "list-decimal";
    case "circle":
      return "list-[circle]";
    case "lower-roman":
      return "list-[lower-roman]";
    case "lower-alpha":
      return "list-[lower-alpha]";
  }
};
