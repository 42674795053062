import { Calendar } from "@dentlink/icons";
import { getYear } from "date-fns";
import DatePicker from "react-datepicker";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Placement } from "../Popper/types";
import { ChangeEvent, forwardRef, InputHTMLAttributes } from "react";
import DatePickerChevron from "./components/DatePickerChevron";

interface YearPickerProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  onBlur?: () => void;
  isMobile: boolean;
  name: string;
}

interface YearPickerCustomInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  isMobile: boolean;
  label: string;
}

const YearPickerCustomInput = forwardRef<
  HTMLInputElement,
  YearPickerCustomInputProps
>(({ isMobile, label, onChange, ...inputProps }, ref) => {
  const onChangeForOnlyAllowNumber = (e: ChangeEvent<HTMLInputElement>) => {
    // 숫자만 허용
    if (!e.target.value.match(/\D/g)) onChange && onChange(e);
  };

  return (
    <section className="flex flex-col gap-2">
      <label className="text-sm text-blueGray">{label}</label>
      <div className="flex items-center border-b-2 border-lineColor pb-1 focus-within:border-primary">
        <input
          {...inputProps}
          ref={ref}
          readOnly={isMobile}
          onChange={onChangeForOnlyAllowNumber}
          type="text"
          pattern="\d*"
          inputMode="numeric"
          maxLength={4}
          className="cursor-pointer border-none outline-none placeholder:text-grayText"
        />
        <Calendar />
      </div>
    </section>
  );
});

const popperOptions = {
  popperPlacement: "bottom" as Placement,
  popperModifiers: [
    { name: "offset", enabled: true, options: { offset: [0, 5] } },
    { name: "preventOverflow", enabled: true },
  ],
};

const YearPicker = forwardRef<ReactDatePicker, YearPickerProps>(
  ({ onChange, onBlur, value, name, label, isMobile }, ref) => {
    const onYearChange: ReactDatePickerProps["onChange"] = (date) => {
      if (!date) return;
      const year = getYear(date).toString();
      onChange(year);
    };

    const selected = new Date(value);
    const customInputProps = { isMobile, label };

    return (
      <DatePicker
        {...popperOptions}
        ref={ref}
        name={name}
        showYearPicker
        dateFormat="yyyy"
        selected={selected}
        onBlur={onBlur}
        onChange={onYearChange}
        customInput={<YearPickerCustomInput {...customInputProps} />}
        previousYearButtonLabel={<DatePickerChevron direction="left" />}
        nextYearButtonLabel={<DatePickerChevron direction="right" />}
      />
    );
  }
);

export default YearPicker;
