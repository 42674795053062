import { createSlice } from "@reduxjs/toolkit";

export type OrderFormState = {
  stagedFileNumber: number;
};

const initialState: OrderFormState = {
  stagedFileNumber: 0,
};

const orderFormSlice = createSlice({
  name: "orderForm",
  initialState,
  reducers: {
    increaseStagedFileNumber: (state: OrderFormState) => {
      state.stagedFileNumber++;
    },
    decreaseStagedFileNumber: (state: OrderFormState) => {
      state.stagedFileNumber--;
    },
    clearStagedFileNumber: (state: OrderFormState) => {
      state.stagedFileNumber = 0;
    },
  },
});

export const {
  increaseStagedFileNumber,
  decreaseStagedFileNumber,
  clearStagedFileNumber,
} = orderFormSlice.actions;

export default orderFormSlice;
