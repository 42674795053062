import axiosInstance from "src/common/apis/axiosInstance";
import { useSession } from "next-auth/react";
import { ReactNode, useEffect, useMemo } from "react";

const useSetAuthorizationHeader = (accessToken: string) => {
  useEffect(() => {
    if (accessToken) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
    } else {
      delete axiosInstance.defaults.headers.common["Authorization"];
    }
  }, [accessToken]);
};

const AxiosProvider = ({ children }: { children: ReactNode }) => {
  const session = useSession();
  const accessToken = useMemo(
    () => session.data?.user.accessToken ?? "",
    [session]
  );
  useSetAuthorizationHeader(accessToken);

  return <>{children}</>;
};

export default AxiosProvider;
