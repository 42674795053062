import { HTMLAttributes, ReactNode } from "react";
import TabsContext from "./TabsContext";
import classnames from "classnames";

interface TabsProps<T = any>
  extends Omit<HTMLAttributes<HTMLElement>, "onChange"> {
  value: T;
  onChange: (value: T) => void;
  children?: ReactNode;
}

const Tabs = ({
  value,
  onChange,
  children,
  className,
  ...props
}: TabsProps) => {
  return (
    <TabsContext.Provider value={{ value, onChange }}>
      <div
        className={classnames(
          "flex w-full rounded-[5px] bg-[#EBEDF5] text-iconColor",
          className
        )}
        {...props}
      >
        {children}
      </div>
    </TabsContext.Provider>
  );
};

export default Tabs;
