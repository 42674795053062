import { useCallback, useEffect, useRef } from "react";

interface useIntersectionObserverProps {
  root?: null;
  rootMargin?: string;
  threshold?: number;
  onIntersect: IntersectionObserverCallback;
}

/**
 * @see https://bit.ly/3QxuJmB
 * @param {Object} param 파라미터
 * @param {element|document} [param.root] 기준 요소 설정
 * @param {string} [param.rootMargin] 루트 범위 확장 옵션
 * @param {number} [param.threshold] 콜백 호출 노출 범위
 * @param {function} param.onIntersect 관찰 대상 등록 / 가시성 변화시 호출할 함수
 * @return {setObservationTarget} 관찰할 요소 ref 속성에 할당할 상태 변경 함수
 * */
const useIntersectionObserver = ({
  root,
  rootMargin = "0px",
  threshold = 0,
  onIntersect,
}: useIntersectionObserverProps) => {
  const observationTarget = useRef(new Set<HTMLElement>());

  useEffect(() => {
    const hasIOSupport = !!window.IntersectionObserver;
    if (!observationTarget || !hasIOSupport) return;

    const observer: IntersectionObserver = new IntersectionObserver(
      onIntersect,
      {
        root,
        rootMargin,
        threshold,
      }
    );

    observationTarget.current.forEach((target) => {
      observer.observe(target);
    });

    return () => observer.disconnect();
  }, [onIntersect, root, rootMargin, threshold]);

  const setObservationTarget = useCallback((node: HTMLElement | null) => {
    if (node) observationTarget.current.add(node);
  }, []);

  return { setObservationTarget };
};

export default useIntersectionObserver;
