import { Popper } from "@dentlink/ui";
import { DesktopSearch } from "src/components/Search";
import { useEffect, useRef, useState } from "react";
import SearchInput from "src/components/Search/SearchInput";
import { useSearchInput } from "src/lib/Search/hooks";
import classNames from "classnames";

const SearchMenu = ({ className }: { className?: string }) => {
  const [isSearchFocused, setSearchFocused] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSearchFocused) searchInputRef.current?.focus();
  }, [isSearchFocused]);

  const { isTyping, onClear, ...searchInputProps } = useSearchInput();
  const onFocusHandler = () => setSearchFocused(true);
  const onClearHandler = () => {
    onClear();
    setSearchFocused(false);
  };

  const onOutSideClick = (event: Event) => {
    if (!anchorRef.current?.contains(event.target as Node)) {
      setSearchFocused(false);
    }
  };

  return (
    <div className={classNames("inline-flex", className)}>
      <div ref={anchorRef} className="relative" id="desktop-search-anchor">
        <SearchInput
          {...searchInputProps}
          onFocus={onFocusHandler}
          onClear={onClearHandler}
          isFocused={isSearchFocused}
        />
      </div>
      <Popper
        open={isSearchFocused}
        onOutsideClick={onOutSideClick}
        anchorEl={anchorRef.current}
        containerClasses={classNames(
          "bg-white w-[378px] z-30 rounded-dropdown",
          { "px-1.5 py-4": searchInputProps.value.length > 0 }
        )}
        placement="bottom-start"
        modifiers={[
          { name: "offset", options: { offset: [0, 11] }, enabled: true },
        ]}
      >
        <DesktopSearch
          searchTerm={searchInputProps.value}
          isTyping={isTyping}
        />
      </Popper>
    </div>
  );
};

export default SearchMenu;
