import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useMemo,
} from "react";
import { Close, Next, Prev } from "./Buttons";
import DotGroup from "./DotGroup";
import useCarousel from "../../lib/Carousel/hooks/useCarousel";
import { CarouselOptions } from "../../lib/Carousel/types";
import {
  getCarouselChildren,
  injectDefaultValue,
} from "../../lib/Carousel/utils";
import classnames from "classnames";

interface CarouselProps {
  children: ReactNode[];
  className?: string;
  dotGroupClasses?: string;
  onClose?: () => void;
  options?: Partial<CarouselOptions<"partialChild">>;
}

export default function Carousel({
  onClose,
  children,
  options,
  className,
  dotGroupClasses,
}: CarouselProps) {
  const carouselOptions = useMemo(() => injectDefaultValue(options), [options]);
  const childrenCount = useMemo(() => Children.count(children), [children]);
  const carouselChildren = useMemo(
    () => getCarouselChildren(children),
    [children]
  );

  const {
    trackEventHandlers,
    arrowHandlers: { onPrev, onNext },
    dotGroupProps,
    carouselItemProps,
    shouldRenderArrows,
    shouldRenderDots,
  } = useCarousel({
    ...carouselOptions,
    childrenCount,
  });

  return (
    // carousel-container
    <div
      className={classnames(
        "relative flex w-full flex-col bg-[#000]",
        className
      )}
    >
      {onClose && <Close onClose={onClose} />}
      {/* carousel-wrapper */}
      <div className="relative flex w-full">
        {shouldRenderArrows && <Prev onClick={onPrev} />}

        {/* carousel-track */}
        <div className="h-full w-full overflow-hidden" {...trackEventHandlers}>
          {/* carousel-item */}
          <div className="no-scrollbar flex" {...carouselItemProps}>
            {Children.map(carouselChildren, (child, i) => {
              if (isValidElement(child)) {
                return cloneElement(child, {
                  key: i,
                  width: `${carouselOptions.itemWidthRatio}%`,
                  ...child.props,
                });
              }
            })}
          </div>
        </div>

        {shouldRenderArrows && <Next onClick={onNext} />}
      </div>
      {shouldRenderDots && (
        <DotGroup {...dotGroupProps} className={dotGroupClasses} />
      )}
    </div>
  );
}
