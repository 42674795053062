import Header from "./Header";

interface DepthHeaderProps {
  /**
   * 헤더 제목으로 쓸 문자열
   */
  title?: string;
  backgroundColor?: "white" | "background";
}

/**
 * 모바일에서 depth가 있는 페이지에서 사용할 헤더.
 */
const DepthHeader = ({
  title,
  backgroundColor = "background",
}: DepthHeaderProps) => {
  const showTitle = !!title;

  return (
    <Header backgroundColor={backgroundColor}>
      <div className="mx-auto flex h-full w-full  items-center px-1">
        <Header.BackLink />
        {showTitle && (
          <Header.Title title={title} className="flex-1 text-center" />
        )}
        {!showTitle && <Header.Logo variant="mono" />}
        <div className="h-8 w-8"></div>
      </div>
    </Header>
  );
};

export default DepthHeader;
