import * as React from "react";
import { SVGProps } from "react";
const SvgLogoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 164 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46.428 10C52.234 10 56 13.55 56 19s-3.766 9-9.572 9H39V13.194C39 11.43 40.476 10 42.295 10h4.133Zm-.477 13.987c3.472 0 5.462-1.823 5.462-4.987s-1.99-4.987-5.462-4.987h-2.389v9.974h2.389ZM62.129 24.425H70V28H58V13.195C58 11.431 59.369 10 61.055 10H70v3.522h-7.871v3.652H70v3.474h-7.871v3.783-.006ZM83.712 10H88v18h-3.634l-8.078-10.624V28H72V10h3.61l8.102 10.648V10ZM106 10v4.062h-5.317V28h-4.367V14.062H91V10h15ZM113.658 23.908H122V28h-13V10h4.658v13.914-.006ZM124 28V13.195c0-1.764 1.617-3.195 3.611-3.195H129v18h-5ZM141.98 10H146v18h-3.407l-7.573-10.624V28H131V10h3.385l7.595 10.648V10ZM158.613 28l-4.561-7.126h-1.466V28H148V13.195c0-1.764 1.484-3.195 3.312-3.195h1.274v6.942h1.466L158.453 10h5.332l-5.812 8.819L164 28h-5.387Z"
      fill="#fff"
    />
    <path
      d="m24.075 3.561-.029-.011a7.488 7.488 0 0 0-.387-.136l-.058-.018-.364-.106c-.029-.006-.064-.012-.092-.024a5.794 5.794 0 0 0-.457-.1 6.217 6.217 0 0 0-.324-.06l-.15-.017c-.098-.012-.202-.03-.3-.042l-.203-.017c-.087-.006-.168-.018-.254-.018-.15-.006-.3-.012-.457-.012h-.006C16.064 3 12.07 7.054 12 12.07v.066h4.497c2.486 0 4.497 2.062 4.503 4.597V25h6.133C28.717 25 30 23.688 30 22.069v-9.862c0-3.977-2.468-7.363-5.92-8.646h-.005Z"
      fill="#C2CAFA"
    />
    <path
      d="M9.003 24.86V12H2.868C1.284 12 0 13.312 0 14.93v9.866c0 3.976 2.463 7.36 5.921 8.643.012 0 .017.006.029.012.127.047.254.094.387.136l.058.017.364.107c.03.005.064.011.093.023.11.03.225.053.341.077a6.33 6.33 0 0 0 .44.083l.15.017c.098.012.202.03.3.042l.203.017c.087.006.168.018.254.018.15.006.301.012.457.012h.006c4.932 0 8.933-4.053 8.997-9.068v-.065H9.003v-.006Z"
      fill="#fff"
    />
  </svg>
);
export default SvgLogoWhite;
