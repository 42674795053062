import * as React from "react";
import { SVGProps } from "react";
const SvgCircleCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={9.5} cy={9.5} r={8.5} stroke="#E1E3E8" strokeWidth={2} />
    <path
      d="m5.7 9.217 2.714 2.814 4.885-5.066"
      stroke="#E1E3E8"
      strokeWidth={2}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCircleCheck;
