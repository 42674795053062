import { Typography } from "@dentlink/ui";
import { NotificationData } from "src/common/apis/NotificationAPI/types";
import { Avatar } from "src/common/components/Avatar";
import { useRouter } from "next/router";
import { parseOrderCreationTime } from "src/lib/Order/utils";
import { useMarkNotificationAsRead } from "../../lib/Notifications/hooks";

interface NotificationProps {
  data: NotificationData;
}

const Notification = ({ data }: NotificationProps) => {
  useMarkNotificationAsRead(data);

  const router = useRouter();

  return (
    <article
      className="relative flex cursor-pointer gap-4"
      onClick={() => {
        router.push(data.link);
      }}
    >
      <section className="pt-[6px]">
        <Avatar user={data.user} />
      </section>
      <section className="flex-grow">
        <Typography size="md" weight="bold" renderAs="h6">
          {data.user.company_name}
        </Typography>
        <Typography
          variant="body1"
          size="md"
          renderAs="p"
          className="[leading-26px] whitespace-pre-line break-all line-clamp-3"
        >
          {data.description}
        </Typography>
        <Typography variant="body2" color="grayText" className="mt-[4px]">
          <time>{parseOrderCreationTime(data.create_at)}</time>
        </Typography>
      </section>
      {!data.isRead && (
        <span className="absolute top-[8.5px] right-[0px] h-[7px] w-[7px] rounded-full bg-primary" />
      )}
    </article>
  );
};

export default Notification;
