import Head from "next/head";
import { ReactNode } from "react";
import classNames from "classnames";

interface Props {
  children: ReactNode;
  backgroundColor?: "white" | "background";
  className?: string;
}

const MobileLayout = ({
  children,
  backgroundColor = "background",
  className,
}: Props) => {
  return (
    <>
      <Head>
        <title>Portal | Dentlink International</title>
      </Head>
      <div
        className={classNames(
          "flex min-h-screen flex-col",
          {
            "bg-white": backgroundColor === "white",
            "bg-background": backgroundColor === "background",
          },
          className
        )}
      >
        {children}
      </div>
    </>
  );
};

export default MobileLayout;
