import { useEffect } from "react";

/**
 * @param fn - function to call when component unmounts
 */
const useUnmountEffect = (fn: () => any) => {
  useEffect(() => {
    return () => {
      fn();
    };
  }, []);
};

export default useUnmountEffect;
