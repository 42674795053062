import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

export type CheckoutPageState = {
  openBatchPaymentsSuccessUI: boolean;
  openBatchOrdersDeclineUI: boolean;
};

const initialState = {
  openBatchPaymentsSuccessUI: false,
  openBatchOrdersDeclineUI: false,
};

const checkoutPageSlice = createSlice({
  name: "checkoutPage",
  initialState,
  reducers: {
    setOpenBatchPaymentsSuccessDeclineUI: (
      state: Draft<CheckoutPageState>,
      {
        payload: { type, open },
      }: PayloadAction<{ type: "success" | "decline"; open: boolean }>
    ) => {
      switch (type) {
        case "success":
          state.openBatchPaymentsSuccessUI = open;
          break;
        case "decline":
          state.openBatchOrdersDeclineUI = open;
          break;
      }
    },
  },
});

export const { setOpenBatchPaymentsSuccessDeclineUI } =
  checkoutPageSlice.actions;
export default checkoutPageSlice;
