import { OrderAPI } from "src/common/apis/OrderAPI";
import { useIntersectionObserver } from "@dentlink/hooks";
import { useInfiniteQuery } from "@tanstack/react-query";
import QueryKeys from "../../../common/constants/QueryKeys";

export default function useSearchQuery({ debouncedTerm = "" }) {
  const useInfiniteQueryResult = useInfiniteQuery(
    [QueryKeys.ORDER_DATA, debouncedTerm],
    async ({ pageParam }) => {
      return (await OrderAPI.searchOrder(debouncedTerm, pageParam?.cursor))
        .data;
    },
    {
      getNextPageParam: (page) => {
        if (!page.cursor) return undefined;
        return { cursor: page.cursor };
      },
      enabled: !!debouncedTerm,
    }
  );

  const { setObservationTarget } = useIntersectionObserver({
    onIntersect: ([firstEntry]) => {
      if (firstEntry.isIntersecting) {
        useInfiniteQueryResult.fetchNextPage();
      }
    },
  });

  const flatData =
    useInfiniteQueryResult.data?.pages.flatMap((page) => page.orders) || [];

  return { flatData, setObservationTarget, ...useInfiniteQueryResult };
}
