import { RefObject, useEffect } from "react";

interface UseOnTransitionStartProps {
  ref: RefObject<HTMLElement>;
  callback: (event: TransitionEvent) => void;
}

export default function useOnTransitionStart({
  ref,
  callback,
}: UseOnTransitionStartProps) {
  useEffect(() => {
    const element = ref.current;

    element?.addEventListener("transitionstart", callback);
    return () => element?.removeEventListener("transitionstart", callback);
  }, []);
}
