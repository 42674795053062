import * as React from "react";
import { SVGProps } from "react";
const SvgUns = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.4 0H0v10.27h12.4V0Z" fill="currentColor" />
    <path d="M31 0H12.4v1.54H31V0Z" fill="#E63A3A" />
    <path d="M31 1.54H12.4v1.37H31V1.54Z" fill="#fff" />
    <path
      d="M31 2.91H12.4v1.54H31V2.91ZM31 5.82H12.4v1.54H31V5.82ZM31 8.73H12.4v1.54H31V8.73Z"
      fill="#E63A3A"
    />
    <path
      d="M31 4.45H12.4v1.37H31V4.45ZM31 7.36H12.4v1.37H31V7.36ZM31 10.27H0v1.37h31v-1.37Z"
      fill="#fff"
    />
    <path
      d="M31 11.64H0v1.54h31v-1.54ZM31 14.55H0v1.54h31v-1.54ZM31 17.46H0V19h31v-1.54Z"
      fill="#E63A3A"
    />
    <path
      d="M31 13.18H0v1.37h31v-1.37ZM31 16.09H0v1.37h31v-1.37Z"
      fill="#fff"
    />
  </svg>
);
export default SvgUns;
