import { components } from "react-select";
import { Children } from "react";

const ShadeColors: Record<string, string> = {
  Discussion: "#EBEDF5",
  A1: "#FEF3E3",
  A2: "#FFF1DD",
  A3: "#FFEBCF",
  "A3.5": "#FFE7C5",
  A4: "#FFE1B6",
  B1: "#F8EBDA",
  B2: "#F8EAD9",
  B3: "#F3E3D0",
  B4: "#EAD7C2",
  C1: "#E8D8C1",
  C2: "#E6CFB1",
  C3: "#E2C499",
  C4: "#DABC92",
  D2: "#E8D8C1",
  D3: "#EAD5B6",
  D4: "#E8CFAA",
};

const getColor = (key: keyof typeof ShadeColors) => {
  switch (key) {
    case "Need Discussion":
      return ShadeColors.Discussion;
    default:
      return ShadeColors[key];
  }
};

export default function CustomShadeOption({ children, ...props }: any) {
  return (
    <components.Option {...props}>
      {Children.map(children, (child) => {
        return (
          <div className="flex items-center gap-2.5">
            <div
              className="h-5 w-5 rounded-full"
              style={{
                backgroundColor: getColor(child?.props?.textToHighlight),
              }}
            />
            {child}
          </div>
        );
      })}
    </components.Option>
  );
}
