import { Calendar } from "@dentlink/icons";
import classNames from "classnames";
import { forwardRef, InputHTMLAttributes, ReactNode, Ref } from "react";
import { GapStyles } from "../../../constants/styles";

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputValue: string;
  label?: string;
  helperText?: ReactNode;
  gap?: keyof typeof GapStyles;
  inputRef?: Ref<HTMLInputElement>;
  hasError?: boolean;
}

const CustomInput = forwardRef<HTMLLabelElement, CustomInputProps>(
  (
    {
      value: _value,
      inputValue,
      className,
      required,
      label,
      helperText,
      gap = 8,
      inputRef,
      hasError = false,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <label
          className={classNames(
            "flex w-full flex-col border-b-2 border-lineColor pb-1",
            className,
            GapStyles[gap],
            {
              "focus-within:border-primary": !hasError,
              "border-error": hasError,
            }
          )}
          ref={ref}
        >
          {label && (
            <span className="text-sm text-blueGray">{`${label}${
              required ? "*" : ""
            }`}</span>
          )}
          <div className="flex w-full">
            <input
              value={inputValue}
              className="w-full cursor-pointer border-none text-md outline-none placeholder:text-grayText"
              readOnly
              ref={inputRef}
              {...rest}
            />
            <span className="flex h-[27px] w-[27px] items-center justify-center">
              <Calendar />
            </span>
          </div>
        </label>
        {!!helperText && <div>{helperText}</div>}
      </>
    );
  }
);

export default CustomInput;
