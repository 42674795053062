import DesktopLandingPageLayout from "./DesktopLandingPageLayout";
import PageBackground from "./PageBackground";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";

export * from "./components";
export {
  DesktopLandingPageLayout,
  PageBackground,
  DesktopLayout,
  MobileLayout,
};
