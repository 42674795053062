import { useEffect } from "react";
import useIsMobile from "./useIsMobile";

const useChannelTalkBtnOnDesktopWidth = () => {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_DEPLOY_ENV !== "production") return;
    if (window.ChannelIO !== null) {
      const command = isMobile ? "hideChannelButton" : "showChannelButton";
      window.ChannelIO(command);
    }
  }, [isMobile]);
};

export default useChannelTalkBtnOnDesktopWidth;
