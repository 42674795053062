import * as React from "react";
import { SVGProps } from "react";
const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={7} cy={7} r={6.1} stroke="#E63A3A" strokeWidth={1.8} />
    <path
      d="m7.705 4.157-.002.002a.965.965 0 0 1-.696.277.965.965 0 0 1-.696-.277.913.913 0 0 1-.286-.679c0-.266.096-.494.286-.678a.965.965 0 0 1 .696-.277c.263 0 .492.093.682.277.19.184.286.412.286.678 0 .265-.09.492-.27.677Zm.057 1.74v4.393a.747.747 0 1 1-1.495 0V5.897a.747.747 0 1 1 1.495 0Z"
      fill="#E63A3A"
      stroke="#E63A3A"
      strokeWidth={0.2}
    />
  </svg>
);
export default SvgAlert;
