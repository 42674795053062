import type {
  FontColor,
  FontSize,
  FontWeight,
  ThemeColor,
} from "@dentlink/types";
import { ChipVariant } from "./type";
import { Cancel } from "@dentlink/icons";
import generateChipClasses from "./utils/generateChipStyle";

interface ChipProps {
  /**
   * Design Variant of the Chip;
   */
  variant?: ChipVariant;
  /**
   * label of the Chip;
   */
  label: string;
  /**
   * function to trigger when the chip is clicked;
   */
  onClick?: () => void;
  /**
   * function to trigger when Delete Operation
   */
  onDelete?: () => void;
  fontSize?: FontSize;
  color?: FontColor;
  border?: ThemeColor;
  background?: ThemeColor;
  fontWeight?: FontWeight;
  className?: string;
  defaultStyle?: string;
  iconProps?: {
    width?: number;
    height?: number;
  };
}

const Chip = ({ onClick, onDelete, label, iconProps, ...props }: ChipProps) => {
  const isClickable = !!onClick;
  const isDeletable = !!onDelete;

  const chipClasses = generateChipClasses({
    ...props,
    isClickable,
  });

  return (
    <div onClick={onClick} className={chipClasses}>
      {label}
      {isDeletable && (
        <button onClick={onDelete}>
          <Cancel {...iconProps} />
        </button>
      )}
    </div>
  );
};

export default Chip;
