import * as React from "react";
import { SVGProps } from "react";
const SvgPaperclip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#Paperclip_svg__a)">
      <path
        d="M14.876 8.072 9.753 13.1a.887.887 0 0 0-.266.628.874.874 0 0 0 .264.629.908.908 0 0 0 .64.26.92.92 0 0 0 .641-.259l5.124-5.026c.51-.5.797-1.178.797-1.886 0-.707-.285-1.385-.794-1.886-.51-.5-1.201-.782-1.922-.782-.72 0-1.412.28-1.922.78L7.19 10.586a4.402 4.402 0 0 0-1.327 3.142c0 1.18.478 2.31 1.327 3.143a4.573 4.573 0 0 0 3.203 1.301 4.574 4.574 0 0 0 3.202-1.302l5.124-5.028L20 13.1l-5.124 5.028a6.348 6.348 0 0 1-2.058 1.378 6.447 6.447 0 0 1-4.887.027 6.35 6.35 0 0 1-2.074-1.356 6.216 6.216 0 0 1-1.381-2.034 6.12 6.12 0 0 1 .027-4.795 6.22 6.22 0 0 1 1.405-2.02l5.124-5.026A4.573 4.573 0 0 1 14.235 3c1.2 0 2.352.468 3.202 1.302a4.402 4.402 0 0 1 1.326 3.142 4.402 4.402 0 0 1-1.326 3.142l-5.122 5.029c-.51.5-1.201.78-1.922.78-.721 0-1.413-.28-1.922-.78a2.642 2.642 0 0 1-.796-1.887c0-.707.286-1.386.796-1.886l5.124-5.028 1.28 1.257Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="Paperclip_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPaperclip;
