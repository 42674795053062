import Links from "src/common/constants/Links";
import { Company } from "src/common/constants/User";
import useCompanyType from "src/common/hooks/useCompanyType";
import { useSession } from "next-auth/react";

const useNewCaseLink = (index: number) => {
  const company_type = useCompanyType();
  const session = useSession({
    required: true,
  });
  const orderButtons = session?.data?.user.data.order_buttons;

  if (orderButtons && company_type === Company.clinic) {
    const labUid = orderButtons[index].lab_uid;
    const button_name = orderButtons[index].button_name;
    return {
      href: Links.getCreateNewCaseLink(labUid),
      name: button_name,
    };
  } else if (company_type === Company.lab) {
    return {
      href: Links.NEW_ORDER,
      name: "New case",
    };
  } else {
    return {
      href: "",
      name: "",
    };
  }
};
export default useNewCaseLink;
