import { useRouter } from "next/router";

export default function useOrderId() {
  const router = useRouter();

  const orderId = Number(router.query.order_id);

  if (!orderId || isNaN(orderId)) {
    throw new Error("order_id is required");
  }

  return orderId;
}
