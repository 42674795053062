import { ChevronLeft, CircleCancel, MagnifierOutlined } from "@dentlink/icons";
import { InputBase } from "@dentlink/ui";
import { useRouter } from "next/router";
import { ChangeEvent } from "react";

interface SearchPageHeaderProps {
  value: string;
  onChange: (arg0: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

const SearchPageHeader = ({
  value,
  onChange,
  onClear,
}: SearchPageHeaderProps) => {
  const router = useRouter();

  const showMagnifier = !value;

  return (
    <header className="fixed inset-x-0 top-0 z-10 w-screen bg-white">
      <div
        id="topbar__display-area"
        className="mx-auto flex w-full max-w-[640px] items-center gap-3 py-[10px] pl-[8px] pr-4"
      >
        <button
          onClick={() => {
            router.back();
          }}
        >
          <ChevronLeft width={32} height={32} />
        </button>
        <InputBase
          fullWidth
          onChange={onChange}
          value={value}
          placeholder="Patient Name, Order Number..."
          endAdornment={
            showMagnifier ? (
              <button type="button">
                <MagnifierOutlined width={21} height={21} />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => onClear()}
                className="h-max w-max"
              >
                <CircleCancel className="text-lg text-black" />
              </button>
            )
          }
        />
      </div>
    </header>
  );
};

export default SearchPageHeader;
