import { useDebounce } from "@dentlink/hooks";
import useSearchQuery from "./useSearchQuery";

const useSearchOrders = ({
  searchTerm,
  isTyping,
}: {
  searchTerm: string;
  isTyping: boolean;
}) => {
  const _debouncedTerm = useDebounce(searchTerm);

  const useSearchQueryResults = useSearchQuery({
    debouncedTerm: _debouncedTerm,
  });
  const debouncedLoading = useDebounce(useSearchQueryResults.isLoading);

  const showLoading = searchTerm !== "" && (isTyping || debouncedLoading);

  const showPlaceholder =
    searchTerm !== "" &&
    !isTyping &&
    !debouncedLoading &&
    useSearchQueryResults.flatData.length === 0;

  const showResults = !isTyping && useSearchQueryResults.flatData.length > 0;

  return {
    ...useSearchQueryResults,
    showLoading,
    showPlaceholder,
    showResults,
  };
};

export default useSearchOrders;
