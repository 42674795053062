import * as React from "react";
import { SVGProps } from "react";
const SvgV1Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10ZM8.995 8.288a.5.5 0 0 1 .707 0L12 10.586l2.298-2.298a.5.5 0 0 1 .707 0l.707.707a.5.5 0 0 1 0 .707L13.414 12l2.298 2.298a.5.5 0 0 1 0 .707l-.707.707a.5.5 0 0 1-.707 0L12 13.414l-2.298 2.298a.5.5 0 0 1-.707 0l-.707-.707a.5.5 0 0 1 0-.707L10.586 12 8.288 9.702a.5.5 0 0 1 0-.707l.707-.707Z"
      fill="#D7D7D7"
    />
  </svg>
);
export default SvgV1Close;
