import { createSelector } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import { OrderFilterState } from "src/lib/OrderFilter/redux/orderFilterSlice";
import { RootState } from "src/redux/store";

export const orderFilterSelector = (state: RootState): OrderFilterState =>
  state.orderFilter;

export const orderFilterStatusSelector = createSelector(
  orderFilterSelector,
  (orderFilter) => orderFilter.status
);
export const orderFilterClientSelector = createSelector(
  orderFilterSelector,
  (orderFilter) => orderFilter.client
);
export const orderFilterDeliveryDateSelector = createSelector(
  orderFilterSelector,
  (orderFilter) => orderFilter.arrival_date
);
export const isOrderFilterEmpty = createSelector(
  orderFilterSelector,
  (orderFilter) =>
    isEmpty(orderFilter.status) &&
    isEmpty(orderFilter.client) &&
    isEmpty(orderFilter.arrival_date) &&
    orderFilter.arrival_date.every((date) => date === null)
);
