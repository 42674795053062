import { FontColor, FontSize, FontWeight } from "@dentlink/types";
import { createElement, forwardRef, HTMLAttributes } from "react";
import { TypographyRenderType, TypographyVariant } from "./types";
import getRenderTag from "./utils/getRenderTag";
import getTextStyles from "./utils/getTextStyles";
import getUtilityClasses from "./utils/getUtilityClasses";

export interface TypographyProps extends HTMLAttributes<HTMLParagraphElement> {
  /**
   * The variant of the typography.
   * This pre-defines renderAs, weight, size, color.
   * But can be overrided by individual props.
   */
  variant?: TypographyVariant;
  renderAs?: TypographyRenderType;
  weight?: FontWeight;
  size?: FontSize;
  color?: FontColor;
  /**
   * className gets concatenated after default utility classes.
   */
  className?: string;
  href?: string;
}

const Typography = forwardRef<HTMLParagraphElement, TypographyProps>(
  (
    { children, variant, renderAs, weight, size, color, className, ...props },
    ref
  ) => {
    const renderTag = getRenderTag(variant, renderAs);
    const textStyles = getTextStyles({ variant, weight, size, color });
    const classes = getUtilityClasses({ ...textStyles, className });

    const TypographyComponent = createElement(
      renderTag,
      { ...props, className: classes, ref },
      children
    );
    return TypographyComponent;
  }
);

export default Typography;
