import * as React from "react";
import { SVGProps } from "react";
const SvgPrev = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      r={30.5}
      transform="matrix(1 0 0 -1 31.5 31.5)"
      stroke="#fff"
      strokeWidth={2}
    />
    <path d="m35 39-7.5-7.5L35 24" stroke="#fff" strokeWidth={2} />
  </svg>
);
export default SvgPrev;
