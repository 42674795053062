import { OrderData } from "src/common/apis/OrderAPI/types";
import QueryKeys from "src/common/constants/QueryKeys";
import { useQuery } from "@tanstack/react-query";
import { OrderAPI } from "src/common/apis/OrderAPI";

/**
 * @returns 주문 관련 정보
 */
const useOrder = (orderId: number | undefined) => {
  const { data, isLoading } = useQuery<OrderData>(
    [QueryKeys.ORDER_DATA, orderId?.toString()],
    async () => {
      return (await OrderAPI.getOrderInfo(orderId?.toString() as string)).data;
    },
    { enabled: !!orderId }
  );

  return { data, status: data?.order_status, isLoading };
};

export default useOrder;
