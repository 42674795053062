import Links from "./Links";
import { Company } from "../apis/UserAPI/types";

const listLabels = {
  Clinic: "Lab List",
  Lab: "Clinic List",
} as const;

const Menus = {
  common: [
    { label: "Order List", link: Links.ORDER_LIST },
    {
      label: (companyType: "Clinic" | "Lab") => listLabels[companyType],
      link: Links.CLIENT_LIST,
    },
    {
      label: "Transaction History",
      link: Links.PAYMENT_HISTORY,
    },
    {
      label: "Reviews",
      link: Links.REVIEW_LIST,
    },
    {
      label: "Data Extraction",
      link: Links.DATA_EXTRACTION,
      _companyType: "lab",
    },
  ],
  settings: [
    // TODO: 비밀번호 찾기 디자인 완성 시 추가
    // { label: "Password Assistance", link: Links.PASSWORD_ASSISTANCE },
    {
      label: "Payments",
      link: Links.PAYMENT_METHOD_LIST,
      _companyType: "clinic",
    },
  ],
  helps: [
    {
      label: "Get Help",
      link: Links.CHANNEL_TALK_LINK,
      target: "_blank",
    },
    {
      label: "Terms & Conditions",
      link: Links.TERMS_AND_CONDITIONS,
    },
    {
      label: "Privacy Policy",
      link: Links.PRIVACY_POLICY,
    },
    {
      label: "About",
      link: Links.ABOUT,
      target: "_blank",
    },
  ],
} as const;

type ConditionalLabel = (type: Company) => string;
type TMenuCategory = keyof typeof Menus;
type TMenuEntry = {
  label: string | ConditionalLabel;
  link: string;
  /**
   * _companyType 프로퍼티가 없으면 모든 계정에서 노출합니다
   * _companyType 프로퍼티가 있으면 특정 계정에서만 노출합니다
   * */
  _companyType?: "clinic" | "lab";
  target?: "_blank" | "_self" | "_parent" | "_top";
};

type TMenu = { [key in TMenuCategory]: Array<TMenuEntry> };

export { Menus as default };
export type { TMenu, TMenuEntry };
