import { HTMLAttributes, useCallback, useState } from "react";
import classnames from "classnames";
import { useIntersectionObserver } from "@dentlink/hooks";

type Targets = "front" | "rear";

export default function useObserverTargets() {
  const [isIntersectingFront, setIsIntersectingFront] = useState(false);
  const [isIntersectingRear, setIsIntersectingRear] = useState(false);

  const { setObservationTarget } = useIntersectionObserver({
    onIntersect: ([{ target, isIntersecting }]) => {
      const { id } = target;
      const setters = {
        front: setIsIntersectingFront,
        rear: setIsIntersectingRear,
      };
      setters[id as Targets](isIntersecting);
    },
  });

  const FrontTarget = useCallback(
    ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
      return (
        <div
          {...props}
          className={classnames("pl-px", className)}
          ref={setObservationTarget}
          id="front"
        />
      );
    },
    [setObservationTarget]
  );

  const RearTarget = useCallback(
    ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
      return (
        <div
          {...props}
          className={classnames("pr-px", className)}
          ref={setObservationTarget}
          id="rear"
        />
      );
    },
    [setObservationTarget]
  );

  return {
    FrontTarget,
    RearTarget,
    isIntersectingFront,
    isIntersectingRear,
  };
}
