import * as React from "react";
import { SVGProps } from "react";
const SvgReceiptOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.5 9.333h7M5.5 5.333h7"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M1 14.667V2a.667.667 0 0 1 .667-.667h14.666A.667.667 0 0 1 17 2v12.667l-2.667-1.334-2.666 1.334L9 13.333l-2.667 1.334-2.666-1.334L1 14.667Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgReceiptOutlined;
