import * as React from "react";
import { SVGProps } from "react";
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.333 20.195a4.306 4.306 0 1 0 0-8.611 4.306 4.306 0 0 0 0 8.611Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.558 11.646c.216.31.405.637.566.978l2.323 1.292c.29 1.3.293 2.646.009 3.947l-2.332 1.291a6.61 6.61 0 0 1-.566.978l.045 2.664a9.33 9.33 0 0 1-3.417 1.983l-2.288-1.373a7.895 7.895 0 0 1-1.13 0L13.49 24.77a9.204 9.204 0 0 1-3.427-1.974l.045-2.655a7.188 7.188 0 0 1-.565-.987L7.22 17.863a9.15 9.15 0 0 1-.01-3.947l2.333-1.292a6.6 6.6 0 0 1 .565-.978l-.045-2.664A9.329 9.329 0 0 1 13.481 7l2.287 1.372a7.921 7.921 0 0 1 1.13 0l2.279-1.363a9.203 9.203 0 0 1 3.426 1.973l-.045 2.664Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSettings;
