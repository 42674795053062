import * as React from "react";
import { SVGProps } from "react";
const SvgSearchEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 77 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.75} width={77} height={77} rx={12} fill="#EBEDF5" />
    <path
      fill="#fff"
      stroke="#B2B6C2"
      strokeWidth={2}
      d="M22 18.75h33v38H22z"
    />
    <path
      d="M27 27.75h15.135M27 34.372h22.703M27 41.236h15"
      stroke="#B2B6C2"
      strokeWidth={1.892}
    />
  </svg>
);
export default SvgSearchEmpty;
