import { ISODateString } from "@dentlink/types";
import { format, minutesToHours } from "date-fns";

type ParseOptions = {
  showGMTOffset?: boolean;
};

const getHours = (offset: number) => {
  const hours = minutesToHours(Math.abs(offset));
  const isOneDigit = hours < 10;
  return `${isOneDigit ? "0" : ""}${hours}`;
};

const getMinutes = (offset: number) => {
  const minutes = Math.abs(offset) % 60;
  const isOneDigit = minutes < 10;
  return `${isOneDigit ? "0" : ""}${minutes}`;
};

// TODO : add i18n suppport
/**
 *
 * @param creationTime ISO format string of order creation time.
 * @returns formatted creation time.
 */
const parseOrderCreationTime = (
  creationTime: ISODateString,
  options?: ParseOptions
): string => {
  const orderCreationTime = new Date(creationTime);

  const parsedTime = format(orderCreationTime, "MMMM dd, yyyy HH:mm aa");
  const timezoneOffset = new Date().getTimezoneOffset();
  const isMinus = timezoneOffset < 0;

  const GMTOffset = `(GMT ${isMinus ? "+" : "-"}${getHours(
    timezoneOffset
  )}:${getMinutes(timezoneOffset)})`;

  const withOffset = [parsedTime, GMTOffset].filter(Boolean).join(" ");

  return options?.showGMTOffset ? withOffset : parsedTime;
};

export default parseOrderCreationTime;
