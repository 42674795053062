import * as React from "react";
import { SVGProps } from "react";
const SvgNext = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={31.5}
      cy={31.5}
      r={30.5}
      transform="rotate(-180 31.5 31.5)"
      stroke="#fff"
      strokeWidth={2}
    />
    <path d="m28 39 7.5-7.5L28 24" stroke="#fff" strokeWidth={2} />
  </svg>
);
export default SvgNext;
