import * as React from "react";
import { SVGProps } from "react";
const SvgCirclePlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 21.5c5.799 0 10.5-4.701 10.5-10.5S16.799.5 11 .5.5 5.201.5 11 5.201 21.5 11 21.5Z"
      fill="#B2B6C2"
    />
    <path
      d="M11.001 4.92v12.162M17.081 11H4.92"
      stroke="#fff"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgCirclePlus;
