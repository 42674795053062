import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

const setCustom100vh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

const useSetCustomVh = () => {
  useIsomorphicLayoutEffect(() => {
    setCustom100vh();
    window.addEventListener("resize", setCustom100vh);
    return () => {
      window.removeEventListener("resize", setCustom100vh);
    };
  }, []);
};

export default useSetCustomVh;
