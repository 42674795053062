import { useMemo } from "react";
import throttle from "lodash/throttle";

interface useThrottleProps {
  callback: (...args: unknown[]) => void;
  delay: number;
  options?: {
    leading?: boolean;
    trailing?: boolean;
  };
}

export default function useThrottle({
  callback,
  delay,
  options,
}: useThrottleProps) {
  return useMemo(() => throttle(callback, delay, options), []);
}
