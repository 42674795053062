import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Step =
  | "sign-up"
  | "patient-details"
  | "case-details"
  | "impression-details"
  | "delivery-info";

type ButtonClickMap = { [key in Step]: number };
const defaultNextButtonClickMap: ButtonClickMap = {
  "sign-up": 0,
  "patient-details": 0,
  "case-details": 0,
  "impression-details": 0,
  "delivery-info": 0,
};

const initialState = {
  showAccountCreatedToast: false,
  viewMode: false,
  shouldMoveToCompletePage: false,
  nextButtonClickMap: defaultNextButtonClickMap,
};

export type SamplingState = typeof initialState;

const samplingSlice = createSlice({
  name: "sampling",
  initialState,
  reducers: {
    setShowAccountCreatedToast: (
      state: SamplingState,
      action: PayloadAction<boolean>
    ) => {
      state.showAccountCreatedToast = action.payload;
    },
    setViewMode: (state: SamplingState, action: PayloadAction<boolean>) => {
      state.viewMode = action.payload;
    },
    setShouldMoveToCompletePage: (
      state: SamplingState,
      action: PayloadAction<boolean>
    ) => {
      state.shouldMoveToCompletePage = action.payload;
    },
    setNextButtonClickMap: (
      state: SamplingState,
      { payload: type }: PayloadAction<Step>
    ) => {
      const currentValue = state.nextButtonClickMap[type];
      state.nextButtonClickMap[type] = currentValue + 1;
    },
    resetNextButtonClickField: (
      state: SamplingState,
      { payload: type }: PayloadAction<Step>
    ) => {
      state.nextButtonClickMap[type] = 0;
    },
    resetNextButtonClickMap: (state: SamplingState) => {
      state.nextButtonClickMap = defaultNextButtonClickMap;
    },
  },
});

export const {
  setShowAccountCreatedToast,
  setViewMode,
  setShouldMoveToCompletePage,
  setNextButtonClickMap,
  resetNextButtonClickField,
  resetNextButtonClickMap,
} = samplingSlice.actions;
export default samplingSlice;
