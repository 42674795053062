import { Chip, Divider, Paper, Typography } from "@dentlink/ui";
import { CompactOrderData } from "src/common/apis/OrderAPI/types";
import OrderStatus from "src/components/Order/Show/OrderStatus";
import { useRouter } from "next/router";
import { format } from "date-fns";
import {
  checkShowPaymentPendingLabel,
  getAdditionalOrderStatusChips,
  parseCaseData,
  parseOrderCompanyInfo,
  parseOrderCreationTime,
  parsePatientAge,
  parsePatientGender,
} from "src/lib/Order/utils";
import { nonNullable } from "@dentlink/utils";
import useIsMobile from "src/common/hooks/useIsMobile";
import useCompanyType from "src/common/hooks/useCompanyType";
import { PAYMENT_PENDING_LABEL } from "src/lib/Order/types/Label";
import { Pending } from "@dentlink/icons";
import classNames from "classnames";
import ToothChip from "./ToothChip";
import { FadeScrollWrapper } from "../FadeScroll";
import Links from "src/common/constants/Links";

interface OrderListItemProps {
  data: CompactOrderData;
  /**
   * should replace history, not push on clicking the item
   */
  shouldReplaceOnClick?: boolean;
  shadow?: boolean;
  className?: string;
}

/**
 * Displays Order Information for OrderList Page.
 * @param {OrderListItemProps} props
 * @returns
 */
const OrderListItem = ({
  data,
  shouldReplaceOnClick,
  shadow = true,
  className,
}: OrderListItemProps) => {
  const cases = parseCaseData(data.cases);
  const options = data.order_options;
  const others = data.order_others;
  const router = useRouter();
  const isMobile = useIsMobile();
  const companyType = useCompanyType();
  const shouldRenderPaymentPendingLabel = checkShowPaymentPendingLabel(
    data.payment_status,
    data.create_company_type,
    data.order_status
  );

  return (
    <Paper
      onClick={async () => {
        if (shouldReplaceOnClick) {
          await router.replace(
            Links.getOrderPageLink(data.order_id.toString())
          );
        } else {
          await router.push(Links.getOrderPageLink(data.order_id.toString()));
        }
      }}
      padded
      flex="column"
      className={classNames(
        "cursor-pointer border border-lineColor pb-2.5",
        !!shadow && "shadow-paper",
        className
      )}
    >
      <div className="flex w-full gap-2">
        <div className="flex gap-2">
          <OrderStatus status={data.order_status} />
          {getAdditionalOrderStatusChips({
            isRemaked: data.is_remaked,
            isReordered: data.is_reordered,
          })}
        </div>
        {shouldRenderPaymentPendingLabel && (
          <Typography
            className="flex items-center gap-1"
            size="xs"
            color="error"
            weight="semibold"
          >
            <Pending width={18} height={18} />
            {PAYMENT_PENDING_LABEL}
          </Typography>
        )}
      </div>
      <div className="mt-2.5 flex w-full justify-between">
        <Typography size="base" weight="bold">
          {data.patient_name}
          <Typography size="base">
            <span className="font-light">&nbsp;❘&nbsp;</span>
            {[
              parsePatientGender(data.patient_gender),
              parsePatientAge(data.patient_birthdate),
            ]
              .filter(nonNullable)
              .join(", ")}
          </Typography>
        </Typography>
        <Typography color="blueGray" weight="light" variant="body2">
          {data.order_uid}
        </Typography>
      </div>
      <div className="mt-1.5 flex flex-col gap-0.5">
        <Typography size="base" weight="bold" color="blueGray">
          {parseOrderCompanyInfo(data, companyType)}
        </Typography>
        <Typography size="xs" color="grayText">
          {parseOrderCreationTime(data.create_at, { showGMTOffset: true })}
        </Typography>
      </div>
      <Divider className="mt-4" />
      <FadeScrollWrapper>
        {({ FrontTarget, RearTarget }) => (
          <div
            className={classNames("mt-3.5 flex w-full justify-start gap-2", {
              "flex-wrap overflow-hidden": !isMobile,
              "no-scrollbar overflow-y-hidden overflow-x-scroll": isMobile,
            })}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <FrontTarget className="-mr-2" />
            {cases.map(({ id, ...props }) => (
              <ToothChip key={id} system={data.numbering_system} {...props} />
            ))}
            {options.map(({ option_id, option_name }) => (
              <Chip
                label={option_name}
                key={option_id}
                variant="outlined"
                color="grayText"
                border="lineColor"
                className="whitespace-nowrap"
              />
            ))}
            {others.map(({ other_name, other_id }) => (
              <Chip
                label={other_name}
                key={other_id}
                variant="outlined"
                color="grayText"
                border="lineColor"
                className="whitespace-nowrap"
              />
            ))}
            <RearTarget className="-ml-2" />
          </div>
        )}
      </FadeScrollWrapper>
      <div className="mt-2.5 flex w-full justify-start gap-4">
        <Typography variant="body2" weight="bold">
          Arrival Date
        </Typography>
        <Typography variant="body2">
          {format(new Date(data.arrival_date), "MMMM dd, yyyy")}
        </Typography>
      </div>
    </Paper>
  );
};

export default OrderListItem;
