import { useToggle } from "@dentlink/hooks";
import { BellOutlined } from "@dentlink/icons";
import { Badge, Popper } from "@dentlink/ui";
import classNames from "classnames";
import NotificationList from "src/components/Notifications/NotificationList";
import { useRef } from "react";
import { useNotifications } from "src/lib/Notifications/hooks";

const NotificationMenu = () => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const [isOpen, toggle, setOpen] = useToggle();

  const { unReadCount } = useNotifications();

  return (
    <>
      <Badge content={unReadCount} layout="desktop">
        <button
          onClick={toggle}
          type="button"
          className={classNames({ "text-primary": isOpen })}
          ref={btnRef}
        >
          <BellOutlined width={28} height={28} />
        </button>
      </Badge>
      <Popper
        open={isOpen}
        onOutsideClick={(event: Event) => {
          if (!btnRef.current?.contains(event.target as Node)) {
            setOpen(false);
          }
        }}
        anchorEl={btnRef.current}
        containerClasses="bg-white w-[378px] z-30 rounded-[5px] py-4 px-1.5"
        placement="bottom-start"
        modifiers={[
          { name: "offset", options: { offset: [42, 8] }, enabled: true },
        ]}
      >
        <NotificationList className="common-scroll max-h-[calc(100vh-7rem)] overflow-auto px-1.5" />
      </Popper>
    </>
  );
};

export default NotificationMenu;
