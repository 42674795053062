import * as React from "react";
import { SVGProps } from "react";
const SvgChevronTop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m9 5.297-4-4-4 4" stroke="#999" />
  </svg>
);
export default SvgChevronTop;
