import { useState } from "react";

/**
 *
 * @param initialValue
 * @returns [value, toggle, setValue]
 */
export default function useToggle(
  initialValue = false
): [boolean, () => void, (value: boolean) => void] {
  const [state, setState] = useState(initialValue);

  const toggle = () => setState(!state);

  return [state, toggle, setState];
}
