import { FontSize } from "@dentlink/types";
import classnames from "classnames";
import { getListStyleClass, getTextSizeClass } from "./utils";
import { ListStyle } from "./type";

interface NoticeList {
  entries: Array<string | number>;
  className?: string;
  fontSize?: FontSize;
  listStyle?: ListStyle;
}

export default function NoticeList({
  entries,
  className,
  fontSize = "sm",
  listStyle = "disc",
}: NoticeList) {
  const textSizeClass = getTextSizeClass(fontSize);
  const listStyleClass = getListStyleClass(listStyle);

  return (
    <ul
      className={classnames(
        "list-outside pl-3 text-iconColor",
        className,
        listStyleClass
      )}
    >
      {entries.map((entry, i, { length }) => (
        <li
          key={i}
          className={classnames("text-[1px] leading-[21px]", {
            "mb-2": length - 1 !== i,
          })}
        >
          <span
            className={classnames(
              "whitespace-pre-line leading-[21px]",
              textSizeClass
            )}
          >
            {entry}
          </span>
        </li>
      ))}
    </ul>
  );
}
