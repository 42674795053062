import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

type OrderManagementSlice = {
  isAddTrackingUIOpen: boolean;
  isSuccessUIOpen: boolean;
  selectedUserId: number | undefined;
};
type DraftState = Draft<OrderManagementSlice>;
const initialState = {
  isAddTrackingUIOpen: false,
  isSuccessUIOpen: false,
  selectedUserId: undefined,
};

const orderManagementSlice = createSlice({
  name: "orderManagement",
  initialState,
  reducers: {
    setIsAddTrackingUIOpen(state: DraftState, action: PayloadAction<boolean>) {
      state.isAddTrackingUIOpen = action.payload;
    },
    setIsSuccessUIOpen(state: DraftState, action: PayloadAction<boolean>) {
      state.isSuccessUIOpen = action.payload;
    },
    setSelectedUserId(
      state: DraftState,
      action: PayloadAction<number | undefined>
    ) {
      state.selectedUserId = action.payload;
    },
  },
});

export const { setIsAddTrackingUIOpen, setIsSuccessUIOpen, setSelectedUserId } =
  orderManagementSlice.actions;
export default orderManagementSlice;
