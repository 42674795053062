import { Chip } from "@dentlink/ui";
import { ToothNumberingSystem } from "src/common/types/Order";
import { parseFormulaToString } from "src/lib/Order/utils";

interface ToothChipProps {
  name: string;
  formula: string[];
  system: ToothNumberingSystem;
}

const ToothChip = ({ name, formula, system }: ToothChipProps) => {
  const label = `${parseFormulaToString(
    formula,
    undefined,
    system,
    3
  )} ${name}`;

  return (
    <Chip
      label={label}
      variant="outlined"
      color="grayText"
      border="lineColor"
      className="whitespace-nowrap"
    />
  );
};

export default ToothChip;
