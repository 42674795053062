import { OrderData } from "src/common/apis/OrderAPI/types";
import { Company } from "src/common/apis/UserAPI/types";
import { OrderDetailMap } from "../constants/OrderDetail";
import { Details } from "../types/OrderDetail";

interface getAvailableCardsProps {
  data: OrderData | undefined;
  companyType: Company | undefined;
  rate?: number | null;
}

/**
 * 주문 데이터 와 유저 타입에 따라 유저에게 보여줘야 할 카드에 대한 정보를 반환합니다.
 * @param {getAvailableCardsProps}
 * @returns {ReturnType<typeof getCardsRenderability>} returns each card's rendability
 */
const getCardsRenderability = ({
  data,
  companyType,
  rate,
}: getAvailableCardsProps) => {
  if (!data || !companyType) {
    return {
      shouldRenderOrderForm: false,
      shouldRenderOrderInfo: false,
      shouldRenderCaseInfo: false,
      shouldRenderShippingInfo: false,
      shouldRenderPayment: false,
      shouldRenderAdditionalInfo: false,
      shouldRenderOrderOptions: false,
      shouldRenderReviewInfo: false,
    };
  }

  let availableCards: Details[] | null = [];
  availableCards =
    // @ts-expect-error ts(2538) Type 'Omit<Company, "Admin">' cannot be used as an index type. - TS에서 Omit 으로 되어있는 타입을 index로 사용할수가 없다네요..!
    OrderDetailMap[companyType][data.create_company_type][data.order_status];

  return {
    shouldRenderOrderForm: availableCards?.includes("OrderForm"),
    shouldRenderOrderInfo: availableCards?.includes("OrderInfo"),
    shouldRenderCaseInfo:
      data.cases.length > 0 && availableCards?.includes("CaseInfo"),
    shouldRenderShippingInfo:
      data.shipping && availableCards?.includes("ShippingInfo"),
    shouldRenderPayment: data.payment_status === "completed",
    shouldRenderAdditionalInfo: availableCards?.includes("AdditionalInfo"),
    shouldRenderOrderOptions:
      data.order_options.length > 0 && availableCards?.includes("OrderOptions"),
    shouldRenderOthers:
      data.order_others.length > 0 && availableCards?.includes("Others"),
    shouldRenderEditButton:
      companyType === "Lab" &&
      data.payment_status !== "completed" &&
      (data.order_status === "processing" || data.order_status === "shipping"),
    shouldRenderReviewInfo: availableCards?.includes("ReviewInfo") && rate,
  };
};

export default getCardsRenderability;
