import * as React from "react";
import { SVGProps } from "react";
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.601 0v1.6h4.801V0H12v1.6h3.2a.8.8 0 0 1 .8.8v12.8a.8.8 0 0 1-.8.8H.8a.8.8 0 0 1-.8-.8V2.4a.8.8 0 0 1 .8-.8H4V0h1.601Zm8.801 7.2H1.6v7.2h12.802V7.2ZM4 3.2H1.6v2.4h12.802V3.2H12V4h-1.6v-.8H5.601V4H4v-.8Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCalendar;
