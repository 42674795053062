interface MobileBackgroundProps {
  children?: React.ReactNode;
  color?: "white" | "background";
  className?: string;
}

export default function PageBackground({
  children,
  color = "background",
  className,
}: MobileBackgroundProps) {
  const bgColorStyle = color === "white" ? "bg-white" : "bg-background";
  const defaultStyle = "relative min-h-screen flex-shrink-0 min-w-[320px]";

  const classes = [defaultStyle, bgColorStyle, className]
    .filter(Boolean)
    .join(" ");

  return <main className={classes}>{children}</main>;
}
