import { ValueOf } from "@dentlink/types";

export const OrderFormMode = {
  ClinicNeworder: "clinic_new",
  ClinicEdit: "clinic_edit",
  ClinicReorder: "clinic_reorder",
  ClinicRemake: "clinic_remake",
  LabNeworder: "lab_new",
  LabReorder: "lab_reorder",
  LabRemake: "lab_remake",
  LabEdit: "lab_edit",
} as const;

export type OrderFormModeValue = ValueOf<typeof OrderFormMode>;

export const OrderType = {
  Neworder: "neworder",
  Reorder: "reorder",
  Remake: "remake",
} as const;

export const OrderFormAction = {
  checkout: "save&checkout",
  submit: "submit",
  save: "save",
  delete: "delete",
  back: "back",
  relaySubmit: "relaySubmit",
} as const;

export type OrderFormActionValue = ValueOf<typeof OrderFormAction>;
