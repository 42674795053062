import { FontColor, FontSize, FontWeight, ThemeColor } from "@dentlink/types";
import {
  getBackgroundClasses,
  getBorderClasses,
  getFontClasses,
} from "@dentlink/utils";
import { ChipVariant } from "../type";

interface ChipStyleProps {
  variant?: ChipVariant;
  color?: FontColor;
  border?: ThemeColor;
  background?: ThemeColor;
  className?: string;
  fontSize?: FontSize;
  isClickable?: boolean;
  fontWeight?: FontWeight;
  defaultStyle?: string;
}

export default function generateChipClasses({
  variant,
  color,
  border,
  background,
  fontSize,
  isClickable,
  fontWeight,
  className,
  defaultStyle = "h-[22px] px-2 py-px pb-px w-fit flex-center flex-shrink-0 gap-2",
}: ChipStyleProps) {
  const generalStyles = [defaultStyle, isClickable && "cursor-pointer"];

  switch (variant) {
    case "outlined": {
      const backgroundClasses = getBackgroundClasses({
        color: background ?? "white",
      });
      const fontClasses = getFontClasses({
        color: color ?? "black",
        size: fontSize ?? "xs",
        weight: fontWeight ?? "semibold",
      });
      const borderClasses = getBorderClasses({
        color: border ?? color ?? "lineColor",
        width: 1,
      });
      return [
        ...generalStyles,
        backgroundClasses,
        fontClasses,
        borderClasses,
        className,
      ]
        .filter(Boolean)
        .join(" ");
    }
    case "filled": {
      const fontClasses = getFontClasses({
        color: "white",
        size: fontSize ?? "xs",
        weight: fontWeight ?? "semibold",
      });
      const backgroundClasses = getBackgroundClasses({
        color: background ?? color,
      });
      return [...generalStyles, fontClasses, backgroundClasses, className]
        .filter(Boolean)
        .join(" ");
    }
    default: {
      const fontClasses = getFontClasses({
        color: color ?? "black",
        size: fontSize ?? "xs",
        weight: fontWeight ?? "semibold",
      });
      const backgroundClasses = getBackgroundClasses({
        color: background ?? "white",
      });
      const borderClasses = getBorderClasses({
        color: border,
        width: border && 1,
      });
      return [
        ...generalStyles,
        backgroundClasses,
        fontClasses,
        borderClasses,
        className,
      ]
        .filter(Boolean)
        .join(" ");
    }
  }
}
