import { ISODateString } from "@dentlink/types";
import { differenceInYears } from "date-fns";

/**
 * calculates the age of a patient
 * @param birthdate ISO format string of patient's date of birth.
 * @returns full age of patient.
 */
const parsePatientAge = (birthdate: ISODateString | "") => {
  if (!birthdate) return undefined;
  const today = new Date();
  const birthinDate = new Date(birthdate);

  return differenceInYears(today, birthinDate);
};

export default parsePatientAge;
