import { Button } from "@dentlink/ui";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useNewCaseLink } from "src/lib/Menus/hooks";
import useCompanyType from "src/common/hooks/useCompanyType";

interface NewCaseButtonProps {
  className?: string;
  size?: "small" | "medium" | "large";
}

export default function NewCaseButton({
  className,
  size = "large",
}: NewCaseButtonProps) {
  const router = useRouter();
  const newCaseLink = useNewCaseLink(0);
  const oralDesignLink = useNewCaseLink(1);
  const onClickNewCaseLink = async () => await router.push(newCaseLink.href);
  const onClickOralDesignLink = async () =>
    await router.push(oralDesignLink.href);
  const company_type = useCompanyType();

  return (
    <div
      className={classNames(
        `sticky flex w-full flex-col justify-center`,
        className
      )}
    >
      {/* <Button
        onClick={onClickNewCaseLink}
        className="max-w-[608px]"
        fullWidth
        size={size}
        variant="contained"
        color="primary"
        elevation
      >
        {newCaseLink.name}
      </Button>
      {company_type === "Clinic" && (
        <Button
          onClick={onClickOralDesignLink}
          className="mt-[8px] max-w-[608px] border border-primary bg-white text-primary"
          fullWidth
          size={size}
          variant="contained"
          elevation
        >
          {oralDesignLink.name}
        </Button>
      )} */}
    </div>
  );
}
