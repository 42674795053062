import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  showWriteReviewDesktopModal: false,
  showWriteReviewSuccessDesktopModal: false,
  showWriteReviewSuccessMobileModal: false,
  shouldFocusToReviewCard: false,
};

export type ReviewState = typeof initialState;

const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    setShowWriteReviewDesktopModal(
      state: ReviewState,
      action: PayloadAction<boolean>
    ) {
      state.showWriteReviewDesktopModal = action.payload;
    },
    setShowWriteReviewSuccessDesktopModal(
      state: ReviewState,
      action: PayloadAction<boolean>
    ) {
      state.showWriteReviewSuccessDesktopModal = action.payload;
    },
    setShowWriteReviewSuccessMobileModal(
      state: ReviewState,
      action: PayloadAction<boolean>
    ) {
      state.showWriteReviewSuccessMobileModal = action.payload;
    },
    setShouldFocusToReviewCard(
      state: ReviewState,
      action: PayloadAction<boolean>
    ) {
      state.shouldFocusToReviewCard = action.payload;
    },
  },
});

export const {
  setShowWriteReviewDesktopModal,
  setShowWriteReviewSuccessDesktopModal,
  setShowWriteReviewSuccessMobileModal,
  setShouldFocusToReviewCard,
} = reviewSlice.actions;
export default reviewSlice;
