import { NotificationAPI } from "src/common/apis/NotificationAPI";
import { NotificationData } from "src/common/apis/NotificationAPI/types";
import { useEffect } from "react";

const useMarkNotificationAsRead = (data: NotificationData) => {
  useEffect(() => {
    if (!data.isRead) {
      NotificationAPI.readNotification(data.notification_id);
    }
  }, [data.isRead, data.notification_id]);
};

export default useMarkNotificationAsRead;
