import { Typography } from "@dentlink/ui";
import classNames from "classnames";
import { useRouter } from "next/router";

interface PageTitleProps {
  title: string;
  subTitle?: string;
  className?: string;
}

const PageTitle = ({ title, subTitle, className }: PageTitleProps) => {
  const router = useRouter();

  return (
    <section
      className={classNames(className, "my-3 flex flex-row items-center gap-3")}
    >
      <Typography
        variant="title"
        renderAs="h2"
        size="lg"
        onClick={() => router.reload()}
        className="cursor-pointer"
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography variant="title" renderAs="h2" size="lg" color="primary">
          {subTitle}
        </Typography>
      )}
    </section>
  );
};

export default PageTitle;
