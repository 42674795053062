import type { ThemeColor } from "@dentlink/types";
import { borderColorConfig, BorderWidthConfig } from "./configs";
import { UtilityClassGenerator } from "./type";

interface IBorderClasses {
  color?: ThemeColor | "inherit";
  width?: number;
}

const getBorderClasses: UtilityClassGenerator<IBorderClasses> = ({
  color,
  width,
}) => {
  return [color && borderColorConfig[color], width && BorderWidthConfig[width]]
    .filter(Boolean)
    .join(" ");
};

export default getBorderClasses;
