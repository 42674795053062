import type { ReactNode } from "react";

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: ReactNode) => JSX.Element;
  children: JSX.Element;
}) => {
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
