import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import Links from "src/common/constants/Links";

interface NonAuthComponentProps {
  children: ReactNode;
  shouldRedirect?: boolean;
}

const NonAuth = ({
  children,
  shouldRedirect = true,
}: NonAuthComponentProps) => {
  const { status, data } = useSession();
  const router = useRouter();

  if (
    shouldRedirect &&
    status === "authenticated" &&
    data &&
    data.type !== "new"
  ) {
    router.replace(Links.AUTH_DEFAULT_REDIRECT);
  }

  return <>{children}</>;
};

export default NonAuth;
