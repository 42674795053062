import { OrderStatusProps } from "../../../lib/Order/types/OrderStatus";
import { getOrderStatusStyle } from "../../../lib/Order/utils/getOrderStatusStyler";
import { capitalizeFirstLetter } from "@dentlink/utils";
import classNames from "classnames";

const sizeStyle = "w-[79px] h-[21px]";
const textStyle = "text-xs font-semibold";
const containerStyle = "flex items-center justify-center flex-shrink-0";

export const UnstyledOrderStatusChip = ({
  label,
  className,
}: {
  label: string;
  className?: string;
}) => {
  return (
    <div
      className={classNames(sizeStyle, textStyle, containerStyle, className)}
    >
      <span className="text-inherit">{label}</span>
    </div>
  );
};

const OrderStatus = ({ status }: OrderStatusProps) => {
  const orderStatusStyle = getOrderStatusStyle(status);

  return (
    <UnstyledOrderStatusChip
      label={capitalizeFirstLetter(status)}
      className={orderStatusStyle}
    />
  );
};

export default OrderStatus;
