import { CircleCheck, CircleCheckContained } from "@dentlink/icons";
import * as React from "react";
import { SVGProps } from "react";

type CheckboxIconProps = SVGProps<SVGSVGElement> & {
  variant: "outlined" | "contained";
  checked: boolean | undefined;
};
export default function CheckboxIcon({
  variant,
  checked,
  ...props
}: CheckboxIconProps) {
  if (!checked) {
    return <CircleCheck {...props} className="text-grayText" />;
  }

  return variant === "contained" ? (
    <CircleCheckContained {...props} className="text-primary" />
  ) : (
    <CircleCheck {...props} />
  );
}
