import { CircleCancel, MagnifierOutlined } from "@dentlink/icons";
import { forwardRef, InputHTMLAttributes } from "react";

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onClear: () => void;
  isFocused: boolean;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onClear, isFocused, ...inputProps }, ref) => {
    return (
      <div className="relative flex w-[17rem] items-center gap-2 rounded-[5px] bg-[#F0F2F5] px-4">
        {!isFocused && (
          <MagnifierOutlined className="flex-shrink-0 text-md text-grayText" />
        )}
        <input
          type="text"
          className="w-full bg-transparent py-1 text-md caret-primary/30 placeholder:text-grayText focus:outline-none"
          placeholder="Search Patients..."
          ref={ref}
          {...inputProps}
        />
        {isFocused && (
          <button type="button" onClick={onClear} className="h-max w-max">
            <CircleCancel className="flex-shrink-0 text-md text-grayText" />
          </button>
        )}
      </div>
    );
  }
);

SearchInput.displayName = "SearchInput";

export default SearchInput;
