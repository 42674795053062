import * as React from "react";
import { SVGProps } from "react";
const SvgLogoBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 136 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.501 7.896c4.815 0 7.938 2.944 7.938 7.463 0 4.52-3.123 7.464-7.938 7.464h-6.16V10.544c0-1.462 1.224-2.648 2.733-2.648H38.5Zm-.396 11.598c2.88 0 4.53-1.51 4.53-4.135 0-2.624-1.65-4.135-4.53-4.135h-1.98v8.27h1.98ZM51.522 19.858h6.527v2.965h-9.951V10.545c0-1.462 1.135-2.65 2.533-2.65h7.418v2.921h-6.527v3.029h6.527v2.88h-6.527v3.138-.005ZM69.42 7.896h3.555v14.927h-3.013l-6.7-8.81v8.81h-3.555V7.896h2.994l6.718 8.83v-8.83ZM87.902 7.896v3.368h-4.408v11.559h-3.622V11.264h-4.409V7.896h12.44ZM94.253 19.43h6.918v3.393h-10.78V7.896h3.862v11.538-.005ZM102.829 22.823V10.545c0-1.462 1.341-2.65 2.994-2.65h1.152v14.928h-4.146ZM117.739 7.896h3.334v14.927h-2.826l-6.28-8.81v8.81h-3.333V7.896h2.806l6.299 8.83v-8.83ZM131.533 22.823l-3.783-5.91h-1.215v5.91h-3.804V10.545c0-1.462 1.231-2.65 2.747-2.65h1.057v5.758h1.215l3.65-5.757h4.421l-4.819 7.313L136 22.823h-4.467Z"
      fill="#1E1E32"
    />
    <path
      d="M19.965 2.556s-.015-.004-.024-.01a6.223 6.223 0 0 0-.321-.112l-.048-.015-.302-.088c-.024-.005-.053-.01-.077-.02-.091-.024-.187-.044-.283-.063-.033-.005-.067-.01-.096-.02a5.152 5.152 0 0 0-.268-.049l-.125-.015c-.081-.01-.168-.024-.25-.034l-.167-.015c-.072-.004-.139-.014-.21-.014-.125-.005-.25-.01-.38-.01h-.004c-4.089 0-7.401 3.362-7.459 7.522v.054h3.73c2.06 0 3.729 1.71 3.734 3.812v6.856H22.5c1.314 0 2.378-1.088 2.378-2.43v-8.18c0-3.297-2.047-6.105-4.909-7.169h-.004Z"
      fill="#3750FF"
    />
    <path
      d="M7.466 20.22V9.553H2.378C1.064 9.554 0 10.642 0 11.984v8.182c0 3.297 2.043 6.104 4.91 7.167.01 0 .014.005.024.01.106.039.211.078.321.112l.048.015.302.088c.024.005.053.01.077.02.091.024.187.044.283.063a5.151 5.151 0 0 0 .364.069l.125.015c.082.01.168.024.25.034l.167.015c.072.005.14.014.211.014.125.005.25.01.379.01h.005c4.09 0 7.408-3.36 7.46-7.52v-.054h-7.46v-.005Z"
      fill="#1E1E32"
    />
  </svg>
);
export default SvgLogoBlack;
