import type { Props as ReactSelectProps } from "react-select";
import ReactSelect, { SelectInstance } from "react-select";
import {
  ClearIndicator,
  CustomShadeOption,
  DropdownIndicator,
  MenuList,
} from "./components";
import getStyles from "./utils/getStyles";
import Typography from "../Typography";
import { MutableRefObject, ReactNode, RefCallback } from "react";
import formatOptionLabel from "./utils/formatOptionLabel";
import { GapStyles } from "../../constants/styles";
import classNames from "classnames";

/**
 * This Select Component is a wrapper around the React-Select Library.
 * so refer to react-select official documentation for more information.
 */
interface SelectProps<Option extends { label: string }, isMulti extends boolean>
  extends Omit<ReactSelectProps<Option, isMulti>, "styles"> {
  name: string;
  isMobile?: boolean;
  className?: string;
  label?: string;
  required?: boolean;
  helperText?: ReactNode;
  selectRef?:
    | RefCallback<SelectInstance<Option, isMulti>>
    | MutableRefObject<SelectInstance<Option, isMulti>>;
  gap?: keyof typeof GapStyles;
  customOption?: boolean;
  hasError?: boolean;
}

const Select = <
  Option extends { label: string },
  isMulti extends boolean = false
>({
  options,
  className,
  label,
  required,
  helperText,
  selectRef,
  isSearchable = false,
  gap = 8,
  customOption = false,
  hasError = false,
  ...props
}: SelectProps<Option, isMulti>) => {
  const styles = getStyles<Option>(hasError);
  const customComponents: ReactSelectProps<Option, isMulti>["components"] = {
    DropdownIndicator,
    ClearIndicator,
    MenuList,
  };

  if (customOption) customComponents.Option = CustomShadeOption;

  return (
    <>
      <label
        className={classNames(
          `flex w-full flex-col`,
          className,
          GapStyles[gap]
        )}
      >
        {label && (
          <Typography variant="body2" color="blueGray">
            {`${label}${required ? "*" : ""}`}
          </Typography>
        )}
        <ReactSelect
          theme={(theme) => ({
            ...theme,
            colors: { ...theme.colors, neutral50: "#999999" },
          })}
          options={options}
          styles={styles}
          components={customComponents}
          isSearchable={isSearchable}
          formatOptionLabel={formatOptionLabel}
          ref={(selectInstance) => {
            if (selectRef && selectInstance) {
              if (typeof selectRef === "function") {
                selectRef(selectInstance);
              } else {
                selectRef.current = selectInstance;
              }
            }
          }}
          {...props}
        />
      </label>
      {!!helperText && <div>{helperText}</div>}
    </>
  );
};

export default Select;
