import { ListEmpty } from "@dentlink/icons";
import React from "react";

interface OrderListPlaceholderProps {
  text?: string;
  className?: string;
}

export default function OrderListPlaceholder({
  text,
  className,
}: OrderListPlaceholderProps) {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-4 ${className}`}
    >
      <ListEmpty width={77} height={77} />
      <span className="text-iconColor">{text}</span>
    </div>
  );
}
