import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import QueryKeys from "../constants/QueryKeys";
import { AxiosError } from "axios";
import { Client } from "../apis/ClientAPI/type";
import { ClientAPI } from "../apis/ClientAPI";

type QueryOption<T> = UseQueryOptions<Client, AxiosError, T>;

export default function useClientProfileQuery<T = Client>(
  userId: number,
  options: QueryOption<T> = {}
) {
  const { getClientProfile } = ClientAPI;

  return useQuery<Client, AxiosError, T>(
    [QueryKeys.CLIENT_PROFILE, userId.toString()],
    async () => await getClientProfile(userId),
    options
  );
}
