const QueryKeys = {
  /**
   * @see https://dev.dentlink.io:4000/api/v1/docs/#/Users%20API/UsersController_getUserIdAndCompanyName
   */
  COMPACT_USER_INFO: "compact_user_info",

  MATERIAL_OPTIONS: "material_options",

  /**
   * @see https://dev.dentlink.io:4000/api/v1/docs/#/Orders%20API/OrdersController_getOrder
   */
  ORDER_DATA: "order_data",

  ORDER_LIST: "order_list",
  ORDER_LIST_TOTAL: "order_list_total",

  ORDER_STATISTICS: "order_statistics",

  UNPAID_ORDERS: "unpaid_orders",

  /**
   * 기공물 카테고리 조회를 위한 API.
   * @see https://dev.dentlink.io:4000/api/v1/docs/#/Categories%20API
   */
  PROSTHESIS_CATEGORY: "prosthesis_categories",

  ORDER_OPTIONS: "order_options",

  INVOICE_DATA: "invoice_data",

  INVOICE_RESULT: "invoice_result",

  PAYMENT_CARD_LIST: "payment_card_list",

  COMMENT_DATA: "comment_data",

  NOTIFICATIONS: "notifications",

  /**
   * 거래처 조회 API
   * @see https://dev.dentlink.io:4000/api/v1/docs/#/Clients%20API
   * */
  CLIENT_LIST: "client_list",
  CLIENT_PROFILE: "client_profile",

  /** History API */
  HISTORY_TRANSACTIONS: "history_transaction",
  HISTORY_SETTLEMENT: "history_settlement",

  /** User API */
  USER_PROFILE: "user_profile",
  USER_SESSION: "user_session",

  /** Invoices API */
  INVOICES_BATCH_CHECKOUT_LIST: "invoices_batch_checkout_list", // 일괄 결제 항목 조회
  INVOICES_ORDER_PAYMENTS: "invoices_order_payments", // 선택한 주문(들) 결제

  USER_PREFERENCE: "user_preference",

  /** Order Management API */
  PROCESSING_ORDERS: "processing_orders", // 프로세싱 단계의 주문 목록 조회

  AUTO_PAYMENTS_INFO: "auto_payments_info", // 자동 결제 정보 조회

  DASHBOARD_HERO_IMAGE: "dashboard_hero_image", // 대시보드 히어로 이미지 조회
  DASHBOARD_BANNER_IMAGES: "dashboard_banner_images", // 대시보드 배너 이미지 조회

  REVIEW_ORDER_SUMMARY: "review_order_summary",
  REVIEW_DATA: "review_data",

  /** Sampling API */
  SAMPLING_DATA: "sampling_data",
  PROSTHESIS_DATA: "prosthesis_data",
  USER_ID: "user_id",
  METHOD_DATA: "method_data",
  MATERIAL_CROWN_DATA: "material_crown_data",
  MATERIAL_ABUTMENT_DATA: "material_abutment_data",
  CASE_PREFERENCE_DATA: "case_preference_data",
};

export default QueryKeys;
