import { useEffect } from "react";

const useTriggerFunctionByCondition = (
  condition: boolean,
  fn: () => void | Promise<void>
) => {
  useEffect(() => {
    if (condition) {
      fn();
    }
  }, [condition, fn]);
};

export default useTriggerFunctionByCondition;
