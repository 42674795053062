import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type OrderDetailPageState = {
  [key: number]: {
    /**
     * 해당 order의 detail page 접속 시 checkout page로 자동으로 리다이렉션할 것인지 여부.
     */
    shouldRedirectToCheckoutPage: boolean;
    /**
     * 해당 order의 detail page에서 cancel order 사유를 입력하는 시트를 열지 여부.
     */
    isCancelOrderUIOpen: boolean;
    /**
     * 해당 order의 detail page에서 decline order 사유를 입력하는 시트를 열지 여부.
     */
    isDeclineOrderUIOpen: boolean;
    /**
     * 해당 order의 detail page에서 tracking number를 입력하는 시트를 열지 여부.
     */
    isAddTrackingNumberUIOpen: boolean;
    /**
     * 해당 order를 삭제하는 UI를 열지 여부.
     */
    deleteUIState: {
      open: boolean;
      withComment: boolean;
    };
  };
};

type SetShouldRedirectToCheckoutAction = {
  orderId: number;
  value: boolean;
};

const initialState: OrderDetailPageState = {};

const orderDetailPageSlice = createSlice({
  name: "orderPage",
  initialState,
  reducers: {
    setShouldRedirectToCheckoutPage: (
      state: OrderDetailPageState,
      action: PayloadAction<SetShouldRedirectToCheckoutAction>
    ) => {
      const { orderId, value } = action.payload;
      state[orderId] = {
        ...state[orderId],
        shouldRedirectToCheckoutPage: value,
      };
    },
    setIsCancelOrderUIOpen: (
      state: OrderDetailPageState,
      action: PayloadAction<{ orderId: number; value: boolean }>
    ) => {
      const { orderId, value } = action.payload;
      state[orderId] = { ...state[orderId], isCancelOrderUIOpen: value };
    },
    setIsDeclineOrderUIOpen: (
      state: OrderDetailPageState,
      action: PayloadAction<{ orderId: number; value: boolean }>
    ) => {
      const { orderId, value } = action.payload;
      state[orderId] = { ...state[orderId], isDeclineOrderUIOpen: value };
    },
    setIsAddTrackingNumberUIOpen: (
      state: OrderDetailPageState,
      action: PayloadAction<{ orderId: number; value: boolean }>
    ) => {
      const { orderId, value } = action.payload;
      state[orderId] = { ...state[orderId], isAddTrackingNumberUIOpen: value };
    },
    setDeleteUIOpen: (
      state: OrderDetailPageState,
      action: PayloadAction<{
        orderId: number;
        value: boolean;
        withComment?: boolean;
      }>
    ) => {
      const { orderId, value, withComment } = action.payload;
      state[orderId] = {
        ...state[orderId],
        deleteUIState: {
          open: value,
          withComment: withComment || false,
        },
      };
    },
  },
});

export const {
  setShouldRedirectToCheckoutPage,
  setIsCancelOrderUIOpen,
  setIsDeclineOrderUIOpen,
  setIsAddTrackingNumberUIOpen,
  setDeleteUIOpen,
} = orderDetailPageSlice.actions;

export default orderDetailPageSlice;
