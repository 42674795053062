import { NextComponentType } from "next";
import Head from "next/head";
import { DesktopHeader, Footer } from "./components";

interface Props {
  children: React.ReactNode;
}

const DesktopLayout: NextComponentType<never, never, Props> = ({
  children,
}) => {
  return (
    <>
      <Head>
        <title>Portal | Dentlink International</title>
      </Head>
      <div className="flex min-h-screen flex-col">
        <DesktopHeader />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default DesktopLayout;
