import React, { InputHTMLAttributes, useCallback, useId } from "react";
import classnames from "classnames";
import TextLabel from "./TextLabel";

interface ToggleProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "id"> {
  color?: TColor;
  size?: TSize;
  className?: string;
  label?: string;
  labelClasses?: string;
  labelDirection?: "left" | "right";
}

type TColor = keyof typeof colorMap;
const colorMap = {
  primary: "peer-checked:bg-primary", // blue
  error: "peer-checked:bg-error", // red
  text: "peer-checked:bg-text", // black
};

type TSize = keyof typeof sizeMap;
const sizeMap = {
  base: "w-[52px] h-7 after:w-6 after:h-6 after:top-0.5 after:left-0.5",
};

const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  (
    {
      label,
      labelClasses,
      labelDirection = "right",
      size = "base",
      color = "primary",
      className,
      ...toggleProps
    },
    ref
  ) => {
    const id = useId();

    const ConditionalTextLabel = useCallback(() => {
      if (!label) return null;
      return <TextLabel label={label} className={labelClasses} />;
    }, [label, labelClasses]);

    const isShowLabelLeft = labelDirection === "left";
    const isShowLabelRight = labelDirection === "right";

    return (
      <div
        className={classnames(
          { "flex items-center gap-3": label && !className },
          className
        )}
      >
        {isShowLabelLeft && <ConditionalTextLabel />}
        <label
          htmlFor={id}
          className="justify-content relative flex cursor-pointer items-center"
        >
          <input
            ref={ref}
            id={id}
            type="checkbox"
            className="peer sr-only"
            {...toggleProps}
          />
          <div
            className={classnames(
              "rounded-full bg-lineColor after:absolute after:rounded-full after:bg-white after:shadow-sm after:transition peer-checked:after:translate-x-full",
              sizeMap[size],
              colorMap[color]
            )}
          />
        </label>
        {isShowLabelRight && <ConditionalTextLabel />}
      </div>
    );
  }
);

export default Toggle;
