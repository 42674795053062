import { ChangeEvent, useCallback, useEffect, useState } from "react";

/**
 *
 * @param delay Time to delay `isTyping` status update
 */
const useSearchInput = ({ delay = 500 } = {}) => {
  const [value, setValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTyping(false);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay, value, setIsTyping]);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setIsTyping(true);
  }, []);

  const onClear = useCallback(() => setValue(""), []);

  return {
    isTyping,
    onChange,
    onClear,
    value,
  };
};

export default useSearchInput;
