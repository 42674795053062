import classnames from "classnames";

interface Props {
  onClick: () => void;
  isActive: boolean;
  className?: string;
}

export default function Dot({ onClick, isActive, className }: Props) {
  return (
    <button type="button" onClick={onClick} className="h-fit w-fit p-0.5">
      <div
        className={classnames(
          "h-2.5 w-2.5 rounded-full transition-colors duration-[400ms]",
          { "bg-[#5C5C5C]": !isActive, "bg-white": isActive },
          className
        )}
      />
    </button>
  );
}
