import { LogoBlack } from "@dentlink/icons";
import { useSession } from "next-auth/react";
import Link from "next/link";
import Links from "src/common/constants/Links";
import DesktopMenu from "./DesktopMenu";
import NotificationMenu from "./NotificationMenu";
import SearchMenu from "./SearchMenu";

const Header = () => {
  const { status } = useSession();

  return (
    <header className="sticky top-0 z-20 w-full bg-white shadow-header">
      <nav className="container-xl mx-auto flex h-14 max-w-[calc(1194px+80px)] items-center justify-between px-10">
        <Link href={Links.AUTH_DEFAULT_REDIRECT} passHref legacyBehavior>
          <h1 className="cursor-pointer">
            <LogoBlack name="Dentlink" width={132} height={30} />
          </h1>
        </Link>
        {status === "authenticated" && (
          <section className="flex items-center gap-4 py-1.5 text-lg">
            <SearchMenu />
            <NotificationMenu />
            <DesktopMenu />
          </section>
        )}
      </nav>
    </header>
  );
};

export default Header;
