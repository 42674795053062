import Loading from "@dentlink/ui/src/Loading";
import classnames from "classnames";
import { loadingSelector } from "../../../redux/selectors/globalSelectors";
import { useSelector } from "react-redux";

interface BlurLayerProps {
  className?: string;
  loading?: boolean;
}

export default function BlurLayer({
  className,
  loading = true,
}: BlurLayerProps) {
  const isShow = useSelector(loadingSelector());

  const classes = classnames(
    "fixed backdrop-blur-sm z-20 inset-0 min-w-screen min-h-screen grid place-content-center",
    className
  );
  return isShow ? (
    <section className={classes}>{loading && <Loading />}</section>
  ) : null;
}
