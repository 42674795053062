import { configureStore } from "@reduxjs/toolkit";
import { createWrapper, MakeStore } from "next-redux-wrapper";
import invoicePageSlice from "../lib/Invoice/redux/invoicePageSlice";
import orderDetailPageSlice from "../lib/Order/redux/orderDetailPageSlice";
import globalSlice from "./slices/globalSlice";
import orderFormSlice from "src/lib/Order/redux/orderFormSlice";
import orderFilterSlice from "../lib/OrderFilter/redux/orderFilterSlice";
import signupPageSlice from "../lib/SignupForm/redux/signupPageSlice";
import commentUploaderSlice from "../lib/Comment/redux/commentUploaderSlice";
import checkoutPageSlice from "../lib/Checkout/redux/checkoutPageSlice";
import profileSlice from "../lib/Profile/redux/profileSlice";
import orderManagementSlice from "../lib/OrderManagement/redux/orderManagementSlice";
import reviewSlice from "../lib/Review/redux/reviewSlice";
import samplingSlice from "../lib/Sampling/redux/samplingSlice";

const store = configureStore({
  reducer: {
    commentUploader: commentUploaderSlice.reducer,
    profile: profileSlice.reducer,
    orderPage: orderDetailPageSlice.reducer,
    invoicePage: invoicePageSlice.reducer,
    signupPage: signupPageSlice.reducer,
    global: globalSlice.reducer,
    orderForm: orderFormSlice.reducer,
    orderFilter: orderFilterSlice.reducer,
    orderManagement: orderManagementSlice.reducer,
    checkoutPage: checkoutPageSlice.reducer,
    review: reviewSlice.reducer,
    sampling: samplingSlice.reducer,
  },
});

const makeStore: MakeStore<typeof store> = () => store;

export const wrapper = createWrapper(makeStore, {
  debug: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
