import { useToggle } from "@dentlink/hooks";
import { Hamburger } from "@dentlink/icons";
import { Popper } from "@dentlink/ui";
import classNames from "classnames";
import { useRef } from "react";
import { MenuList } from "src/components/Menus";
import { ProfileOnMenu } from "src/components/Profile/ProfileBar";

const DesktopMenu = () => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const [isOpen, toggle, setOpen] = useToggle();

  return (
    <>
      <button
        onClick={toggle}
        className={classNames("-ml-1", { "text-primary": isOpen })}
        type="button"
        ref={btnRef}
      >
        <Hamburger width={30} height={23} />
      </button>
      <Popper
        open={isOpen}
        onOutsideClick={(event: Event) => {
          if (!btnRef.current?.contains(event.target as Node)) {
            setOpen(false);
          }
        }}
        anchorEl={btnRef.current}
        containerClasses="bg-white w-[378px] z-30 rounded-dropdown"
        placement="bottom-end"
        modifiers={[
          { name: "offset", options: { offset: [0, 11] }, enabled: true },
        ]}
      >
        <ProfileOnMenu className="px-4 pt-4" />
        <MenuList />
      </Popper>
    </>
  );
};

export default DesktopMenu;
