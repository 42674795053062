import classnames from "classnames";
import React from "react";

interface TextLabelProps {
  label: string;
  className?: string;
}

export default function TextLabel({ label, className }: TextLabelProps) {
  return (
    <span
      className={classnames({
        "font-medium text-md text-black": !className,
        className,
      })}
    >
      {label}
    </span>
  );
}
