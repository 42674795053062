import { useTabsContext } from "../Tabs/TabsContext";
import classnames from "classnames";

interface TabProps<T> {
  value: T;
  label: string;
  disabled?: boolean;
  selectedColor?: string;
}

const Tab = <T,>({
  value,
  label,
  disabled = false,
  selectedColor,
}: TabProps<T>) => {
  const { value: selectedValue, onChange } = useTabsContext();

  const isSelected = value === selectedValue;
  const backgroundColor = () => {
    if (isSelected) {
      if (selectedColor) return selectedColor;
      else return "bg-blueGray";
    }
  };

  const classes = classnames(
    "w-full text-sm rounded-[5px] h-[33px]",
    {
      "text-white font-bold": isSelected,
      "text-grayText cursor-not-allowed": disabled,
    },
    backgroundColor()
  );

  return (
    <button
      onClick={() => {
        onChange(value);
      }}
      className={classes}
      type="button"
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Tab;
