import { DrawerPlacements } from "./types";

const BorderRadiusStyle = {
  top: "rounded-t-[10px]",
  right: "rounded-r-[10px]",
  left: "rounded-l-[10px]",
  bottom: "rounded-b-[10px]",
};

const PlacementStyle = {
  top: "top-0 left-0 w-screen pb-[10px]",
  left: "top-0 left-0 h-screen pr-[10px]",
  right: "top-0 right-0 h-screen pl-[10px]",
  bottom: "bottom-0 left-0 w-screen pt-[10px]",
};

const OppositeDirection = {
  top: "bottom",
  right: "left",
  left: "right",
  bottom: "top",
} as const;

interface getStylesProps {
  placement: DrawerPlacements;
}

export default function getStyles({ placement }: getStylesProps) {
  const borderRadiusStyle = BorderRadiusStyle[OppositeDirection[placement]];
  const defaultStyles = "fixed z-50 bg-white flex flex-col justify-start";
  const placementStyle = PlacementStyle[placement];

  const styleClasses = [defaultStyles, placementStyle, borderRadiusStyle].join(
    " "
  );

  return styleClasses;
}
