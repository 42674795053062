import { CaretDown } from "@dentlink/icons";
import { components } from "react-select";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown className="fill-black text-sm" />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
