import { useState } from "react";
import { INITIAL_DIFF, SENSITIVITY_MAP } from "../constants";
import { SensitivityType } from "../types";

interface UseCarouselDiffProps {
  sensitivity: SensitivityType;
}

export default function useCarouselDiff({ sensitivity }: UseCarouselDiffProps) {
  const [diff, setDiff] = useState<number>(INITIAL_DIFF);

  const DIFF_LEVEL = SENSITIVITY_MAP[sensitivity];

  const shouldMoveToNext = diff > DIFF_LEVEL;
  const shouldMoveToPrev = diff < DIFF_LEVEL * -1;

  return {
    shouldMoveToNext,
    shouldMoveToPrev,
    setDiff,
    diff,
  };
}
