import isFunction from "lodash/isFunction";
import { useMemo } from "react";
import { NumOrNull } from "../types";
import { INITIAL_DIFF } from "../constants";
import { propertyValidator } from "../utils";

const _initialValue = {
  _swipeStartPos: null as NumOrNull, // 스와이프 시작 지점
  _isActiveTransition: true, // transition 효과 활성화 여부
  _isOnTransitionEvent: false, // 현재 transition 진행중인지 여부

  getDiff(clientX: number) {
    if (this._swipeStartPos) return this._swipeStartPos - clientX;
    return INITIAL_DIFF;
  },

  onTransitionStartAction() {
    this._isOnTransitionEvent = true;
  },
  onTransitionEndAction() {
    this._isOnTransitionEvent = false;
  },

  onDotClickAction() {
    this._isActiveTransition = true;
  },
  onSwipeStartAction(clientX: number) {
    this._swipeStartPos = clientX;
    this._isActiveTransition = false;
  },
  onSwipeEndAction() {
    this._swipeStartPos = null;
  },

  onMoveToIdxAction() {
    this._isActiveTransition = true;
  },
  onMoveToIdxPromptlyAction() {
    this._isActiveTransition = false;
  },

  get isSwipeable() {
    return !!this._swipeStartPos;
  },
  get isIdle() {
    return !this._isOnTransitionEvent;
  },
  get isActiveTransition() {
    return this._isActiveTransition;
  },
};

type CarouselModelProps = keyof typeof _initialValue;

export default function useCarouselModel() {
  return useMemo(() => {
    return new Proxy(_initialValue, {
      get(target, prop: CarouselModelProps) {
        propertyValidator(target, prop);
        const value = Reflect.get(target, prop);
        return isFunction(value) ? value.bind(target) : value;
      },
      set(target, prop: CarouselModelProps, value) {
        propertyValidator(target, prop);
        return Reflect.set(target, prop, value);
      },
      deleteProperty(target, prop: CarouselModelProps) {
        propertyValidator(target, prop);
        return Reflect.deleteProperty(target, prop);
      },
      ownKeys(target) {
        return Object.keys(target).filter((prop) => !prop.startsWith("_"));
      },
    });
  }, []);
}
