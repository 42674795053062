import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client } from "src/common/apis/ClientAPI/type";
import type { OrderStatus } from "src/common/apis/OrderAPI/types";
import { ISODateString } from "@dentlink/types";

export type OrderFilterState = {
  status: Array<OrderStatus>;
  client: Array<Client>;
  arrival_date: Array<ISODateString | null>;
};

type SetStatusAction = {
  status: OrderStatus;
};

const initialState: OrderFilterState = {
  status: [],
  client: [],
  arrival_date: [],
};

const orderFilterSlice = createSlice({
  name: "orderFilter",
  initialState,
  reducers: {
    toggleStatus: (
      state: OrderFilterState,
      action: PayloadAction<SetStatusAction>
    ) => {
      const { status: target } = action.payload;
      const targetIndex = state.status.findIndex((status) => status === target);
      if (targetIndex >= 0) {
        state.status.splice(targetIndex, 1);
      } else {
        state.status.push(target);
      }
    },
    setDeliveryDate: (
      state: OrderFilterState,
      action: PayloadAction<{
        type: "start" | "end" | "today" | "tomorrow";
        value: ISODateString | null;
      }>
    ) => {
      const { type, value } = action.payload;
      switch (type) {
        case "start":
          state.arrival_date[0] = value;
          break;
        case "end":
          state.arrival_date[1] = value;
          break;
        default:
          // today | tomorrow
          state.arrival_date[0] = value;
          state.arrival_date[1] = value;
      }
    },
    clearDeliveryDate: (state: OrderFilterState) => {
      state.arrival_date = [];
    },
    toggleClient: (
      state: OrderFilterState,
      action: PayloadAction<{ client: Client }>
    ) => {
      const { client: target } = action.payload;
      const targetIndex = state.client.findIndex(
        (client) => client.user_id === target.user_id
      );
      if (targetIndex >= 0) {
        state.client.splice(targetIndex, 1);
      } else {
        state.client.push(target);
      }
    },
    clearAllFilters: (state: OrderFilterState) => {
      state.client = [];
      state.arrival_date = [];
      state.status = [];
    },
    clearClientFilters: (state: OrderFilterState) => {
      state.client = [];
    },
    clearStatusFilters: (state: OrderFilterState) => {
      state.status = [];
    },
  },
});

export const {
  clearAllFilters,
  clearClientFilters,
  clearStatusFilters,
  clearDeliveryDate,
  toggleClient,
  toggleStatus,
  setDeliveryDate,
} = orderFilterSlice.actions;

export default orderFilterSlice;
