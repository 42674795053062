import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { ProfileTabs } from "../types";

export type ProfileState = {
  isProfileSetupComplete: boolean;
  isSubmittingProfileForm: boolean;
  lastViewedProfileTab: ProfileTabs;
};

const initialState: ProfileState = {
  isProfileSetupComplete: false,
  isSubmittingProfileForm: false,
  lastViewedProfileTab: "profileTab",
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setIsProfileSetupComplete(
      state: Draft<ProfileState>,
      action: PayloadAction<boolean>
    ) {
      state.isProfileSetupComplete = action.payload;
    },
    setIsSubmittingProfileForm(
      state: Draft<ProfileState>,
      action: PayloadAction<boolean>
    ) {
      state.isSubmittingProfileForm = action.payload;
    },
    setLastViewedProfileTab(
      state: Draft<ProfileState>,
      action: PayloadAction<ProfileTabs>
    ) {
      state.lastViewedProfileTab = action.payload;
    },
  },
});

export const {
  setIsProfileSetupComplete,
  setIsSubmittingProfileForm,
  setLastViewedProfileTab,
} = profileSlice.actions;
export default profileSlice;
