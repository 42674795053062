import { FontColor, FontSize, FontWeight, ThemeColor } from "@dentlink/types";

export const fontColorConfig: Record<FontColor | "inherit", string> = {
  primary: "text-primary",
  primaryLight: "text-primaryLight",
  black: "text-black",
  text: "text-text",
  error: "text-error",
  grayText: "text-grayText",
  blueGray: "text-blueGray",
  inherit: "text-inherit",
  white: "text-white",
  iconColor: "text-iconColor",
};

export const fontSizeConfig: Record<FontSize | "inherit", string> = {
  xs: "text-xs",
  sm: "text-sm",
  md: "text-md",
  base: "text-base",
  xl: "text-xl",
  lg: "text-lg",
  inherit: "text-inherit",
};

export const fontWeightConfig: Record<FontWeight | "inherit", string> = {
  light: "font-light",
  regular: "font-regular",
  semibold: "font-semibold",
  bold: "font-bold",
  inherit: "font-inherit",
};

export const borderColorConfig: Record<ThemeColor | "inherit", string> = {
  background: "border-background",
  black: "border-black",
  error: "border-error",
  grayText: "border-grayText",
  iconColor: "border-iconColor",
  lineColor: "border-lineColor",
  primary: "border-primary",
  primaryLight: "border-primaryLight",
  blueGray: "border-blueGray",
  text: "border-text",
  transparent: "border-transparent",
  white: "border-white",
  inherit: "border-inherit",
};

export const BorderWidthConfig: Record<number, string> = {
  1: "border",
  2: "border-2",
  4: "border-4",
};

export const backgroundColorConfig: Record<ThemeColor | "inherit", string> = {
  background: "bg-background",
  black: "bg-black",
  error: "bg-error",
  grayText: "bg-grayText",
  iconColor: "bg-iconColor",
  lineColor: "bg-lineColor",
  primary: "bg-primary",
  blueGray: "bg-blueGray",
  text: "bg-text",
  transparent: "bg-transparent",
  white: "bg-white",
  inherit: "bg-inherit",
  primaryLight: "bg-primaryLight",
};
