import * as React from "react";
import { SVGProps } from "react";
const SvgExportOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 6H7a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-3"
      stroke="currentColor"
    />
    <path d="M9.5 13v-1a3 3 0 0 1 3-3h4" stroke="currentColor" />
    <path d="m14.5 6 2 3-2 3" stroke="currentColor" />
  </svg>
);
export default SvgExportOutlined;
