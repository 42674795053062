import { ToothNumberingSystem } from "src/common/types/Order";
import { MnEntries, MxEntries } from "src/common/constants/Teeth";
import take from "lodash/take";

/**
 * Mx, Mn의 선택 여부를 반환한다.
 * @param formula {string[]} array of selected dental formula
 * @param system {ToothNumberingSystem} numbering system of dental formula
 * @returns {object} batch selection status of formula
 */
const checkBatch = (
  formula: string[],
  system: ToothNumberingSystem
): { isMx: boolean; isMn: boolean } => {
  const isMx = MxEntries[system].every((entry) => formula.includes(entry));
  const isMn = MnEntries[system].every((entry) => formula.includes(entry));
  return { isMx, isMn };
};

/**
 * 치식의 배열을 치식 문자열로 변환한다.
 * @example
 * // returns "#13 #14 15(p)..."
 * parseFormulaToString(["#13", "#14", "p15", "i15"])
 *
 * // returns "MX"
 * parseForumlaToString(["#1", "#2", "#3", "#4", "#5", "#6", "#7", "#8", "#9", "#10", "#11", "#12", "#13", "#14", "#15", "#16"])
 *
 * @param formula  array of selected dental formula
 * @param separator seperator of dental formula entries
 * @param system numbering system of dental formula
 * @param limit max length of dental formula
 * @returns {string} parsed string of formula
 */
const parseFormulaToString = (
  formula: string[],
  separator = " ",
  system: ToothNumberingSystem,
  limit?: number
): string => {
  const { isMx, isMn } = checkBatch(formula, system);

  // replace Mx, Mn's entries with Mx, Mn.
  const parsedFormula = formula.filter((entry) => {
    if (isMx && MxEntries[system].includes(entry)) {
      return false;
    }
    if (isMn && MnEntries[system].includes(entry)) {
      return false;
    }
    return true;
  });

  if (isMx) {
    parsedFormula.unshift("MX");
  }
  if (isMn) {
    parsedFormula.push("MN");
  }

  const finalEntries = (
    limit ? take(parsedFormula, limit) : parsedFormula
  ).reduce((acc, curr) => {
    if (curr.startsWith("p")) {
      return acc.concat(`${curr.slice(1)}(P)`);
    }
    return acc.concat(curr);
  }, [] as string[]);

  const exceedsLimit = limit ? parsedFormula.length > limit : false;

  return `${finalEntries.join(separator)}${exceedsLimit ? "..." : ""}`;
};

export default parseFormulaToString;
