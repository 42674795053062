import {
  CarouselOptions,
  CoordinateKeys,
  IDX_GUIDE_TYPE,
  SubOptions,
  SubOptionsKey,
  SwipeEvent,
} from "./types";
import { Children, ReactNode } from "react";
import { DEFAULT_CAROUSEL_OPTIONS, IDX_OFFSET } from "./constants";

export const getCoordinates = (
  event: SwipeEvent
): { [key in CoordinateKeys]: number } => {
  const swipeEvent = "touches" in event ? event.touches[0] : event;
  const { clientX, clientY, pageX, pageY } = swipeEvent;

  return { clientX, clientY, pageX, pageY };
};

export const getCarouselChildren = (children: ReactNode[]) => {
  if (children.length === 1) return children;

  const childrenArray = Children.toArray(children);
  const firstChild = childrenArray.at(0);
  const lastChild = childrenArray.at(-1);

  return [lastChild, ...childrenArray, firstChild];
};

export const getInitialCarouselIdx = (
  childrenLength: number,
  startIndex: number
) => {
  if (childrenLength === 1) return 0;
  return startIndex > childrenLength - 1 ? IDX_OFFSET : startIndex + IDX_OFFSET;
};

export const getSwipeSizeCalcExpression = (
  idx: number,
  ratio: number,
  diff: number,
  displayCount = 1
) => {
  const swipeSize = `-${idx * (ratio / displayCount)}%`;
  const offsetSize = `${diff}px`;
  return `${swipeSize} - ${offsetSize}`;
};

export const factoryGetCurIdxFromDotIdx =
  ({ FRONT, LAST_ITEM }: IDX_GUIDE_TYPE) =>
  (dotIndex: number) => {
    switch (dotIndex) {
      case FRONT:
        return FRONT + IDX_OFFSET;
      case LAST_ITEM:
        return LAST_ITEM + IDX_OFFSET;
      default:
        return dotIndex + IDX_OFFSET;
    }
  };

/** 원본 Children 에 맞춰 교정한 인덱스를 반환하는 함수 */
export const factoryGetDotIdxFromCurIdx =
  ({ FIRST_ITEM, TAIL, FRONT, LAST_ITEM }: IDX_GUIDE_TYPE) =>
  (currentIndex: number) => {
    switch (currentIndex) {
      case FIRST_ITEM:
      case TAIL:
        return FRONT;
      case FRONT:
        return LAST_ITEM;
      default:
        return currentIndex - IDX_OFFSET;
    }
  };

export const injectDefaultValue = (
  options?: Partial<CarouselOptions<"partialChild">>
): CarouselOptions => {
  const subOptionsKey: SubOptionsKey[] = ["autoSlideOptions"];

  const subOptions = subOptionsKey.reduce((subOptions, key) => {
    subOptions[key] = {
      ...DEFAULT_CAROUSEL_OPTIONS[key],
      ...options?.[key],
    };
    return subOptions;
  }, {} as SubOptions);

  return {
    ...DEFAULT_CAROUSEL_OPTIONS,
    ...options,
    ...subOptions,
  };
};

export const propertyValidator = (
  target: Record<string, unknown>,
  prop: string,
  prefix = "_"
) => {
  if (!Reflect.has(target, prop)) throw new Error("Invalid Property");
  if (prop.startsWith(prefix)) throw new Error("Access Restricted");
};
