import { useProfileQuery } from "../../../common/queries";
import useCompanyType from "../../../common/hooks/useCompanyType";
import {
  TClinicProfile,
  TLabProfile,
} from "../../../common/apis/UserAPI/types";
import ProfileImageThumbnail, { ThumbnailProps } from "./ProfileImageThumbnail";
import SettingsButton from "./SettingsButton";
import classnames from "classnames";
import { NoBadgeProps, UserName } from "./UserName";

export default function ProfileOnMenu({ className }: { className?: string }) {
  const companyType = useCompanyType();
  const isClinic = companyType === "Clinic";

  type ProfileType = typeof companyType extends "Clinic"
    ? TClinicProfile
    : TLabProfile;

  const { data, isLoading } = useProfileQuery<ProfileType>();

  const thumbnailProps: ThumbnailProps = {
    isLoading,
    src: data?.profile_image?.file_url,
    fallbackInitial: data?.company_name.charAt(0),
    size: "small",
    type: companyType ?? "Clinic",
  };

  const userNameProps: NoBadgeProps = {
    isLoading,
    title: isClinic ? data?.company_name : data?.user_name,
    blueGray: isClinic ? undefined : data?.company_type,
    hasSubTitle: !isClinic,
  };

  return (
    <section className={classnames("flex items-center gap-[13px]", className)}>
      <ProfileImageThumbnail {...thumbnailProps} />
      <UserName {...userNameProps} />
      <SettingsButton className="ml-auto" />
    </section>
  );
}
