import * as React from "react";
import { SVGProps } from "react";
const SvgFilterOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.596 1h12.808a.603.603 0 0 1 .545.35.58.58 0 0 1-.105.634l-4.898 5.31a.568.568 0 0 0-.157.396v4.157a.573.573 0 0 1-.268.492l-2.386 1.564a.603.603 0 0 1-.838-.186.581.581 0 0 1-.086-.299V7.69a.568.568 0 0 0-.157-.396l-4.898-5.31A.584.584 0 0 1 1.596 1v0Z"
      stroke="#000"
      strokeWidth={1.515}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgFilterOutlined;
