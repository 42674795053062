import BigCheckIcon from "../../BottomSheet/asset/BigCheckIcon";
import { Button, Typography } from "../../index";
import { ComponentProps } from "react";
import InfoSuccess from "./InfoSuccess";

type InfoSuccessWithDescProps = ComponentProps<typeof InfoSuccess> & {
  description: string;
};

export default function InfoSuccessWithDesc({
  onClick,
  title,
  label,
  description,
}: InfoSuccessWithDescProps) {
  return (
    /** 바깥 Wrapper */
    <div className="relative w-[423px]">
      <div className="relative -bottom-10 mx-auto w-fit">
        <BigCheckIcon />
      </div>
      {/** 흰색 배경 콘텐츠 영역 */}
      <div className="rounded-dropdown bg-white px-10 py-8">
        <Typography
          renderAs="h2"
          size="xl"
          weight="bold"
          className="pt-6 pb-4 leading-[50px]"
        >
          {title}
        </Typography>
        <Typography size="base">{description}</Typography>
        <Button fullWidth onClick={onClick} className="mt-6">
          {label}
        </Button>
      </div>
    </div>
  );
}
