import * as React from "react";
import { SVGProps } from "react";
const SvgLogoInnovaid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 124 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.832 3.218 2.081 2.818-2.081 2.985-2.078-2.985 2.078-2.818ZM7.835 11.45l-1.83 2.619a.341.341 0 0 1-.28.141.348.348 0 0 1-.284-.142L.179 6.484a.953.953 0 0 1 0-1.113l.21-.293 1.535-2.149 5.91 8.522ZM15.491 6.486l-1.664 2.397L7.832.79h3.496c.551 0 1.074.259 1.362.67l1.053 1.471 1.748 2.44a.959.959 0 0 1 0 1.115Z"
      fill="currentColor"
    />
    <path
      d="m4.324 5.529 3.51-4.74H4.343c-.555 0-1.078.26-1.358.67l-.752 1.047L4.02 5.083l.309.446h-.004ZM7.834 11.451l1.829 2.618a.34.34 0 0 0 .28.142.348.348 0 0 0 .285-.142L13.51 9.34l-2.17-2.946-3.506 5.057ZM25.5 1.073h-2.242v12.879H25.5V1.073ZM41.447 14.34l-8.79-8.775.031 8.386h-2.243V.7h.49l8.774 8.899-.04-8.526h2.252V14.34h-.474ZM57.85 14.34l-8.79-8.775.023 8.386H46.84V.7h.49l8.774 8.899-.032-8.526h2.243V14.34h-.466ZM69.134 14.083a6.795 6.795 0 0 1-2.59-.498 6.698 6.698 0 0 1-2.124-1.399 6.589 6.589 0 0 1-1.438-2.083 6.478 6.478 0 0 1-.513-2.588c0-.932.173-1.733.513-2.526.34-.793.83-1.5 1.438-2.106a6.697 6.697 0 0 1 2.124-1.43 6.566 6.566 0 0 1 2.598-.52c.924 0 1.761.17 2.575.52a6.856 6.856 0 0 1 2.14 1.43 6.738 6.738 0 0 1 1.453 2.106c.348.793.53 1.648.53 2.55 0 .901-.174 1.756-.53 2.549a6.69 6.69 0 0 1-6.168 4.002l-.008-.007Zm0-11.014c-.616 0-1.192.117-1.73.35-.536.233-1.01.56-1.397.964a4.262 4.262 0 0 0-.916 1.415 4.606 4.606 0 0 0-.324 1.71c0 .598.11 1.204.332 1.725.22.528.537.995.947 1.407.403.404.877.722 1.406.956a4.292 4.292 0 0 0 1.714.341c.616 0 1.193-.116 1.714-.341a4.464 4.464 0 0 0 2.322-2.355c.229-.529.34-1.112.34-1.718 0-.606-.12-1.205-.34-1.733a4.72 4.72 0 0 0-.94-1.423 4.239 4.239 0 0 0-1.398-.956 4.405 4.405 0 0 0-1.714-.342h-.016ZM108.276 1.073h-2.243v12.879h2.243V1.073ZM113.219 13.952V1.073h3.696c1.279 0 2.385.195 3.277.575.901.381 1.643.91 2.204 1.555.561.645.971 1.36 1.224 2.13.253.761.379 1.538.379 2.316 0 .963-.174 1.85-.513 2.619a5.961 5.961 0 0 1-1.422 1.982 6.29 6.29 0 0 1-2.069 1.259 7.05 7.05 0 0 1-2.464.435h-4.32l.008.008Zm3.988-2.138c.648 0 1.256-.1 1.801-.287a4.197 4.197 0 0 0 1.429-.832c.403-.357.719-.8.94-1.329.229-.528.34-1.142.34-1.826 0-.684-.119-1.337-.356-1.873a3.776 3.776 0 0 0-.971-1.345 4.358 4.358 0 0 0-1.398-.824 4.848 4.848 0 0 0-1.635-.28h-1.903v8.596H117.207ZM99.945 13.951l-3.412-8.285-3.412 8.285h-2.535L96.533 0l5.947 13.951h-2.535ZM77.965 1.049H80.5l3.412 8.285 3.411-8.285h2.536l-5.947 13.95-5.947-13.95Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLogoInnovaid;
