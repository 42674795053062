import * as React from "react";
import { SVGProps } from "react";

const BigCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="68" height="68" rx="5" fill="#3751FF" />
    <path
      d="M19 34.773L29.9336 45L50 24"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BigCheckIcon;
