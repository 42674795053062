import Image, { ImageProps } from "next/legacy/image";
import React, { useState } from "react";
import classnames from "classnames";
import { Loading } from "@dentlink/ui";

interface CarouselImageProps extends ImageProps {
  alt: string;
  sizes?: string;
  /** Do not pass directly. Carousel's itemWidthRatio would control it */
  className?: string;
}

export default function CarouselImage({
  alt,
  sizes = "(max-width: 767px) 90vw, 60vw",
  className = "h-screen tablet:py-28 tablet:px-40",
  layout = "fill",
  ...imageProps
}: CarouselImageProps) {
  const [isLoading, setIsLoading] = useState(true);
  const onImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div
      className={classnames("flex-center shrink-0 grow", className)}
      style={{ width: imageProps.width }}
    >
      <div className="relative h-screen w-full select-none tablet:h-full">
        <div
          className={classnames("grid h-full w-full place-items-center", {
            hidden: !isLoading,
          })}
        >
          <Loading color="white" />
        </div>
        <Image
          layout={layout}
          objectFit="contain"
          draggable={false}
          alt={alt}
          sizes={sizes}
          onLoadingComplete={onImageLoad}
          {...imageProps}
        />
      </div>
    </div>
  );
}
