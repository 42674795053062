/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef } from "react";

interface UseControlledProps {
  /**
   * input의 controlled value (when set, component will be controlled)
   */
  controlledValue: any;
  /**
   * component name
   */
  name: string;
  /**
   * state name (checked, text, ...)
   */
  state: string;
}

/**
 * 현재 input이 Controlled Input인지 여부를 반환하고,
 * 렌더링 이후에 해당 상태를 변경하려고 한다면 console창에 error 메시지를 띄움.
 */
const useControlled = ({
  controlledValue,
  name,
  state,
}: UseControlledProps) => {
  // once control status determined, it should never change.
  const { current: isControlled } = useRef<boolean>(
    controlledValue !== undefined
  );

  if (process.env.NODE_ENV !== "production") {
    useEffect(() => {
      if (isControlled !== (controlledValue !== undefined)) {
        console.error(
          [
            `@dentlink/ui : A component is changing the ${
              isControlled ? "" : "un"
            }controlled ${state} state of ${name} to be ${
              isControlled ? "un" : ""
            }controlled.`,
            "Elements should not switch from uncontrolled to controlled (or vice versa).",
            `Decide between using a controlled or uncontrolled ${name} ` +
              "element for the lifetime of the component.",
            "The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.",
            "More info: https://fb.me/react-controlled-components",
          ].join("\n")
        );
      }
    }, [state, name, controlledValue]);
  }

  return isControlled;
};

export default useControlled;
