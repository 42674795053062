import { CaretDown } from "@dentlink/icons";
import { getYear } from "date-fns";

const getRange = (start: number, end: number) => {
  const range = [];
  for (let i = start; i <= end; i++) {
    range.push(i);
  }
  return range;
};

interface YearSelectProps {
  year: number;
  changeYear: (year: number) => void;
}

const YearSelect = ({ year, changeYear }: YearSelectProps) => {
  const currentYear = getYear(new Date());
  const yearRange = getRange(currentYear - 100, currentYear + 1);

  return (
    <div className="flex gap-1">
      <select
        onChange={(e) => {
          changeYear(parseInt(e.target.value, 10));
        }}
        value={year}
        className="appearance-none bg-transparent text-center"
      >
        {yearRange.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <CaretDown className="mt-1.5 text-sm" />
    </div>
  );
};

export default YearSelect;
