import {
  OrderStatus as TOrderStatus,
  PaymentStatus,
} from "../../../common/apis/OrderAPI/types";
import { Company } from "../../../common/apis/UserAPI/types";

const checkShowPaymentPendingLabel = (
  payment_status: PaymentStatus,
  create_company_type: Omit<Company, "Admin">,
  order_status: TOrderStatus
) => {
  // 2. order_status === "canceled" || order_status === "declined"면 무조건 노출하지 않는다
  if (order_status === "canceled" || order_status === "declined") {
    return false;
    // 2.order_status === "canceled" || order_status === "declined"가 아니면서
  } else {
    // 3. payment_status === "pending" || payment_status === "error"면 노출한다.
    if (payment_status === "pending" || payment_status === "error") {
      return true;
    } else {
      return false;
    }
  }
};

export default checkShowPaymentPendingLabel;
