import { CarouselOptions } from "./types";

export const INITIAL_DIFF = 0;
export const IDX_OFFSET = 1;

export const SENSITIVITY_MAP = {
  sensitive: 10,
  standard: 50,
  insensitive: 200,
} as const;

/**
 * @typedef {Object} IDX_GUIDE
 * @property {1} FIRST_ITEM - 원본 첫번째 아이템
 * @property {number} LAST_ITEM - 원본 마지막 아이템
 * @property {number} TAIL - 복제한 첫번째 아이템(마지막 인덱스)
 * @property {0} FRONT- 복제한 마지막 아이템(첫번째 인덱스)
 */
/**
 * 복제한 children 배열에 대한 인덱스 가이드 상수들을 반환하는 함수
 * @return IDX_GUIDE
 * @param childrenCount - 원본 children length
 */
export const IDX_GUIDE_MAP = (childrenCount: number) => ({
  FIRST_ITEM: IDX_OFFSET,
  LAST_ITEM: childrenCount - IDX_OFFSET,
  TAIL: childrenCount + IDX_OFFSET,
  FRONT: 0,
});

export const DEFAULT_CAROUSEL_OPTIONS: CarouselOptions = {
  arrows: true,
  dots: true,
  autoSlide: false,
  hideBodyScroll: false,
  autoSlideOptions: {
    interval: 2000,
    direction: "next",
  },
  startIndex: 0,
  speed: 400,
  itemWidthRatio: 100,
  sensitivity: "standard",
  transitionType: "linear",
};
