import { LogoInnovaid } from "@dentlink/icons";
import { Typography } from "@dentlink/ui";
import { Fragment } from "react";
import classnames from "classnames";
import { FooterOptionProps } from "src/lib/Layout/types/footer";

/*
  모바일
  - 기본적으로 숨김처리하고 특정 페이지에서만 노출
  - 노출이 필요한 페이지에서는 show props를 활용
  
  데스크탑
  - 노출이 기본값이고 현재는 제어 불가
*/

const bgColorMap = {
  background: "bg-background",
  white: "bg-white",
} as const;

const Footer = ({
  backgroundColor = "background",
  showMobile = false,
  showDesktop = true,
}: FooterOptionProps) => {
  return (
    <footer
      className={classnames(
        "w-full border-t-[0.5px] border-t-lineColor",
        bgColorMap[backgroundColor],
        showMobile ? "block" : "hidden",
        showDesktop ? "md:block" : "md:hidden"
      )}
    >
      <section className="mx-auto flex max-w-[80rem] flex-col px-4 pt-8 pb-6  text-grayText tablet:px-[2.5rem] tablet:py-6">
        <LogoInnovaid width={125} height={16} className="mb-4 self-start" />
        <Typography size="xs" color="inherit" renderAs="p" className="mb-1">
          {Object.values(FooterContents).map((text, idx, { length }) => (
            <Fragment key={text}>
              <span>{text}</span>
              {idx < length - 1 && <span className="mx-2">|</span>}
            </Fragment>
          ))}
        </Typography>
        <Typography size="xs" color="inherit" renderAs="p">
          {COPYRIGHT}
        </Typography>
      </section>
    </footer>
  );
};

export default Footer;

const FooterContents = {
  COMPANY_NAME: "Innovaid Co.",
  CEO_NAME: "KOOK JIN HYEOK",
  ADDRESS: "F16, 122, Mapo-daero, Mapo-gu, Seoul, Republic of Korea",
  PHONE_NUMBER: "T: +82 (02) 6959 0960",
  EMAIL: "M: support@dentlink.app",
  REGISTRATION_NO: "Company Registration No : 138-86-04528",
};

const COPYRIGHT = `© Copyright ${new Date().getFullYear()} Innovaid Corp.`;
