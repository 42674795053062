import useIsMobile from "../../common/hooks/useIsMobile";
import Menu from "./Menu";
import { useConditionalMenus } from "../../lib/Menus/hooks";

const MenuList = () => {
  const isMobile = useIsMobile();
  const menus = useConditionalMenus();

  return (
    <div className="flex flex-col p-4 tablet:pt-6 tablet:pb-2">
      <Menu.NewCaseButton showChevron={isMobile} />

      <Menu.Group divider={false} className="pt-4">
        {menus.common?.map((props) => (
          <Menu.Item key={props.link} {...props} />
        ))}
      </Menu.Group>

      <Menu.Group>
        {menus.settings?.map((props) => (
          <Menu.Item key={props.link} {...props} />
        ))}
      </Menu.Group>

      <Menu.Group>
        {menus.helps?.map((props) => (
          <Menu.Item key={props.link} {...props} />
        ))}
      </Menu.Group>

      <Menu.Group
        divider={!isMobile}
        className="mt-[0.6px] tablet:mt-0 tablet:mb-0.5"
      >
        <Menu.SignOutButton />
      </Menu.Group>
    </div>
  );
};

export default MenuList;
