import { OrderStatusStyler } from "../types/OrderStatus";
import { OrderStatus } from "src/common/apis/OrderAPI/types";
import { pipe } from "@dentlink/utils";

const getBackgroundColor: OrderStatusStyler = (
  orderStatus: OrderStatus,
  acc: string[]
): string[] => {
  switch (orderStatus) {
    case "draft":
    case "new":
    case "canceled":
    case "declined":
      acc.push("bg-white");
      break;
    case "reviewing":
    case "processing":
    case "shipping":
      acc.push("bg-primary");
      break;
    case "confirmed":
      acc.push("bg-blueGray");
      break;
    default:
  }
  return acc;
};

const getVariant: OrderStatusStyler = (
  orderStatus: OrderStatus,
  acc: string[]
): string[] => {
  switch (orderStatus) {
    case "draft":
      acc.push("border-grayText");
      break;
    case "new":
      acc.push("border-primary");
      break;
    case "canceled":
    case "declined":
      acc.push("border-error");
      break;
    default:
      return acc;
  }
  acc.push("box-border border");
  return acc;
};

const getTextColor: OrderStatusStyler = (
  orderStatus: OrderStatus,
  acc: string[]
): string[] => {
  switch (orderStatus) {
    case "draft":
      acc.push("text-grayText");
      break;
    case "new":
      acc.push("text-primary");
      break;
    case "reviewing":
    case "processing":
    case "shipping":
    case "confirmed":
      acc.push("text-white");
      break;
    case "canceled":
    case "declined":
      acc.push("text-error");
      break;
    default:
  }
  return acc;
};

const stylers: OrderStatusStyler[] = [
  getBackgroundColor,
  getVariant,
  getTextColor,
];

export const getOrderStatusStyle = (orderType: OrderStatus) => {
  const partialStylers = stylers.map((styler) => styler.bind(null, orderType));
  const pipedStylers = pipe(...partialStylers);
  return pipedStylers([]).join(" ");
};
