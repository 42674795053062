import { Minus } from "@dentlink/icons";
import { format } from "date-fns";
import { RefObject } from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import DatePickerChevron from "./DatePickerChevron";
import YearMonthPicker from "./YearMonthPicker";

interface RangepickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  monthPickerRef: RefObject<HTMLInputElement>;
  startDate: Date | null;
  endDate: Date | null;
  locale?: Locale;
}

const RangePickerHeader = (props: RangepickerHeaderProps) => {
  const {
    monthDate,
    increaseMonth,
    decreaseMonth,
    changeMonth,
    changeYear,
    locale,
    monthPickerRef,
    startDate,
    endDate,
  } = props;

  const yearMonthPickerProps = {
    monthDate,
    monthPickerRef,
    locale,
    changeMonth,
    changeYear,
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex w-full items-center gap-[12px] text-md">
        <input
          value={startDate ? format(startDate, "MMM dd, yyyy") : ""}
          readOnly
          type="text"
          className="w-full border-b border-black text-center text-text outline-none"
        />
        <Minus width={12} height={12} className="flex-shrink-0" />
        <input
          value={endDate ? format(endDate, "MMM dd, yyyy") : ""}
          readOnly
          type="text"
          className="w-full border-b border-black text-center text-text outline-none"
        />
      </div>
      <div className="mb-3 flex w-full items-center justify-between">
        <button
          type="button"
          id="to-previous-month"
          onClick={decreaseMonth}
          className="flex-center h-[1.875rem] w-[1.875rem] rounded"
        >
          <DatePickerChevron direction="left" />
        </button>
        <YearMonthPicker {...yearMonthPickerProps} />
        <button
          type="button"
          id="to-next-month"
          onClick={increaseMonth}
          className="flex-center h-[1.875rem] w-[1.875rem] rounded"
        >
          <DatePickerChevron direction="right" />
        </button>
      </div>
    </div>
  );
};

export default RangePickerHeader;
