import { useRouter } from "next/router";
import { useEffect } from "react";

function storePrevPaths() {
  const storage = globalThis?.sessionStorage;
  if (!storage) return;

  const prevPath = storage.getItem("currentPath");
  storage.setItem("prevPath", prevPath ?? "/");
  storage.setItem("currentPath", globalThis.location.pathname);
}

export default function useStorePrevPath() {
  const router = useRouter();
  useEffect(() => storePrevPaths, [router.asPath]);
}
