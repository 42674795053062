import { useEffect } from "react";
import { disableBodyScrolling, enableBodyScrolling } from "@dentlink/utils";

interface useDisableBodyScrollProp {
  /**
   * whether to disable scrolling
   */
  condition?: boolean;
}

const useDisableBodyScroll = ({ condition }: useDisableBodyScrollProp) => {
  useEffect(() => {
    if (condition) {
      disableBodyScrolling();
    } else {
      enableBodyScrolling();
    }

    return () => {
      enableBodyScrolling();
    };
  }, [condition]);
};

export default useDisableBodyScroll;
