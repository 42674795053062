import { ThemeColor } from "@dentlink/types";
import { backgroundColorConfig } from "./configs";
import { UtilityClassGenerator } from "./type";

interface BackgroundClassesProps {
  color?: ThemeColor | "inherit";
}

const getBackgroundClasses: UtilityClassGenerator<BackgroundClassesProps> = ({
  color,
}) => {
  return [color && backgroundColorConfig[color]].filter(Boolean).join(" ");
};

export default getBackgroundClasses;
