import { CaretDown } from "@dentlink/icons";
import { ChangeEvent } from "react";

interface MonthSelectProps {
  month: number;
  changeMonth: (month: number) => void;
}

const MONTH_NAMES_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MonthSelect = ({ month, changeMonth }: MonthSelectProps) => {
  // TODO : Locale 설가
  // TODO : background에 삼각형 추가
  return (
    <div className="flex gap-1">
      <select
        value={month}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          changeMonth(parseInt(e.target.value, 10));
        }}
        className="appearance-none bg-transparent text-center"
      >
        {MONTH_NAMES_SHORT.map((monthName, i) => (
          <option key={monthName} value={i}>
            {monthName}
          </option>
        ))}
      </select>
      <CaretDown className="mt-1.5 text-sm" />
    </div>
  );
};

export default MonthSelect;
