import { createContext, useContext } from "react";

interface TabsContext<T = any> {
  value: T;
  onChange: (newValue: T) => void;
}

const TabsContext = createContext<TabsContext | null>(null);

export const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("useTabsContext must be used within a TabsProvider");
  }
  return context;
};

export default TabsContext;
