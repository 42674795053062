import {
  AllHTMLAttributes,
  ChangeEvent,
  FormEventHandler,
  forwardRef,
  ReactNode,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import useControlled from "../../utils/useControlled";
import useForkRef from "../../utils/useForkRef";
import classNames from "classnames";
import CheckboxIcon from "./components/CheckboxIcon";

interface CheckboxProps
  extends Omit<AllHTMLAttributes<HTMLInputElement>, "label"> {
  /**
   * The name of the checkbox.
   */
  label?: ReactNode;
  /**
   * The checked status of checkbox
   */
  checked?: boolean;
  /**
   * Callback to call when value change(necessary when controlled)
   */
  onChange?: FormEventHandler<HTMLInputElement>;
  className?: string;
  labelClasses?: string;
  helperText?: ReactNode;
  variant?: "outlined" | "contained";
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      checked: isChecked,
      onChange,
      className,
      labelClasses = "text-md",
      variant = "outlined",
      helperText,
      ...props
    },
    ref
  ) => {
    const id = useId();
    const checkboxId = `checkbox-${label}-${id}`;

    const isControlled = useControlled({
      controlledValue: isChecked,
      name: "checkbox",
      state: "checked",
    });

    const uncontrolledRef = useRef<HTMLInputElement>(null);
    const handleRef = useForkRef(ref, uncontrolledRef);

    const [checked, setChecked] = useState(isControlled ? isChecked : false);

    useEffect(() => {
      // controlled 일때만 isChecked 변화에 따라 체크박스 상태를 변경합니다
      if (isControlled) setChecked(isChecked);
    }, [isChecked, isControlled]);

    useEffect(() => {
      // uncontrolled input일 경우 uncontrolled checked state를 구독하고 있도록 함.
      if (!isControlled) setChecked(uncontrolledRef.current?.checked);
    }, [isControlled]);

    const onHiddenInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      /** @see https://react-hook-form.com/api/useform/register/#main*/
      if (!isControlled) setChecked(e.target.checked);
    };

    const hiddenInputRef = !isControlled ? handleRef : undefined;

    return (
      <>
        <label
          htmlFor={checkboxId}
          className={classNames(
            "cursor-pointer",
            { "relative flex w-full items-center gap-2": label !== undefined },
            className
          )}
        >
          <CheckboxIcon
            width={19}
            height={19}
            variant={variant}
            checked={checked}
          />
          <span
            className={classNames(
              "flex select-none flex-wrap gap-1",
              { "text-grayText": !checked },
              labelClasses
            )}
          >
            {label}
          </span>
          <input
            {...props}
            id={checkboxId}
            type="checkbox"
            className="hidden"
            checked={isChecked}
            ref={hiddenInputRef}
            onChange={onHiddenInputChange}
          />
        </label>
        {!!helperText && <div className="mt-2">{helperText}</div>}
      </>
    );
  }
);

export default Checkbox;
