import { RefObject } from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import YearMonthPicker from "./YearMonthPicker";

interface CustomHeaderProps extends ReactDatePickerCustomHeaderProps {
  monthPickerRef: RefObject<HTMLInputElement>;
  locale?: Locale;
}

const DatePickerChevron = ({ direction }: { direction: "left" | "right" }) => {
  const shape = {
    left: "M17.5 20L12.5 15.0024L17.5 10",
    right: "M12.5 20L17.5 15.0024L12.5 10",
  };
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="5" fill="white" />
      <path
        d={shape[direction]}
        stroke="#282829"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DatepickerHeader = (props: CustomHeaderProps) => {
  const {
    monthDate,
    increaseMonth,
    decreaseMonth,
    changeMonth,
    changeYear,
    locale,
    monthPickerRef,
  } = props;

  const yearMonthPickerProps = {
    monthDate,
    monthPickerRef,
    locale,
    changeMonth,
    changeYear,
  };

  return (
    <div className="mb-3 flex w-full items-center justify-between">
      <button
        type="button"
        id="to-previous-month"
        onClick={decreaseMonth}
        className="flex-center"
      >
        <DatePickerChevron direction="left" />
      </button>
      <YearMonthPicker {...yearMonthPickerProps} />
      <button
        type="button"
        id="to-next-month"
        onClick={increaseMonth}
        className="flex-center"
      >
        <DatePickerChevron direction="right" />
      </button>
    </div>
  );
};

export default DatepickerHeader;
