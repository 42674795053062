import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { setIsProfileSetupComplete } from "../redux/profileSlice";
import { selectIsProfileSetupComplete } from "../redux/profileSelector";
import { useProfileQuery } from "../../../common/queries";

const useCheckShouldRedirectToProfileSetupPage = ({
  disabledPath,
}: {
  disabledPath: string[];
}) => {
  const { pathname } = useRouter();
  const dispatch = useDispatch();
  const isDisabledPath = disabledPath.some((p) => p === pathname);

  const prevIsProfileSetupComplete = useSelector(selectIsProfileSetupComplete);
  let curIsProfileSetupComplete = prevIsProfileSetupComplete;

  const { data, isLoading } = useProfileQuery({
    enabled: !prevIsProfileSetupComplete,
  });

  if (data) {
    curIsProfileSetupComplete = !!data.phone_num;
    const isSameState =
      curIsProfileSetupComplete === prevIsProfileSetupComplete;
    !isSameState &&
      dispatch(setIsProfileSetupComplete(curIsProfileSetupComplete));
  }

  const shouldRedirectToProfileSetupPage =
    !isDisabledPath && !isLoading && !curIsProfileSetupComplete;
  return shouldRedirectToProfileSetupPage;
};

export default useCheckShouldRedirectToProfileSetupPage;
