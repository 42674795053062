import classnames from "classnames";
import { HTMLAttributes, ReactNode, useMemo } from "react";
import useObserverTargets from "../../lib/FadeScroll/useObserverTargets";
import {
  Color,
  getGradientClass,
  Height,
  Width,
} from "../../lib/FadeScroll/constants";

type Targets = "FrontTarget" | "RearTarget";
type Children = {
  [key in Targets]: (props: HTMLAttributes<HTMLDivElement>) => JSX.Element;
};

interface FadeScrollWrapperProps {
  className?: string;
  children: ({ FrontTarget, RearTarget }: Children) => ReactNode;
  color?: Color;
  width?: Width;
  height?: Height;
}

export default function FadeScrollWrapper({
  children,
  className,
  color = "white",
  width = 4,
  height = 5.5,
}: FadeScrollWrapperProps) {
  const { FrontTarget, RearTarget, isIntersectingFront, isIntersectingRear } =
    useObserverTargets();

  const { before, after } = useMemo(
    () => getGradientClass(color, width, height),
    [color, height, width]
  );

  return (
    <div
      className={classnames("relative w-full overflow-hidden", className, {
        [after]: !isIntersectingRear,
        [before]: !isIntersectingFront,
      })}
    >
      {children({ FrontTarget, RearTarget })}
    </div>
  );
}
