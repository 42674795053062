import type { Props as ReactSelectProps } from "react-select";

const getStyles = <T>(hasError = false) => {
  const borderColor = hasError ? "#E63A3A" : "#E1E3E8";
  const {
    styles: customStyles,
  }: Pick<ReactSelectProps<T>, "styles"> = {
    styles: {
      input: (provided, _state) => ({
        ...provided,
        margin: "0",
        paddingTop: "0",
        paddingBottom: "5px",
        caretColor: "#3751FF4D",
        fontSize: "18px",
        ":hover": {
          cursor: "text",
        },
      }),
      control: (provided, _state) => ({
        ...provided,
        border: "none",
        borderRadius: 0,
        boxShadow: "none",
        fontSize: "1rem",
        alignItems: "baseline",
        minHeight: "31px",
        height: "31px",
      }),
      menu: (provided, _state) => ({
        ...provided,
        padding: "0.375rem",
        border: "1px solid #E1E3E8",
        boxShadow: "0px 8px 20px rgba(105, 119, 157, 0.2)",
        borderRadius: "5px",
        width: "100%",
      }),
      menuList: (provided, _state) => ({
        ...provided,
        margin: "0",
        maxHeight: "220px",
        padding: "0.625rem",
      }),
      indicatorSeparator: (provided, _state) => ({
        ...provided,
        display: "none",
      }),
      indicatorsContainer: (provided, _state) => ({
        ...provided,
        height: "2rem",
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#3751FF" : "#999999",
        backgroundColor: "transparent", // !important
        position: "relative",
        ":hover": {
          backgroundColor: "#F3F4FA",
        },
        padding: "0.5rem",
        borderRadius: "5px",
        minHeight: "2.5rem",
        marginBottom: "0.25rem",
        ":last-child": {
          marginBottom: "0",
        },
        wordBreak: "break-all",
      }),
      container: (provided, _state) => ({
        ...provided,
        height: "33px",
        width: "100%",
        borderBottom: `2px solid`,
        borderBottomColor: _state.isFocused ? "#3751FF" : borderColor,
        ":hover": {
          borderBottomColor: _state.isFocused ? "#3751FF" : borderColor,
        },
      }),
      valueContainer: (provided, _state) => ({
        ...provided,
        paddingLeft: "0",
        paddingTop: "0",
        paddingBottom: "0",
        height: "2rem",
      }),
      dropdownIndicator: (provided, _state) => ({
        ...provided,
        transition: "all .2s ease",
        transform: _state.selectProps.menuIsOpen
          ? "rotate(180deg)"
          : "rotate(0deg)",
        color: "#282829",
        ":hover": {
          color: "#282829",
        },
      }),
      clearIndicator: (provided, _state) => ({
        ...provided,
        color: "#282829",
      }),
      placeholder: (provided) => ({
        ...provided,
        paddingBottom: "6px", // 모든 input 하단 padding 6px
      }),
    },
  };

  return customStyles;
};

export default getStyles;
