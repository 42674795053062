import { ChevronRight } from "@dentlink/icons";
import { useRouter } from "next/router";
import { useNewCaseLink } from "src/lib/Menus/hooks";
import useCompanyType from "src/common/hooks/useCompanyType";

const NewCaseButtonOnMenu = ({ showChevron }: { showChevron: boolean }) => {
  const router = useRouter();
  const newCaseLink = useNewCaseLink(0);
  const oralDesignLink = useNewCaseLink(1);
  const company_type = useCompanyType();

  return (
    <>
      {/* <button
        className="flex w-full items-center justify-between rounded-dropdown bg-primary py-[18px] px-[9px] text-white shadow-button tablet:justify-center"
        onClick={() => router.push(newCaseLink.href)}
      >
        <span className="text-base font-bold">{newCaseLink.name}</span>
        {showChevron && <ChevronRight width={24} height={24} />}
      </button>
      {company_type === "Clinic" && (
        <button
          className="mt-[8px] flex w-full items-center justify-between rounded-dropdown border border-primary bg-white py-[18px] px-[9px] text-primary shadow-button tablet:justify-center"
          onClick={() => router.push(oralDesignLink.href)}
        >
          <span className="text-base font-bold">{oralDesignLink.name}</span>
          {showChevron && <ChevronRight width={24} height={24} />}
        </button>
      )} */}
    </>
  );
};

export default NewCaseButtonOnMenu;
