import * as React from "react";
import { SVGProps } from "react";
const SvgFdi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="currentColor" d="M0 0h31v19H0z" />
    <path
      d="M15.5 15a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z"
      stroke="#fff"
      strokeMiterlimit={10}
    />
    <path
      d="M10.315 7.667h10.37M10.315 11.333h10.37"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 14.851c1.265 0 2.291-2.396 2.291-5.351S16.765 4.149 15.5 4.149c-1.266 0-2.292 2.396-2.292 5.351s1.026 5.351 2.292 5.351Z"
      stroke="#fff"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgFdi;
