import { ValueOf } from "@dentlink/types";
import isEmpty from "lodash/isEmpty";
import type { UseFormSetError } from "react-hook-form";
import type { OrderFormValues } from "../types/OrderForm";

const FieldNames = {
  prosthesis: "prosthesis",
  materials: "materials",
  dental_formula: "dental_formula",
};
type CaseFieldName = ValueOf<typeof FieldNames>;

type ErrorObject = [
  (
    | `cases.${number}.prosthesis`
    | `cases.${number}.materials.${number}`
    | `cases.${number}.dental_formula`
  ),
  { type: "manual"; message: string },
  { shouldFocus: boolean }
];

const ErrorObjects: Record<CaseFieldName, (index: number) => ErrorObject> = {
  prosthesis: (index) => [
    `cases.${index}.prosthesis`,
    { type: "manual", message: "please select prosthesis type." },
    { shouldFocus: true },
  ],
  materials: (index) => [
    `cases.${index}.materials.${0}`,
    { type: "manual", message: "please select material type." },
    { shouldFocus: true },
  ],
  dental_formula: (index) => [
    `cases.${index}.dental_formula`,
    { type: "manual", message: "please select tooth." },
    { shouldFocus: true },
  ],
};

const customValidateSubmitData = (
  data: OrderFormValues,
  setError: UseFormSetError<OrderFormValues>
): boolean => {
  let validationResult = true;

  const setFieldError = (fieldName: CaseFieldName) => (caseIndex: number) => {
    setError(...ErrorObjects[fieldName](caseIndex));
  };

  const isOptionSelected = data.order_options.length > 0;
  const isOthersSelected =
    data.order_others.length > 0 &&
    data.order_others[0].other_name !== "" &&
    data.order_others[0].other_price !== undefined;

  data.cases.forEach((caseData, caseIndex) => {
    const isTeethSelected = caseData.dental_formula.length > 0;

    const isProsthesisSelected = !!caseData.prosthesis;
    const isMaterialsSelected =
      caseData.materials.filter((value) => !isEmpty(value)).length > 0;

    if (isOptionSelected || isOthersSelected) {
      if (isTeethSelected) {
        if (!isMaterialsSelected) {
          setFieldError(FieldNames.materials)(caseIndex);
          validationResult = false;
        }
        if (!isProsthesisSelected) {
          setFieldError(FieldNames.prosthesis)(caseIndex);
          validationResult = false;
        }
      } else {
        if (isProsthesisSelected) {
          setFieldError(FieldNames.dental_formula)(caseIndex);
          validationResult = false;
        }
      }
    } else {
      if (isTeethSelected) {
        if (!isMaterialsSelected) {
          setFieldError(FieldNames.materials)(caseIndex);
          validationResult = false;
        }
        if (!isProsthesisSelected) {
          setFieldError(FieldNames.prosthesis)(caseIndex);
          validationResult = false;
        }
      } else {
        if (!isMaterialsSelected) {
          setFieldError(FieldNames.materials)(caseIndex);
        }
        if (!isProsthesisSelected) {
          setFieldError(FieldNames.prosthesis)(caseIndex);
        }
        setFieldError(FieldNames.dental_formula)(caseIndex);
        validationResult = false;
      }
    }
  });

  if (data.cases.length === 0 && !isOptionSelected && !isOthersSelected) {
    validationResult = false;
    alert("Please write case, or select at least one option or others.");
  }

  return validationResult;
};

export default customValidateSubmitData;
