import { OrderStatus } from "src/common/apis/OrderAPI/types";
import type { Company } from "src/common/apis/UserAPI/types";
import { Details, OrderActionName } from "src/lib/Order/types/OrderDetail";

/**
 * 액션에 따라서 렌더링할 버튼의 스타일 옵션을 저장하고 있는 객체.
 */
export const OrderActionButtonTypeMap: Record<
  OrderActionName,
  { label: string; variant: "outlined" | "contained" }
> = {
  accept: {
    variant: "contained",
    label: "Accept",
  },
  decline: {
    variant: "outlined",
    label: "Decline",
  },
  cancel: {
    variant: "outlined",
    label: "Cancel Order",
  },
  "addTracking#": {
    variant: "contained",
    label: "Add Tracking #",
  },
  reorder: {
    variant: "contained",
    label: "Reorder",
  },
  confirm: {
    variant: "contained",
    label: "Confirm Order",
  },
  checkout: {
    variant: "contained",
    label: "Checkout",
  },
  batchCheckout: {
    variant: "contained",
    label: "Checkout",
  },
  delete: {
    variant: "outlined",
    label: "Delete",
  },
  remake: {
    variant: "contained",
    label: "Remake",
  },
  requestRemake: {
    variant: "contained",
    label: "Request a Remake",
  },
  writeReview: {
    variant: "outlined",
    label: "Write a Review",
  },
};

type OrderWriterType = "Lab" | "Clinic";

const BasicOrderDetails = [
  "OrderInfo",
  "CaseInfo",
  "AdditionalInfo",
  "OrderOptions",
] as const;

const LabOrderDetails = [...BasicOrderDetails, "Others"] as const;

/**
 * OrderDetail 페이지에서 접속 유저 / Order 작성자 / 현재 Order Status 에 따라서 보여줄 카드 정보
 */
export const OrderDetailMap: Record<
  Company,
  Record<OrderWriterType, Record<OrderStatus, Details[] | null>>
> = {
  Clinic: {
    Clinic: {
      draft: ["OrderForm"],
      new: [...BasicOrderDetails],
      reviewing: [...BasicOrderDetails],
      processing: [...BasicOrderDetails],
      shipping: [...BasicOrderDetails, "ShippingInfo"],
      confirmed: [...BasicOrderDetails, "ShippingInfo", "ReviewInfo"],
      canceled: [...BasicOrderDetails],
      declined: [...BasicOrderDetails],
    },
    Lab: {
      draft: [...LabOrderDetails],
      new: [...LabOrderDetails],
      reviewing: [...LabOrderDetails],
      processing: [...LabOrderDetails],
      shipping: [...LabOrderDetails, "ShippingInfo"],
      confirmed: [...LabOrderDetails, "ShippingInfo", "ReviewInfo"],
      canceled: [...LabOrderDetails],
      declined: [...LabOrderDetails, "ShippingInfo"],
    },
  },
  Lab: {
    Clinic: {
      draft: [...BasicOrderDetails],
      new: [...BasicOrderDetails],
      reviewing: [...BasicOrderDetails],
      processing: [...BasicOrderDetails],
      shipping: [...BasicOrderDetails, "ShippingInfo"],
      confirmed: [...BasicOrderDetails, "ShippingInfo", "ReviewInfo"],
      canceled: [...BasicOrderDetails],
      declined: [...BasicOrderDetails, "ShippingInfo"],
    },
    Lab: {
      draft: ["OrderForm"],
      new: [...LabOrderDetails],
      reviewing: [...LabOrderDetails],
      processing: [...LabOrderDetails],
      shipping: [...LabOrderDetails, "ShippingInfo"],
      confirmed: [...LabOrderDetails, "ShippingInfo", "ReviewInfo"],
      canceled: [...LabOrderDetails],
      declined: [...LabOrderDetails, "ShippingInfo"],
    },
  },
  Admin: {
    Clinic: {
      draft: [...BasicOrderDetails],
      new: [...BasicOrderDetails],
      reviewing: [...BasicOrderDetails],
      processing: [...BasicOrderDetails],
      shipping: [...BasicOrderDetails, "ShippingInfo"],
      confirmed: [...BasicOrderDetails, "ShippingInfo", "ReviewInfo"],
      canceled: [...BasicOrderDetails, "ShippingInfo"],
      declined: [...BasicOrderDetails],
    },
    Lab: {
      draft: [...LabOrderDetails],
      new: [...LabOrderDetails],
      reviewing: [...LabOrderDetails],
      processing: [...LabOrderDetails],
      shipping: [...LabOrderDetails, "ShippingInfo"],
      confirmed: [...LabOrderDetails, "ShippingInfo", "ReviewInfo"],
      canceled: [...LabOrderDetails, "ShippingInfo"],
      declined: [...LabOrderDetails],
    },
  },
};

type IsPaymentCompleted = "Y" | "N";

const AllNull: Record<OrderStatus, null> = {
  draft: null,
  new: null,
  reviewing: null,
  processing: null,
  shipping: null,
  confirmed: null,
  canceled: null,
  declined: null,
};

/**
 * OrderDetail 페이지에서 접속 유저 / Order 작성자 / 현재 Order Status 에 따라서 수행할 수 있는 Action 이름
 */
export const OrderActionNameMap: Record<
  Company,
  Record<
    OrderWriterType,
    Record<IsPaymentCompleted, Record<OrderStatus, OrderActionName[] | null>>
  >
> = {
  Clinic: {
    Clinic: {
      Y: {
        draft: null,
        new: ["cancel"],
        reviewing: ["cancel"],
        canceled: ["reorder"],
        declined: ["reorder"],
        processing: null,
        shipping: ["confirm"],
        confirmed: ["requestRemake", "writeReview"],
      },
      N: {
        draft: null,
        new: ["cancel"],
        reviewing: ["cancel"],
        canceled: ["reorder"],
        declined: ["reorder"],
        processing: null,
        shipping: ["batchCheckout"],
        confirmed: ["requestRemake", "writeReview"],
      },
    },
    Lab: {
      Y: {
        draft: null,
        new: null,
        reviewing: null,
        processing: ["decline"],
        shipping: ["confirm"],
        confirmed: ["requestRemake", "writeReview"],
        canceled: null,
        declined: null,
      },
      N: {
        draft: null,
        new: null,
        reviewing: null,
        processing: ["decline"],
        shipping: ["batchCheckout"],
        confirmed: null,
        canceled: null,
        declined: null,
      },
    },
  },
  Lab: {
    Clinic: {
      Y: {
        draft: null,
        new: ["accept", "decline"],
        reviewing: ["accept", "decline"],
        canceled: ["reorder"],
        declined: ["reorder"],
        processing: ["addTracking#", "cancel"],
        shipping: null,
        confirmed: null,
      },
      N: {
        draft: null,
        new: ["accept", "decline"],
        reviewing: ["accept", "decline"],
        canceled: ["reorder"],
        declined: ["reorder"],
        processing: ["addTracking#", "cancel"],
        shipping: null,
        confirmed: null,
      },
    },
    Lab: {
      Y: {
        draft: null,
        new: null,
        reviewing: null,
        processing: ["addTracking#"],
        shipping: null,
        confirmed: ["remake"],
        canceled: ["reorder"],
        declined: ["reorder"],
      },
      N: {
        draft: null,
        new: null,
        reviewing: null,
        processing: ["addTracking#", "cancel"],
        shipping: ["cancel"],
        confirmed: ["remake"],
        canceled: ["reorder"],
        declined: ["reorder"],
      },
    },
  },
  Admin: {
    Clinic: {
      Y: AllNull,
      N: AllNull,
    },
    Lab: {
      Y: AllNull,
      N: AllNull,
    },
  },
};
