import { ChangeEvent } from "react";
import Header from "./Header";

interface SearchHeader {
  value: string;
  onChange: (arg0: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

const SearchHeader = ({ value, onChange, onClear }: SearchHeader) => {
  return (
    <Header backgroundColor="white">
      <div className="mx-auto flex h-full w-full max-w-[40rem] items-center pl-1 pr-4">
        <Header.BackLink />
        <Header.Searchbar value={value} onChange={onChange} onClear={onClear} />
      </div>
    </Header>
  );
};

export default SearchHeader;
