import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import QueryKeys from "../constants/QueryKeys";
import { PaymentMethod } from "../apis/PaymentAPI/type";
import { AxiosError } from "axios";
import { PaymentAPI } from "../apis/PaymentAPI";

type UsePaymentMethodsQuery<T> = UseQueryOptions<
  PaymentMethod[],
  AxiosError,
  T
>;

export default function usePaymentMethodsQuery<T = PaymentMethod[]>(
  options: UsePaymentMethodsQuery<T> = {}
) {
  return useQuery<PaymentMethod[], AxiosError, T>(
    [QueryKeys.PAYMENT_CARD_LIST],
    async () => (await PaymentAPI.lookupPaymentMethod()).data,
    options
  );
}
