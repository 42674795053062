const left = "M6.5 12L1.5 7.00242L6.5 2";
const right = "M1.5 12L6.5 7.00242L1.5 2";

const shapeMap = { left, right };

const DatePickerChevron = ({ direction }: { direction: "left" | "right" }) => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={shapeMap[direction]}
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DatePickerChevron;
