import * as React from "react";
import { SVGProps } from "react";
const SvgCaretDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7 7 .938.538h12.124L7 7Z" fill="currentColor" />
  </svg>
);
export default SvgCaretDown;
