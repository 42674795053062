import { RefCallback, RefObject, useImperativeHandle, useRef } from "react";
import ReactDatePicker from "react-datepicker";

const useDatepickerRef = (
  ref: RefObject<any> | RefCallback<any> | undefined | null
) => {
  /**
   * react-datepicker 에서 제공하는 ref에는
   * <ReactDatepicker/> 가 Class Component 형태로 그대로 들어가기 때문에
   * focus() 메서드가 없다. (ref.current.focus() 불가)
   * 따라서 useImperativeHandle로 직접 focus 메서드를 추가해준다.
   */
  const datepickerRef = useRef<ReactDatePicker>(null);
  const customInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      customInputRef.current?.focus();
    },
  }));

  return { datepickerRef, customInputRef };
};

export default useDatepickerRef;
