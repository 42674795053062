import { useEffect } from "react";

/**
 * Hook to watch certain value changes.
 * @param value Any value to watch for changes
 * @param options Options to apply
 */
export default function useWatchChanges(
  value: any,
  options?: { name?: string }
) {
  useEffect(() => {
    if (options?.name) {
      console.log(`${options.name} changed to `, value);
    } else {
      console.log(value);
    }
  }, [value]);
}
