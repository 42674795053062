import * as React from "react";
import { SVGProps } from "react";
const SvgHamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.027 1H20M0 9h19.973M0 17h19.973"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
export default SvgHamburger;
