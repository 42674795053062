import * as React from "react";
import { SVGProps } from "react";
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 11.7C6.203 10.066 8.408 8 11.872 8c3.465 0 5.67 2.066 6.873 3.7"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <circle cx={11.872} cy={13.621} r={2.379} fill="currentColor" />
  </svg>
);
export default SvgEye;
