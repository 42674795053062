import Highlighter from "react-highlight-words";
import { FormatOptionLabelMeta } from "react-select";

function formatOptionLabel<Option extends { label: string }>(
  { label }: Option,
  { inputValue }: FormatOptionLabelMeta<Option>
) {
  return (
    <Highlighter
      searchWords={[inputValue]}
      textToHighlight={label}
      autoEscape
      highlightClassName="text-primary"
      highlightStyle={{ backgroundColor: "transparent" }}
    />
  );
}

export default formatOptionLabel;
