import { useContext } from "react";
import RadioGroupContext from "./RadioGroupContext";

export default function useRadioGroup() {
  const context = useContext(RadioGroupContext);

  if (!context) {
    throw new Error("useRadioGroup must be used within a RadioGroup");
  }

  return context;
}
