import { components } from "react-select";
import { CircleCancel } from "@dentlink/icons";

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <CircleCancel className="text-iconColor" />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
