import React, { ButtonHTMLAttributes, forwardRef } from "react";
import { ColorSchemeType, VariantType } from "./types";
import {
  ButtonColorConfig,
  ButtonVariantConfig,
  DisabledButtonColorConfig,
  SizeConfig,
} from "./buttonConfig";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: (value: any) => void;
  color?: ColorSchemeType;
  fullWidth?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  variant?: VariantType;
  className?: string;
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "reset";
  elevation?: boolean;
}

const containerStyle =
  "flex flex-row rounded-[5px] justify-center items-center";
const shadowStyle = "shadow-button";
const fontStyle = "font-semibold";

const paddingStyle = "py-2.5";

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      color,
      fullWidth = false,
      startIcon,
      endIcon,
      variant = "contained",
      children,
      className,
      size = "large",
      type = "button",
      elevation,
      disabled,
      ...props
    },
    ref
  ) => {
    const widthStyle = fullWidth ? "w-full" : "w-fit";
    const colorScheme =
      color ?? ButtonVariantConfig[variant].defaultColorScheme;

    const classes = [
      elevation && shadowStyle,
      widthStyle,
      SizeConfig[variant][size],
      paddingStyle,
      containerStyle,
      fontStyle,
      disabled
        ? DisabledButtonColorConfig[variant][colorScheme]
        : ButtonColorConfig[variant][colorScheme],
      className,
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <button
        ref={ref}
        className={classes}
        onClick={onClick}
        type={type}
        disabled={disabled}
        {...props}
      >
        {startIcon}
        <span className="text-inherit">{children}</span>
        {endIcon}
      </button>
    );
  }
);

export default Button;
