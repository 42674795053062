import { Settings } from "@dentlink/icons";
import { useRouter } from "next/router";
import Links from "../../../common/constants/Links";

export default function SettingsButton({ className }: { className?: string }) {
  const router = useRouter();
  const onClick = () => router.push(Links.PROFILE);

  return (
    <button type="button" className={className} onClick={onClick}>
      <Settings width={32} height={32} />
    </button>
  );
}
