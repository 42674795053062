import isEmpty from "lodash/isEmpty";
import {
  EditOrderPayload,
  WriteOrderPayload,
} from "src/common/apis/OrderAPI/types";
import {
  OrderFormMode,
  OrderFormModeValue,
  OrderType,
} from "../constants/OrderForm";
import { ModeDependentData } from "../types/ModeContexts";
import { OrderFormValues, MaterialOption } from "../types/OrderForm";

/**
 *
 * @param submitData OrderForm에서 제출한 데이터
 * @param dependentData OrderForm이 사용되는 Mode Context에 따라 필요한 데이터
 * @param mode OrderForm이 사용되는 Mode Context
 * @returns OrderForm에서 제출한 데이터를 API에 전달할 수 있는 형태로 변환한 데이터
 */
export default function parseFormDataIntoPayload(
  submitData: OrderFormValues,
  dependentData: ModeDependentData | null,
  mode: OrderFormModeValue,
  isRelay?: boolean
): WriteOrderPayload | EditOrderPayload {
  const parsedFormData = {
    patient_name: submitData.patient_name,
    patient_gender: submitData.patient_gender,
    patient_birthdate: submitData.patient_birthdate?.toISOString(),
    arrival_date: submitData.arrival_date.toISOString(),
    impression_type: submitData.impression_type,
    numbering_system: submitData.numbering_system,
    cases: submitData.cases.map((caseData) => ({
      dental_formula: caseData.dental_formula,
      prosthesis_id: caseData.prosthesis?.value,
      material_ids: caseData.materials
        .filter((value) => !isEmpty(value))
        .map((material: MaterialOption) => material.value),
      shade: caseData.shade?.value || "",
    })),
    order_options: submitData.order_options.map(
      // @ts-expect-error `id` is automatically inserted by react-hook-form fieldArray
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ option_name: _, id, ...option }) => option
    ),
    order_others: submitData.order_others,
    description: submitData.description,
    scan_file_ids: submitData.scan_files.map((scanFile) => scanFile.file_id),
    image_file_ids: submitData.image_files.map(
      (imageFile) => imageFile.file_id
    ),
    isRelay,
  };

  const clinicInfo = {
    from_user_id: submitData.clinic?.value,
    from_company_name: submitData.clinic?.label,
  };

  const shippingInfo = {
    shipping: {
      courier_service: submitData.shipping?.courier_service.value,
      tracking_code: submitData.shipping?.tracking_code,
    },
  };

  const orderType = {
    order_type:
      submitData.order_type === "newcase"
        ? OrderType.Neworder
        : submitData.order_type === "remake"
        ? OrderType.Remake
        : undefined,
  };

  let parsedPayload;

  // 기공소 신규주문 작성인 경우
  if (mode === OrderFormMode.LabNeworder) {
    parsedPayload = {
      ...parsedFormData,
      ...clinicInfo,
      ...orderType,
      ...dependentData,
    } as WriteOrderPayload;
  } else if (
    mode === OrderFormMode.LabEdit &&
    shippingInfo.shipping.courier_service &&
    shippingInfo.shipping.tracking_code
  ) {
    parsedPayload = {
      ...parsedFormData,
      ...shippingInfo,
      ...dependentData,
    } as EditOrderPayload;
  } else {
    parsedPayload = { ...parsedFormData, ...dependentData };
  }

  if ("order_id" in parsedPayload) {
    return parsedPayload as EditOrderPayload;
  }
  return parsedPayload as WriteOrderPayload;
}
