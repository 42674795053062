import { Cancel as CancelIcon } from "@dentlink/icons";
import classnames from "classnames";

interface Props {
  onClose: () => void;
  className?: string;
}

export default function Close({ onClose, className }: Props) {
  return (
    <button
      type="button"
      className={classnames(
        "absolute right-4 top-14 z-20 text-white tablet:right-14",
        className
      )}
      onClick={onClose}
    >
      <CancelIcon />
    </button>
  );
}
