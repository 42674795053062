import Menus, { TMenu, TMenuEntry } from "../../../common/constants/Menus";
import useCompanyType from "../../../common/hooks/useCompanyType";
import { useCallback, useMemo } from "react";
import isUndefined from "lodash/isUndefined";
import { getEntries } from "../../Review/utils";

export default function useConditionalMenus() {
  const companyType = useCompanyType() ?? "Clinic";
  const menuEntries = useMemo(() => getEntries<typeof Menus>(Menus), []);

  const renderCondition = useCallback(
    ({ _companyType }: TMenuEntry) => {
      let isShowMenu = true;
      if (isUndefined(_companyType)) isShowMenu = true;
      else if (_companyType !== companyType?.toLowerCase()) isShowMenu = false;
      return isShowMenu;
    },
    [companyType]
  );

  return menuEntries.reduce((mutatedMenus, [category, menus]) => {
    const filtered = (menus as unknown as TMenuEntry[]).filter(renderCondition);
    if (filtered.length > 0) {
      mutatedMenus[category] = filtered;
    }
    return mutatedMenus;
  }, {} as TMenu);
}
