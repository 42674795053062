import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { Client } from "../apis/ClientAPI/type";
import { AxiosError } from "axios";
import QueryKeys from "../constants/QueryKeys";
import { ClientAPI } from "../apis/ClientAPI";

export type UseClientListQueryOption<T> = UseQueryOptions<
  Client[],
  AxiosError,
  T
>;

export default function useClientListQuery<T = Client[]>(
  options: UseClientListQueryOption<T> = {}
) {
  const { getClientList } = ClientAPI;

  return useQuery<Client[], AxiosError, T>(
    [QueryKeys.CLIENT_LIST],
    getClientList,
    options
  );
}
