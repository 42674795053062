import isEmpty from "lodash/isEmpty";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { isOrderFilterEmpty } from "src/lib/OrderFilter/redux/orderFilterSelectors";
import OrderListItem from "./OrderListItem";
import OrderListLoading from "./OrderListLoading";
import OrderListPlaceholder from "./OrderListPlaceholder";
import { useOrderListData } from "src/lib/OrderList/hooks";

interface OrderListProps {
  layout: "desktop" | "mobile";
}

const OrderList = ({ layout }: OrderListProps) => {
  const {
    orderList = [],
    isFetching,
    hasNextPage,
    setObservationTarget,
  } = useOrderListData();

  const shouldRenderPlaceholder = isEmpty(orderList) && !isFetching;

  const isFilterEmpty = useSelector(isOrderFilterEmpty);
  const placeholderText = isFilterEmpty
    ? "No Order Placed"
    : "No Results Found";

  return (
    <>
      {shouldRenderPlaceholder && (
        <OrderListPlaceholder text={placeholderText} className="h-[65vh]" />
      )}
      {orderList?.map((order) => (
        <Fragment key={order.order_id}>
          <OrderListItem
            data={order}
            className={layout === "mobile" ? undefined : "mt-0.5"}
          />
        </Fragment>
      ))}
      {hasNextPage && <div ref={setObservationTarget} />}
      {isFetching && <OrderListLoading className="h-[65vh]" />}
    </>
  );
};

export default OrderList;
