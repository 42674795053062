import { Check } from "@dentlink/icons";
import { forwardRef, useMemo } from "react";
import InputBase, { InputBasePropType } from "../../base/InputBase";

interface TextInputPropType extends Omit<InputBasePropType, "endAdornment"> {
  valid?: boolean;
}

const TextInput = forwardRef<HTMLInputElement, TextInputPropType>(
  ({ valid = false, ...props }, ref) => {
    const checkMark = useMemo(() => {
      return valid ? (
        <Check className="h-[10px] w-[15px] text-primary" />
      ) : undefined;
    }, [valid]);
    return <InputBase {...props} endAdornment={checkMark} ref={ref} />;
  }
);

export default TextInput;
