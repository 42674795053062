import Portal from "../Portal";
import classnames from "classnames";
import { MouseEvent, ReactNode } from "react";
import {
  Confirm,
  FormContent,
  InfoFailed,
  InfoSuccess,
  InfoSuccessWithDesc,
} from "./ModalContents";

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
  /**
   * tailwindcss class for background color ex. bg-black/80
   */
  backgroundColor?: string;
  /**
   * invoke onClose Function when click outside of modal
   */
  closeOnOutsideClick?: boolean;
  /**
   * function to invoke when click outside of modal
   */
  onClose?: () => void;
}

const Modal = function ({
  children,
  className,
  isOpen,
  backgroundColor = "bg-black/80",
  closeOnOutsideClick = false,
  onClose,
}: ModalProps) {
  if (closeOnOutsideClick && !onClose) {
    throw new Error(`should pass onClose when closeOnOutsideClick is true`);
  }

  const onOutsideClick = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose?.();
    }
  };

  return isOpen ? (
    <Portal id="modal-root">
      <div
        className={classnames(
          "flex-center fixed bottom-0 z-50 h-[100dvh] w-screen",
          backgroundColor,
          className
        )}
        onClick={(e) => closeOnOutsideClick && onOutsideClick(e)}
        role="presentation"
      >
        {children}
      </div>
    </Portal>
  ) : null;
};

Modal.InfoSuccess = InfoSuccess;
Modal.InfoSuccessWithDesc = InfoSuccessWithDesc;
Modal.InfoFailed = InfoFailed;
Modal.Confirm = Confirm;
Modal.FormContent = FormContent;

export default Modal;
