import { NotificationAPI } from "src/common/apis/NotificationAPI";
import QueryKeys from "src/common/constants/QueryKeys";
import { useIntersectionObserver } from "@dentlink/hooks";
import { useInfiniteQuery } from "@tanstack/react-query";

const useNotifications = () => {
  const infiniteQueryResult = useInfiniteQuery(
    [QueryKeys.NOTIFICATIONS],
    async ({ pageParam }) => {
      return (await NotificationAPI.getNotifications(pageParam)).data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.cursor) return undefined;
        return { cursor: lastPage.cursor };
      },
    }
  );

  const flatData = infiniteQueryResult.data?.pages.flatMap(
    (page) => page.notifications
  );

  const unReadCount = flatData?.reduce((acc, curr) => {
    if (!curr.isRead) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const { setObservationTarget } = useIntersectionObserver({
    onIntersect: (entries, observer) => {
      if (entries[0].isIntersecting) {
        infiniteQueryResult.fetchNextPage();
        observer.unobserve(entries[0].target);
        observer.disconnect();
      }
    },
  });

  return {
    ...infiniteQueryResult,
    flatData,
    setObservationTarget,
    unReadCount,
  };
};

export default useNotifications;
