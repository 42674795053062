import { CompactCaseInfo } from "src/common/apis/OrderAPI/types";

type ParsedCase = {
  id: CompactCaseInfo["case_id"];
  name: string;
  formula: string[];
};

/**
 *
 * @param {CompactCaseInfo[]} caseData Case information of order
 * @returns parsed case data
 */
const parseCaseData = (caseData: CompactCaseInfo[]): ParsedCase[] => {
  return caseData.map((caseData) => ({
    id: caseData.case_id,
    name: caseData.prosthesis.prosthesis_name,
    formula: caseData.dental_formula,
  }));
};

export default parseCaseData;
