import * as React from "react";
import { SVGProps } from "react";
const SvgCommentOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 1.3a.3.3 0 0 1 .3-.3h13.4a.3.3 0 0 1 .3.3v8.733a.3.3 0 0 1-.3.3H7.15a.3.3 0 0 0-.24.12L4.04 14.28a.3.3 0 0 1-.54-.18v-3.467a.3.3 0 0 0-.3-.3H1.3a.3.3 0 0 1-.3-.3V1.3ZM4 4h8M4 7h6"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgCommentOutlined;
