import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SignupPageState = {
  openSignupCompleteUI: boolean;
};

const initialState: SignupPageState = {
  openSignupCompleteUI: false,
};

const signupPageSlice = createSlice({
  name: "signupPage",
  initialState,
  reducers: {
    setOpenSignupCompleteUI: (
      state: SignupPageState,
      action: PayloadAction<{ value: boolean }>
    ) => {
      const { value } = action.payload;
      state.openSignupCompleteUI = value;
    },
  },
});

export const { setOpenSignupCompleteUI } = signupPageSlice.actions;

export default signupPageSlice;
