import isEqual from "lodash/isEqual";
import { useRef } from "react";

export default function useDeepCompareMemo<T>(value: T): T | undefined {
  const ref = useRef<T>();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
