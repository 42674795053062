import * as MenuComps from "./components";

interface Menu {
  Item: MenuComps.TItem;
  Group: MenuComps.TGroup;
  NewCaseButton: MenuComps.TNewCaseButton;
  SignOutButton: MenuComps.TSignOutButton;
}

const Menu: Menu = { ...MenuComps };
export default Menu;
