import { UnstyledOrderStatusChip } from "src/components/Order/Show/OrderStatus";

/**
 *
 * @param data Order 정보
 * @returns Order Status Chip 우측에 추가로 표시할 Chip 정보
 */
const getAdditionalOrderStatusChips = ({
  isRemaked,
  isReordered,
}: {
  isRemaked: boolean;
  isReordered: boolean;
}) => {
  /**
   * remake와 겹칠 경우 remake를 우선시함
   */
  if (isRemaked) {
    return (
      <UnstyledOrderStatusChip
        label="Remake"
        className="border border-grayText text-grayText"
      />
    );
  } else if (isReordered) {
    return (
      <UnstyledOrderStatusChip
        label="Reorder"
        className="border border-grayText text-grayText"
      />
    );
  }
  return null;
};

export default getAdditionalOrderStatusChips;
