import { Company } from "src/common/apis/UserAPI/types";
import { signOut, useSession } from "next-auth/react";

/**
 * returns user's company type if user is logged in
 * @returns {Company} user's company type
 */
const useCompanyType = (): Company | undefined => {
  const session = useSession({
    required: true,
  });

  try {
    if (session.data?.error === "RefreshAccessTokenError") {
      signOut();
      return undefined;
    } else {
      return session.data?.user.data.company_type;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export default useCompanyType;
