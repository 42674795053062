import { CompactOrderData } from "src/common/apis/OrderAPI/types";
import { Company } from "src/common/apis/UserAPI/types";

const parseOrderCompanyInfo = (
  data: CompactOrderData,
  companyType: Company | undefined
) => {
  /**
   * to whom may be confused with from-to,
   *
   * this from - to is different from API response's from - to.
   * current API response's from : clinic of the order , to : lab of the order.
   *
   * But current Design's from - to perspective is different from API
   * Design's from : company which created the Order.
   * Design's to : company which is receiving the Order.
   */
  switch (companyType) {
    case "Clinic":
      if (data.create_company_type === "Clinic") {
        return `To ${data.to_company_name}`;
      } else {
        return `From ${data.to_company_name}`;
      }
    case "Lab": {
      if (data.create_company_type === "Lab") {
        return `To ${data.from_company_name}`;
      } else {
        return `From ${data.from_company_name}`;
      }
    }
    case "Admin":
      return `${data.from_company_name} > ${data.to_company_name}`;
    default:
      throw new Error("Invalid company type");
  }
};

export default parseOrderCompanyInfo;
