import { CourierServiceProvider } from "../types/Order";

const shippingTrackingLink: Record<
  CourierServiceProvider,
  (arg1: string) => string
> = {
  UPS: (trackingNumber: string) =>
    `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`,
  DHL: (tracking_number: string) =>
    `https://www.dhl.com/en/express/tracking.html?AWB=${tracking_number}&brand=DHL`,
  USPS: (trackingNumber: string) =>
    `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`,
  Fedex: (trackingNumber: string) =>
    `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}`,
};

const Links = {
  AUTH_DEFAULT_REDIRECT: "/dashboard",
  ABOUT: "https://dentlink.io",
  DASHBOARD: "/dashboard",
  ORDER_LIST: "/order/list",
  ORDER_MANAGEMENT: "/order/management",
  CLIENT_LIST: "/client/list",
  PAYMENT_HISTORY: "/transaction-history",
  NEW_ORDER: "/order/new",
  REGISTER_PAYMENT_METHOD: "/settings/payments/register",
  PAYMENT_METHOD_LIST: "/settings/payments",
  CHANNEL_TALK_LINK: "https://8pe0x.channel.io/lounge",
  TERMS_AND_CONDITIONS: "/legal/privacyterms",
  PRIVACY_POLICY: "/legal/privacypolicy",
  PROFILE: "/settings/profile",
  PROFILE_EDIT: "/settings/profile/edit",
  PROFILE_SETUP: "/settings/profile/setup",
  PASSWORD_ASSISTANCE: "/settings/password-assistance",
  REVIEW_LIST: "/reviews",
  DATA_EXTRACTION: "/extraction",
  SAMPLE: "/sample",

  getCommentsPageLink: (order_id: number) => `/order/${order_id}/comments`,
  getPaymentInfoLink: (order_id: number) => `/order/${order_id}/payment-info`,
  getCreateNewCaseLink: (lab_uid: string) => `/order/new?lab_uid=${lab_uid}`,
  getOrderPageLink: (order_id: string) => `/order/${order_id}`,
  getOrderEditPageLink: (order_id: string) => `/order/${order_id}?edit=true`,
  getReorderPageLink: (order_id: string) =>
    `/order/new?parent_order_id=${order_id}`,
  getRemakePageLink: (order_id: string) =>
    `/order/new?remake=true&parent_order_id=${order_id}`,
  getShippingLink: (
    tracking_number: string,
    courier_service: keyof typeof shippingTrackingLink
  ) => shippingTrackingLink[courier_service](tracking_number),
  getSingleCheckoutPageLink: (order_id: string) =>
    `/order/${order_id}/checkout`,
  getOrderDetailPageLink: (order_id: string) => `/order/${order_id}`,
  getBatchCheckoutPageLink: (lab_id: string) => `/checkout/${lab_id}`,
  getWriteReviewPageLink: (order_id: string) =>
    `/order/${order_id}/write-review`,
  getCaseReviewPageLink: (order_id: string) => `/order/${order_id}/case-review`,
};

export default Links;
