import axiosInstance from "src/common/apis/axiosInstance";

import qs from "qs";

import {
  ChangeOrderStatusPayload,
  EditOrderPayload,
  OrderData,
  OrderListData,
  OrderListSearchParams,
  OrderStatisticsResponse,
  SearchOrderDataResponse,
  UnpaidOrdersResponse,
  WriteOrderPayload,
} from "./types";
import { AxiosRequestConfig } from "axios";

export const writeOrder = async (payload: WriteOrderPayload) => {
  return await axiosInstance.post<OrderData>("/orders", payload);
};

export const editOrder = async (payload: EditOrderPayload) => {
  return await axiosInstance.patch<OrderData>(`/orders`, payload);
};

export const getOrderList = async (
  params: OrderListSearchParams = {},
  cursor?: string
) => {
  return await axiosInstance.get<OrderListData>("/orders/list", {
    params: {
      status: params.status,
      client: params.client,
      startdate: params.startdate,
      enddate: params.enddate,
      cursor,
      take: params.take,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getOrderInfo = async (
  orderId: string,
  config: AxiosRequestConfig = {}
) => {
  const res = await axiosInstance.get<OrderData>(`/orders/${orderId}`, config);
  return res;
};

export const changeOrderStatus = async (payload: ChangeOrderStatusPayload) => {
  const res = await axiosInstance.patch(`/orders/status`, payload);
  return res;
};

export const searchOrder = async (searchText: string, cursor?: string) => {
  const res = await axiosInstance.get<SearchOrderDataResponse>(
    "/orders/list/search",
    {
      params: {
        search: searchText,
        cursor: cursor,
      },
    }
  );
  return res;
};

export const deleteOrder = async (
  orderId: number | string,
  reason?: string
) => {
  const res = await axiosInstance.delete(`/orders`, {
    params: {
      order_id: orderId,
      reason,
    },
  });
  return res;
};

export const getUnpaidOrders = async () => {
  return await axiosInstance.get<UnpaidOrdersResponse>(`orders/checkouts`);
};

export const declineOrders = async ({
  order_ids,
  reason = "",
}: {
  order_ids: number[];
  reason?: string;
}) => {
  return await axiosInstance.patch(`orders/checkouts/decline`, {
    order_ids,
    reason,
  });
};

export const getOrderStatistics = async ({
  userId,
}: { userId?: number } = {}) => {
  return await axiosInstance.get<OrderStatisticsResponse>(
    `/orders/status/statistic`,
    {
      params: {
        user_id: userId,
      },
    }
  );
};
