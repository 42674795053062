import Linkify from "linkify-react";
import React from "react";

const linkifyOptions = {
  rel: "noreferrer noopener",
  target: "_blank",
  defaultProtocol: "https",
  className: "text-blueGray underline underline-offset-2 decoration-1",
};

export default function LinkifyTag({ content }: { content: string }) {
  return (
    <Linkify as="span" options={linkifyOptions}>
      {content}
    </Linkify>
  );
}
