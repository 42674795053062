import { NotificationsEmpty } from "@dentlink/icons";
import { Typography } from "@dentlink/ui";

const Placeholder = () => {
  return (
    <div className="flex h-full min-h-[248px] flex-col items-center justify-center gap-[20px]">
      <NotificationsEmpty className="h-[77px] w-[77px]" />
      <Typography color="grayText">No Notification Here</Typography>
    </div>
  );
};

export default Placeholder;
