import * as React from "react";
import { SVGProps } from "react";
const SvgNotificationsEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 77 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={77} height={77} rx={12} fill="#EBEDF5" />
    <path d="M32.5 56h12" stroke="#B2B6C2" strokeWidth={2} />
    <mask id="notifications-empty_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5 21c7.732 0 14 5.82 14 13v16h3v2h-34v-2h3V34c0-7.18 6.268-13 14-13Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5 21c7.732 0 14 5.82 14 13v16h3v2h-34v-2h3V34c0-7.18 6.268-13 14-13Z"
      fill="#fff"
    />
    <path
      d="M52.5 50h-2v2h2v-2Zm3 0h2v-2h-2v2Zm0 2v2h2v-2h-2Zm-34 0h-2v2h2v-2Zm0-2v-2h-2v2h2Zm3 0v2h2v-2h-2Zm30-16c0-8.421-7.306-15-16-15v4c6.77 0 12 5.062 12 11h4Zm0 16V34h-4v16h4Zm-2 2h3v-4h-3v4Zm1-2v2h4v-2h-4Zm2 0h-3v4h3v-4Zm-3 0h-28v4h28v-4Zm-28 0h-3v4h3v-4Zm-1 2v-2h-4v2h4Zm-2 0h3v-4h-3v4Zm1-18v16h4V34h-4Zm16-15c-8.694 0-16 6.579-16 15h4c0-5.938 5.23-11 12-11v-4Z"
      fill="#B2B6C2"
      mask="url(#notifications-empty_svg__a)"
    />
  </svg>
);
export default SvgNotificationsEmpty;
