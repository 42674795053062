import AppProviders from "../common/providers/AppProviders";
import type { AppProps } from "next/app";
import { appWithTranslation, SSRConfig } from "next-i18next";
import useSetSessionId from "src/common/hooks/useSetSessionId";
import useStorePrevPath from "src/common/hooks/useStorePrevPath";
import { Auth, NonAuth } from "src/common/components/Auth";
import "src/common/styles/globals.css";
import BlurLayer from "../common/components/BlurLayer";
import { NextComponentWithAuth } from "src/common/components/Auth/AuthComponent";
import { useProfileSetupNotice } from "src/lib/Profile/hooks/useProfileSetupNotice";
import { Session } from "next-auth";
import Scripts from "src/common/scripts";
import { usePageView } from "src/lib/GoogleTagManager/hooks";
import useChannelTalkBtnOnDesktopWidth from "src/common/hooks/useChannelTalkBtnOnDesktopWidth";
import "../common/styles/toastify.css";
import Head from "next/head";

type ProtectedAppProps = AppProps<
  {
    session: Session;
    dehydratedState: any;
  } & SSRConfig
> & {
  Component: NextComponentWithAuth<Record<string, unknown>, SSRConfig>;
};

declare global {
  interface Window {
    ChannelIO: (command: string) => void;
  }
}

const ProfileSetupNotice = () => useProfileSetupNotice();

function App({
  Component,
  pageProps: { session, dehydratedState, ...pageProps },
}: ProtectedAppProps) {
  const appProviderProps = { session, dehydratedState };
  useSetSessionId();
  useStorePrevPath();
  usePageView();
  useChannelTalkBtnOnDesktopWidth();

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
      </Head>
      <Scripts />
      <AppProviders {...appProviderProps}>
        {Component.auth ? (
          <Auth auth={Component.auth}>
            <Component {...pageProps} />
            <ProfileSetupNotice />
          </Auth>
        ) : (
          <NonAuth shouldRedirect={Component.shouldRedirect}>
            {<Component {...pageProps} />}
          </NonAuth>
        )}
        <BlurLayer />
      </AppProviders>
    </>
  );
}

export default appWithTranslation<ProtectedAppProps>(App);
