import { Prev as PrevIcon } from "@dentlink/icons";
import classnames from "classnames";

interface Props {
  onClick: () => void;
  iconSize?: number;
  className?: string;
  disabled?: boolean;
}

export default function Prev({
  onClick,
  className,
  disabled = false,
  iconSize = 43,
}: Props) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={classnames(
        "absolute left-8 top-2/4 z-10 -translate-y-2/4 rounded-full hover:bg-[#666]",
        className
      )}
    >
      <PrevIcon width={iconSize} height={iconSize} />
    </button>
  );
}
