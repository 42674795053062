import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type InvoicePageState = {
  [orderId: number]: {
    openPaymentSuccessUI: boolean;
  };
};

const initialState: InvoicePageState = {};

const invoicePageSlice = createSlice({
  name: "invoicePage",
  initialState,
  reducers: {
    setOpenPaymentSuccessUI: (
      state: InvoicePageState,
      action: PayloadAction<{ orderId: number; value: boolean }>
    ) => {
      const { orderId, value } = action.payload;
      state[orderId] = { ...state[orderId], openPaymentSuccessUI: value };
    },
  },
});

export const { setOpenPaymentSuccessUI } = invoicePageSlice.actions;

export default invoicePageSlice;
