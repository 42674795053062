import { Gender } from "src/common/apis/OrderAPI/types";

const GenderMap: Record<Gender, "M" | "F"> = {
  ["male"]: "M",
  ["female"]: "F",
};

/**
 *
 * @param gender gender of patient
 * @returns abbreviation.
 */
const parsePatientGender = (gender: Gender): "M" | "F" => {
  return GenderMap[gender];
};

export default parsePatientGender;
