import Header from "./Header";
import { ReactNode } from "react";

interface ButtonHeaderProps {
  title: string;
  button: ReactNode;
}

export default function ButtonHeader({ title, button }: ButtonHeaderProps) {
  return (
    <Header backgroundColor="background">
      <Header.BackLink />
      <Header.Title
        title={title}
        className="absolute left-2/4 -translate-x-2/4 capitalize"
      />
      <div className="absolute right-4">{button}</div>
    </Header>
  );
}
