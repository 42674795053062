import * as React from "react";
import { SVGProps } from "react";
const SvgLogoSimpleWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m15.27.326-.018-.007a4.37 4.37 0 0 0-.225-.08l-.033-.01-.212-.061c-.016-.004-.037-.007-.053-.014-.064-.017-.131-.03-.198-.044-.024-.004-.047-.007-.067-.014a3.607 3.607 0 0 0-.276-.045l-.174-.024-.118-.01c-.05-.004-.097-.01-.147-.01C13.66.003 13.574 0 13.484 0h-.004c-2.863 0-5.182 2.353-5.222 5.267v.037h2.611c1.443 0 2.611 1.198 2.615 2.67v4.8h3.56c.92 0 1.665-.762 1.665-1.702V5.345c0-2.309-1.433-4.275-3.436-5.02h-.004Z"
      fill="#C2CAFA"
    />
    <path
      d="M6.518 12.693V5.226H2.956c-.92 0-1.665.761-1.665 1.701v5.729c0 2.308 1.43 4.274 3.438 5.018.007 0 .01.003.017.007.074.027.147.055.225.079l.033.01.212.062c.017.003.037.007.053.014.064.017.131.03.199.044a3.64 3.64 0 0 0 .255.048l.087.01c.057.007.118.018.175.024l.117.01c.05.004.097.011.148.011.087.003.174.007.265.007h.003c2.864 0 5.188-2.353 5.224-5.265v-.038H6.518v-.004Z"
      fill="#fff"
    />
  </svg>
);
export default SvgLogoSimpleWhite;
