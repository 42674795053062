import { signOut } from "next-auth/react";

const SignOutButtonOnMenu = () => {
  const onClick = () => signOut();

  return (
    <button
      onClick={onClick}
      className="rounded-[3px] px-2 text-center text-xs hover:bg-background tablet:py-2 tablet:text-left tablet:text-base"
    >
      Sign out
    </button>
  );
};

export default SignOutButtonOnMenu;
