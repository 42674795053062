import * as React from "react";
import { SVGProps } from "react";
const SvgPhoto = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.038 2.77a.75.75 0 0 0 .624-.335L5.787.75h4.582l1.123 1.685a.75.75 0 0 0 .624.334h2.693a.596.596 0 0 1 .596.596v9.424a.596.596 0 0 1-.596.596H1.346a.596.596 0 0 1-.596-.596V3.365a.596.596 0 0 1 .596-.596h2.692Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.077 10.769a3.029 3.029 0 1 0 0-6.058 3.029 3.029 0 0 0 0 6.058Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPhoto;
