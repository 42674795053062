import { Loading } from "@dentlink/ui";
import React from "react";

interface OrderListLoadingProps {
  className?: string;
}

export default function OrderListLoading({ className }: OrderListLoadingProps) {
  return (
    <div className={`grid place-content-center ${className}`}>
      <Loading />
    </div>
  );
}
