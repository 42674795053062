import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type currentClickedBtnType = "" | "today" | "tomorrow";

export type GlobalState = {
  loading: boolean;
  modal: boolean;
  openNoPaymentMethodUI: boolean;
  currentClickedBtn: currentClickedBtnType;
  v2Modal: boolean;
};

const initialState: GlobalState = {
  loading: false,
  modal: false,
  openNoPaymentMethodUI: false,
  currentClickedBtn: "",
  v2Modal: true,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoading: (state: GlobalState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setModal: (state: GlobalState, { payload }: PayloadAction<boolean>) => {
      state.modal = payload;
    },
    setOpenNoPaymentMethodUI: (
      state: GlobalState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.openNoPaymentMethodUI = payload;
    },
    setCurrentClickedBtn: (
      state: GlobalState,
      { payload }: PayloadAction<currentClickedBtnType>
    ) => {
      state.currentClickedBtn = payload;
    },
    setV2Modal: (state: GlobalState, { payload }: PayloadAction<boolean>) => {
      state.v2Modal = payload;
    },
  },
});

export const {
  setLoading,
  setModal,
  setOpenNoPaymentMethodUI,
  setCurrentClickedBtn,
  setV2Modal,
} = globalSlice.actions;
export default globalSlice;
