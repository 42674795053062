import * as React from "react";
import { SVGProps } from "react";
const SvgTooth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10.76 1.626-2.763.785-2.762-.785a3.288 3.288 0 0 0-1.705-.023c-.56.142-1.064.43-1.458.828-.394.399-.66.893-.768 1.427a2.727 2.727 0 0 0 .157 1.592l1.708 4.106.278 6.134c.01.218.11.424.278.575a.948.948 0 0 0 1.092.113.872.872 0 0 0 .333-.332l1.39-2.492c.14-.25.35-.46.606-.605A1.73 1.73 0 0 1 8 12.725c.3 0 .596.078.853.224.257.146.467.355.606.605l1.39 2.492a.871.871 0 0 0 .333.332.948.948 0 0 0 1.093-.113.824.824 0 0 0 .277-.575l.278-6.134L14.54 5.45a2.727 2.727 0 0 0 .157-1.594 2.834 2.834 0 0 0-.77-1.428 3.133 3.133 0 0 0-1.46-.828 3.288 3.288 0 0 0-1.707.026Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgTooth;
