import * as React from "react";
import { SVGProps } from "react";
const SvgShippingOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.965 11.368a3.06 3.06 0 0 1-6.06 0H0V.875A.875.875 0 0 1 .875 0h12.242a.875.875 0 0 1 .875.875v1.748h2.623l2.624 3.542v5.198h-1.78a3.06 3.06 0 0 1-6.06 0l-4.434.005Zm5.277-9.618H1.748v7.038a3.06 3.06 0 0 1 4.952.83h4.962c.146-.306.342-.587.58-.83V1.75Zm1.748 5.247h3.499v-.25l-1.757-2.374h-1.74l-.002 2.624Zm.437 5.246a1.312 1.312 0 1 0 0-2.624 1.312 1.312 0 0 0 0 2.624Zm-9.182-1.311a1.312 1.312 0 1 0-2.623 0 1.312 1.312 0 0 0 2.623 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgShippingOutlined;
