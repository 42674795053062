import classNames from "classnames";
import { ReactNode } from "react";
import { GapStyles } from "../../constants/styles";
import Typography from "../Typography";
import RadioGroupContext from "./RadioGroupContext";

interface RadioGroupProps {
  /**
   * selected value
   */
  value: string;
  /**
   * value setter function
   */
  onChange: (...event: any[]) => void;
  label?: string;
  children: ReactNode;
  required?: boolean;
  col?: boolean;
  /**
   * this classes will be applied to outer wrapper of RadioGroup
   */
  className?: string;
  /**
   * this classes will be applied to inner wrapper of RadioGroup
   */
  innerClassName?: string;
  helperText?: ReactNode;
  gap?: keyof typeof GapStyles;
}

const RadioGroup = ({
  value,
  children,
  label,
  required,
  onChange,
  col,
  helperText,
  className,
  innerClassName,
  gap = 8,
}: RadioGroupProps) => {
  return (
    <RadioGroupContext.Provider value={{ value, setValue: onChange }}>
      <div
        className={classNames(
          `flex flex-col justify-start`,
          className,
          GapStyles[gap]
        )}
      >
        {label && (
          <Typography
            variant="body2"
            color="blueGray"
            className={`${required && "after:content-['*']"}`}
          >
            {label}
          </Typography>
        )}
        <div
          className={classNames("flex", innerClassName, {
            "flex-col": col,
          })}
        >
          {children}
        </div>
      </div>
      {!!helperText && <div className="mt-2">{helperText}</div>}
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;
