import { createContext } from "react";

export interface RadioGroupContextValue {
  value: any;
  setValue: (value: string) => void;
}

const RadioGroupContext = createContext<RadioGroupContextValue | null>(null);

export default RadioGroupContext;
