import classNames from "classnames";
import React, { forwardRef, InputHTMLAttributes, ReactNode } from "react";
import { GapStyles } from "../../constants/styles";
import { Typography } from "../../src";
import useControlled from "../../utils/useControlled";

export interface InputBasePropType
  extends InputHTMLAttributes<HTMLInputElement> {
  required?: boolean;
  label?: string;
  placeholder?: string;
  endAdornment?: JSX.Element;
  value?: string;
  fullWidth?: boolean;
  readOnly?: boolean;
  className?: string;
  helperText?: ReactNode;
  /**
   * to use Input component as anchor, use this prop to pass ref.
   */
  setAnchorEl?: (anchorEl: HTMLElement | null) => void;
  gap?: keyof typeof GapStyles;
  hasError?: boolean;
}

const InputBase = forwardRef<HTMLInputElement, InputBasePropType>(
  (
    {
      label,
      required,
      placeholder,
      endAdornment,
      value,
      fullWidth,
      className = "",
      helperText,
      readOnly,
      setAnchorEl,
      gap = 8,
      hasError,
      ...props
    },
    ref
  ) => {
    // const sizeStyle = fullSize ? fullSizeStyle : defaultSizeStyle;
    const isControlled = useControlled({
      controlledValue: value,
      name: "baseInput",
      state: "value",
    });

    const commonProps = {
      className: classNames(
        "w-full focus:outline-none caret-primary/30 placeholder:text-grayText bg-transparent pl-[2px] text-md"
      ),
      readOnly,
      placeholder,
      ref,
      required,
      ...props,
    };

    return (
      <>
        <label
          ref={setAnchorEl}
          className={classNames(
            "flex flex-col items-start border-b-2 border-lineColor pb-1",
            className,
            {
              "w-full": fullWidth,
              "focus-within:border-primary": !hasError,
              "border-error": hasError,
            },
            GapStyles[gap]
          )}
        >
          {label && (
            <Typography size="sm" color="blueGray">
              {`${label}${required ? "*" : ""}`}
            </Typography>
          )}
          <div className="flex w-full">
            {isControlled ? (
              <input value={value} {...commonProps} />
            ) : (
              <input {...commonProps} />
            )}
            {endAdornment && (
              <span className="inline-flex h-[27px] w-[27px] flex-shrink-0 items-center justify-center">
                {endAdornment}
              </span>
            )}
          </div>
        </label>
        {!!helperText && <div>{helperText}</div>}
      </>
    );
  }
);

export default InputBase;
