import * as React from "react";
import { SVGProps } from "react";
const SvgBellOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.163 15.981h2.015v2.022H0V15.98h2.018V8.907a8.072 8.072 0 0 1 16.144 0l.001 7.074Zm-2.018 0V8.907a6.054 6.054 0 0 0-12.108 0v7.077l12.108-.003ZM7.063 20.02h6.055v2.021H7.063V20.02Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBellOutlined;
