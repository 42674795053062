import {
  BellOutlined,
  ChevronLeft,
  CircleCancel,
  Hamburger,
  LogoBlack,
  LogoGray,
  MagnifierOutlined,
} from "@dentlink/icons";
import { Badge, Typography } from "@dentlink/ui";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  ChangeEvent,
  cloneElement,
  isValidElement,
  ReactElement,
  SVGProps,
} from "react";
import Links from "src/common/constants/Links";
import { useNotifications } from "src/lib/Notifications/hooks";

interface HeaderProps {
  children: React.ReactNode;
  backgroundColor?: "white" | "background";
  className?: string;
}

const Header = ({
  children,
  backgroundColor = "background",
  className,
}: HeaderProps) => {
  return (
    <header
      className={classNames(
        "sticky top-0 z-20 flex h-11 w-full items-center border-b border-lineColor",
        {
          "bg-white": backgroundColor === "white",
          "bg-background": backgroundColor === "background",
        },
        className
      )}
    >
      {children}
    </header>
  );
};

const BackLink = () => {
  const router = useRouter();

  return (
    <button
      type="button"
      className="flex h-8 w-8 items-center justify-center"
      onClick={() => router.back()}
    >
      <ChevronLeft width={32} height={32} className="cursor-pointer text-md" />
    </button>
  );
};

interface LogoProps {
  variant: "color" | "mono";
  enableLink?: boolean;
}

const Logo = ({ variant, enableLink }: LogoProps) => {
  const router = useRouter();

  let Icon: ReactElement<SVGProps<SVGElement>>;

  switch (variant) {
    case "color":
      Icon = <LogoBlack />;
      break;
    case "mono":
      Icon = <LogoGray />;
      break;
    default:
      throw new Error(`Invalid variant given to Logo : ${variant}`);
  }

  if (isValidElement(Icon)) {
    return cloneElement(Icon, {
      width: 110,
      height: 24,
      className: "cursor-pointer",
      onClick: enableLink
        ? () => router.push(Links.AUTH_DEFAULT_REDIRECT)
        : undefined,
    });
  }
  return null;
};

interface TitleProps {
  title: string;
  className?: string;
}
const Title = ({ title, className }: TitleProps) => {
  return (
    <Typography size="md" weight="semibold" className={className}>
      {title}
    </Typography>
  );
};

const Navbar = () => {
  const { unReadCount } = useNotifications();
  return (
    <nav className="flex gap-3.5 text-[22px] text-black">
      <Badge content={unReadCount} layout="mobile">
        <Link href="/notifications">
          <BellOutlined />
        </Link>
      </Badge>
      <Link href="/search">
        <MagnifierOutlined />
      </Link>
      <Link href="/menu">
        <Hamburger />
      </Link>
    </nav>
  );
};

interface SearchbarProps {
  value: string;
  onChange: (arg0: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

const Searchbar = ({ value, onChange, onClear }: SearchbarProps) => {
  const showMagnifier = value.length === 0;
  const iconProps = { width: 21, height: 21, className: "relative -top-px" };

  const EndAdornment = () => (
    <button
      type="button"
      onClick={showMagnifier ? undefined : onClear}
      className="h-[26px]"
    >
      {showMagnifier ? (
        <MagnifierOutlined {...iconProps} />
      ) : (
        <CircleCancel {...iconProps} />
      )}
    </button>
  );

  return (
    <section className="flex w-full border-b-2 border-lineColor focus-within:border-primary">
      <input
        value={value}
        onChange={onChange}
        className="h-[26px] w-full caret-primary/30 outline-none placeholder:text-grayText"
        placeholder="Patient Name, Order Number..."
      />
      <EndAdornment />
    </section>
  );
};

Header.Searchbar = Searchbar;
Header.Navbar = Navbar;
Header.Logo = Logo;
Header.BackLink = BackLink;
Header.Title = Title;
export default Header;
