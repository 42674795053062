import useCompanyType from "../../../common/hooks/useCompanyType";
import { ReviewStatus } from "../../Review/types";
import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../../../common/constants/QueryKeys";
import { getOrderSummary } from "../../../common/apis/ReviewAPI/ReviewAPI";

const useReviewCount = () => {
  const isClinic = useCompanyType() === "Clinic";
  const status: ReviewStatus = isClinic ? "pending" : "completed";

  const params = { status };
  const { data: totalCount } = useQuery(
    [QueryKeys.REVIEW_ORDER_SUMMARY, params],
    () => getOrderSummary(params),
    {
      select: (data) => {
        const { total_count } = data;
        if (total_count === 0) return "";
        if (total_count > 99) return "99+";
        else return String(total_count);
      },
    }
  );

  return { totalCount };
};

export default useReviewCount;
