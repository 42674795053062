import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { ProfileState } from "./profileSlice";

const selectProfile = (state: RootState): ProfileState => state.profile;

export const selectIsProfileSetupComplete = createSelector(
  [selectProfile],
  (state) => state.isProfileSetupComplete
);

export const selectIsSubmittingProfileForm = createSelector(
  [selectProfile],
  (state) => state.isSubmittingProfileForm
);

export const selectLastViewedProfileTab = createSelector(
  [selectProfile],
  (state) => state.lastViewedProfileTab
);
