import * as React from "react";
import { SVGProps } from "react";
const SvgSpinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.771 3.81c.377-.173.546-.622.327-.974a6 6 0 1 0 .477 5.381c.154-.385-.092-.797-.493-.9-.4-.104-.804.14-.973.518a4.5 4.5 0 1 1-.387-4.365c.232.343.673.512 1.05.34Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSpinner;
