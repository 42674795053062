import { LogoSimpleWhite } from "@dentlink/icons";
import { CommentData } from "src/common/apis/CommentAPI/types";
import classNames from "classnames";
import { useMemo } from "react";

const Avatar = ({ user }: { user: Omit<CommentData["user"], "user_id"> }) => {
  const classes = classNames(
    "w-[44.5px] h-[44.5px] flex justify-center items-center font-semibold rounded overflow-hidden",
    {
      "bg-blueGray text-white": user.company_type === "Clinic",
      "bg-primary text-white": user.company_type === "Admin",
      "bg-primaryLight text-black": user.company_type === "Lab",
    }
  );

  const initials = useMemo(() => {
    switch (user.company_type) {
      case "Admin":
        return <LogoSimpleWhite width={20} height={18} />;
      case "Clinic":
      case "Lab":
        return user.company_name.charAt(0);
    }
  }, [user.company_name, user.company_type]);

  return <div className={classes}>{initials}</div>;
};

export default Avatar;
