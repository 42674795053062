import { components } from "react-select";

const MenuList = (props: any) => {
  const { children, className, ...rest } = props;

  return (
    <components.MenuList className={`${className} common-scroll`} {...props}>
      {children}
    </components.MenuList>
  );
};

export default MenuList;
