import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { GlobalState } from "../slices/globalSlice";

const globalSelector = (state: RootState): GlobalState => state.global;

export const loadingSelector = () =>
  createSelector([globalSelector], (globalState) => globalState.loading);

export const modalSelector = () =>
  createSelector([globalSelector], (globalState) => globalState.modal);

export const isOpenNoPaymentMethodUISelector = () =>
  createSelector(
    [globalSelector],
    (globalState) => globalState.openNoPaymentMethodUI
  );

export const currentClickedBtnSelector = () =>
  createSelector(
    [globalSelector],
    (globalState) => globalState.currentClickedBtn
  );

export const v2ModalSelector = () =>
  createSelector([globalSelector], (globalState) => globalState.v2Modal);
